export default {
  you: '你',
  him: '他',
  her: '她',
  male: '男',
  female: '女',
  ming: '命',
  age: '岁',
  years: '年龄',
  sunStem: '日干',
  fate: '命局',
  name: '姓名',
  birth: '生日',
  // 命格
  destiny: ['印重局', '比肩局', '伤官局', '财旺局', '煞重局'],
  // 两仪
  opposite: {
    black: '阴',
    white: '阳'
  },
  // 五行
  fiveEl: {
    gold: '金',
    wood: '木',
    water: '水',
    fire: '火',
    earth: '土'
  },
  // 天干
  // prettier-ignore
  stem: {
    '甲': '甲',
    '乙': '乙',
    '丙': '丙',
    '丁': '丁',
    '戊': '戊',
    '己': '己',
    '庚': '庚',
    '辛': '辛',
    '壬': '壬',
    '癸': '癸'
  },
  // 地支
  // prettier-ignore
  branch: {
    '子': '子',
    '丑': '丑',
    '寅': '寅',
    '卯': '卯',
    '辰': '辰',
    '巳': '巳',
    '午': '午',
    '未': '未',
    '申': '申',
    '酉': '酉',
    '戌': '戌',
    '亥': '亥'
  },
  // 二十四节气
  twenty_four: {
    lichun: '立春',
    yushui: '雨水',
    jingzhe: '惊蛰',
    chunfen: '春分',
    qingming: '清明',
    guyu: '谷雨',

    lixia: '立夏',
    xiaoman: '小满',
    mangzhong: '芒种',
    xiazhi: '夏至',
    xiaoshu: '小暑',
    dashu: '大暑',

    liqiu: '立秋',
    chushu: '处暑',
    bailu: '白露',
    qiufen: '秋分',
    hanlu: '寒露',
    shuangjiang: '霜降',

    lidong: '立冬',
    xiaoxue: '小雪',
    daxue: '大雪',
    dongzhi: '冬至',
    xiaohan: '小寒',
    dahan: '大寒'
  },
  // 日期
  time: '时辰',
  unknow: '不明',
  extra: '闰',
  solar: '公历',
  lunar: '农历',
  year: '年',
  month: '月',
  day: '日',
  hour: '时',
  minute: '分',
  second: '秒',
  // 公共按钮
  iknow: '知道了',
  save: '保存',
  no_save: '不保存',
  confirm: '确定',
  cancel: '取消',
  submit: '提交',
  download: '下载',
  to_download: '去下载',
  prompt: '提示',
  search: '查询',
  estimate: '立即测算',
  unlock: '立即解锁',
  check_report: '立即查看天赋',
  birth_report: '生日报告',
  cal_name: '测算者姓名',
  orderno: '订单号',
  order_tips: '订单号可用于查询历史报告，请妥善保存',
  // 页签title
  title: 'HiSeer-你的专属运势专家',
  title_birth: 'HiSeer-报告预测',
  title_plate: 'HiSeer-免费排盘',
  birth_desc: '刚刚发现一个超准的测试，快来试试吧！',
  plate_desc: '刚刚发现一个超好用的排盘工具，快来试试吧！',
  click_right: '点击右键保存图片',
  nav: {
    title: 'HiSeer-独家预测',
    button: '打开APP'
  },
  // 评价
  rate: {
    rate: '评价',
    place: '展开说说您的想法吧...',
    rate_q: '您认为哪些方面有待提高？',
    advices: [
      '内容不够丰富',
      '产出速度太慢',
      '价格不够亲民',
      '预测不够准确',
      '其他（请填写）'
    ]
  },
  // 隐式菜单
  menu: {
    project: '预测项目',
    talentReport: '报告',
    answer: '答案之书',
    plate: '免费排盘',
    type: '报告类型',
    entire: '全能报告',
    person: '人格报告',
    destiny: '命运报告',
    love: '恋爱报告',
    profession: '职业报告',
    relation: '人际报告',
    match: '合盘报告',
    annual: '年度运势报告',
    enterprise: '企业智能人才测评',
    annual_custom: '年度运势 定制报告',
    career: '职业发展测评',
    hexagram: '问卦咨询',
    history: '历史记录',
    order_search: '订单查询',
    privacyArgeement: '隐私协议',
    userArgeement: '用户协议',
    contact: '联系客服',
    home: '全能报告预测',
    result: '全能报告结果',
    help: '如需帮助、易学咨询 请联系微信或邮箱',
    pay_tips: '使用无痕浏览器进行微信支付可能导致异常',
    wechat: '微信号：',
    email: '邮箱：',
    birth_name: '测算者姓名',
    order_type: '订单类型',
    order_name: '订单内容',
    theme: '主题',
    operate: '操作',
    report: '报告',
    plate_name: '姓名',
    gender: '性别',
    birth: '出生时间',
    detail: '查看详情',
    no_history: '暂无记录',
    no_order: '这里没有我的订单？',
    no_result: '未搜索到相关记录',
    order_tips: '输入订单号 / 已绑定的手机号或邮箱进行查询'
  },
  // 时间选择器组件
  time_picker: {
    toast: '仅支持1900年至今日的生日',
    nohour: '时辰不明',
    title: '选择时辰范围',
    place: '选择时辰',
    unknow: '时辰未知',
    error: '请输入正确的生日',
    change: '节气变化',
    y_range: '年输入范围',
    m_range: '月输入范围1-12',
    d_range: '日输入范围',
    hour_err: '请选择时辰',
    clear: '清除',
    delete: '删除'
  },
  // 支付页面
  payment: {
    only: '仅需',
    price: '原价',
    icon: '￥',
    before_price: '立即解锁',
    after_price: '全能报告',
    origin_price: '88',
    sale: '19.9',
    pay: '微信支付',
    fail: '支付失败',
    title: '的全能报告',
    type_item_0: {
      title: '本命解读',
      improve: '个人提升',
      text_0: '最强天赋',
      text_1: '性格思维'
    },
    type_item_1: {
      title: '人际交往',
      improve: '人脉提升',
      text_0: '六亲关系',
      text_1: '贵人分析'
    },
    type_item_2: {
      title: '职业规划',
      improve: '事业提升',
      text_0: '事业观念',
      text_1: '职业能力'
    },
    type_item_3: {
      title: '恋爱指南',
      improve: '情感提升',
      text_0: '情感观点',
      text_1: '最佳交往对象'
    },
    order_confirm: '订单确认中...',
    try_later: '请稍后重试',
    slogan_0: '把握天赋',
    slogan_1: '精准分析',
    slogan_2: '改变命运',
    sale_text: '限时优惠价：',
    until_end: '距离优惠结束还有',
    people_paid: '人购买了报告',
    useful: '的人觉得有用',
    current_order: '分钟前购买了报告',
    user_title: '请确认支付是否完成',
    user_complete: '已完成支付',
    user_again: '支付遇到问题，重新支付',
    notify_continue: '继续支付',
    notify_cancel: '取消',
    fans_price: '粉丝专享价：',
    fans_sale: '限首次免费',
    fan_btn: '立即查看',
    free_btn: '领取免费报告',
    stripe: '信用卡/借记卡支付',
    contact: '联系\n客服',
    save_modal: {
      button: '领取免费报告',
      title: '离免费领取报告只剩一步\n验证手机号或邮箱',
      error: '每人只有1次免费领取机会哦！',
      prompt: '提示',
      tip: '离领取报告只剩一步，您确定要放弃粉丝专享免费报告吗？',
      success: '领取成功',
      success_h5: '关闭弹窗即可查看报告',
      success_txt:
        '打开HiSeer app，使用验证时输入的邮箱或手机号登录，可查看已领取的报告',
      open_app: '打开app查看报告',
      continue: '继续领取',
      cancel: '狠心放弃',
      outdated: '活动已结束'
    }
  },
  // 名片分享
  share: {
    card: '的专属名片',
    qr: '扫码获取&全能报告',
    copy_url: '刚刚获得了我的专属名片，快来看看>>',
    copy_success: '链接已复制，快去分享吧',
    share_card: '点击分享名片',
    check_mine: '查看我的全能报告',
    earth: '地球上',
    talent_first: '的人与其拥有类似的天赋，',
    talent_rest: '是其中之一哦~',
    save_tip: '长按保存图片'
  },
  // 打开app页面
  open_app: {
    gift: '送你一张专题生日报告免费体验券',
    receive: '在HiSeer app中输入我的邀请码即可领取哦！！',
    coupon: '生日报告免费体验券',
    code: '我的邀请码',
    copyed: ' 邀请码已复制',
    tips: '点击右上角在浏览器打开',
    loading: '正在加载'
  },
  // 首页
  front: {
    title: 'HiSeer 预测报告',
    title_dload: 'HiSeer APP下载',
    title_plate: 'HiSeer 独家预测',
    hexagram: '问卦咨询',
    hexagram_desc: '一事一占 大师解卦',
    plate: '八字排盘',
    answer: '答案之书',
    answer_desc: '海量问题 专业解答',
    hiseer: '更多玄学知识、预测报告\n尽在HiSeer App',
    contact: '联系方式',
    contact_tip: '真人咨询、商务合作、请联系',
    email: '邮箱',
    wechat: '微信',
    annual_activity: '下单赠开运符',
    annual_swiper: '咨询师在线 实时解答',
    annual_bold: '1对1咨询',
    planning: '个人规划',
    route: {
      reports: ['人格', '命运', '恋爱', '职业', '人际'],
      five_in_one: '报告五合一',
      entire: '全能报告',
      big_txt: '超值',
      person: '人格报告',
      person_desc: '带你寻找隐藏天赋',
      destiny: '命运报告',
      destiny_desc: '带你寻找相似名人',
      love: '恋爱报告',
      love_desc: '带你寻找灵魂伴侣',
      relation: '人际报告',
      relation_desc: '带你寻找命中贵人',
      profession: '职业报告',
      profession_desc: '助你寻找心仪事业'
    }
  },
  // 生日报告首页
  home: {
    history_order: '历史&订单',
    report_title: '全能报告为你解读生日密码，提供提升建议',
    option_0: '生辰八字排盘',
    option_1: '最强天赋解析',
    option_2: '典型名家名人',
    option_3: '性格思维特点',
    option_4: '潜力提升建议',
    option_5: '六亲贵人关系',
    option_6: '职业规划建议',
    option_7: '情感恋爱观点',
    title: '填写信息以生成全能报告',
    form: {
      title: {
        self: '是否本人',
        birth: '生日',
        gender: '性别',
        name: '姓名'
      },
      birth_placeholder: '请输入出生日期',
      name_placeholder: '请输入姓名',
      name_err: '最多输入20字符',
      option: {
        self_one: '本人',
        self_tow: '非本人',
        male: '男',
        female: '女'
      }
    },
    history: '选择八字排盘中的记录',
    button: '立即查看全能报告',
    share: '邀请朋友测',
    copy_share: '刚刚发现一个超准的报告，快来试试吧！',
    argeement: '使用服务表示同意<a>用户协议</a>',
    talent: {
      question_1: '全能报告是什么？',
      answer_1:
        '每个人在出生的那一刻，宇宙就给生命注入了能量，能量的差异造就了不同的个体，所以我们每个人都拥有不同的人格、天赋、外貌和社会关系，从而拥有不同的命运。<br/>全能报告基于生辰时间，从人格和天赋出发，全方位、多角度为你解读生日密码中的生命信息，助你走好人生路。',
      // answer_1:
      //   '每个人的<strong>天赋</strong>都是上天特别量身定制的礼物，正是天赋中包含的<strong>智慧与力量</strong>帮助我们<strong>披荆斩棘</strong>，<strong>走好人生之路</strong>。',
      question_2: '决定天赋的要素是什么？',
      answer_2:
        '每个人的生日生时都由天而定，不可预测、不可更改，而每个人的<strong>天赋</strong>如何就藏在<strong>生日密码</strong>中。',
      question_3:
        '你是否对自己/他/她的认知有很多疑惑？<br/>你是否想从生日密码中更加了解自己？',
      answer_3:
        '如果你也有同样的疑惑，就和HiSeer易学大师一起来揭开“生日密码”的神秘面纱，从命盘角度来看看你我他/她的命运走向。',
      unlock_count: '20000+用户已解锁报告',
      report_rate: '报告评价',
      accuracy: '准确率',
      usable: '实用性',
      satisfaction: '满意度'
    },
    // swiper
    swiper: {
      first: {
        title: '通过《全能报告》，你可以获得：',
        desc: {
          title_1: '通过命盘分析全方位、多角度解读个人天赋：',
          list_1: `优缺点关键词，勾勒独特性格
          个人最强天赋的具体表现
          价值观分析、性格分析、思维特点分析
          同类型名人名家，展望未来发展`,
          title_2: '从三个方面切入，解析个人发展：',
          list_2: `与他人的社交关系远近情况分析
          恋爱表现分析
          职业能力、长短板分析`,
          title_3:
            '运用命盘分析天赋中的优劣势，并对此提出潜力提升建议、社交注意事项、最佳恋爱对象以及从业建议'
        },
        option_1: '发挥天赋优势',
        option_2: '改变自我认知',
        option_3: '找到发展方向',
        option_4: '弥补职业短板',
        option_5: '挖掘提升潜力'
      },
      second: {
        title: '报告依据',
        first_para: `<strong>《全能报告》</strong>是由<span>HiSeer</span>资深易学研发团队和上清·碧海命理研修院共同研发推出的基于八字命理、针对个人天赋以及内在思维意识和行为特点的深度剖析解读报告。`,
        second_para:
          '我们的命理研修团队将传统易学进行了进一步的精炼和优化，与心理学、哲学、大数据以及当代社会文化相结合，从每个人独有的八字命盘出发，深度分析个人一生以及阶段性的天赋意识与价值取向，为用户的生活指点迷津。'
      },
      example: '报告示例',
      fifth: {
        title: '用户感受',
        comment_1:
          '出于好奇试了试全能报告，有一段描述简直就是给我装监控才能写出来的，这也太神奇了吧！最强天赋的描述给了我一些信心，谢谢大师！',
        date_1: '2022/12/16',
        comment_2:
          '我的全能报告好可爱hhh，所以朋友老是说我太感性是事实吗，希望可以根据建议努力变得更好，加油！',
        date_2: '2023/01/01',
        comment_3: '看完啦，写我在感情里的状态真的太准了555',
        date_3: '2023/01/05',
        comment_4:
          '最近孩子高考报志愿，想拿这个看看做个参考，性格方面的描述比较准确，他确实比较严谨，根据从业建议选择了计算机专业，希望可以被录取。',
        date_4: '2022/06/25  14:30'
      }
    },
    // 我们团队
    our_team: {
      title: '我们的团队',
      tip_1: '拥有40余年易学研究基础',
      tip_2: '从事易学教育十二年',
      tip_3: '已培养3000+易学爱好者',
      tip_4: '完成35万例线下1v1咨询服务',
      tip_5: '建立百万级命理八字数据库',
      hiseer: 'HiSeer 出品'
    },
    // 引流模块
    advertise: {
      slogan_item_1: '每日运势播报',
      slogan_item_2: '八字排盘',
      slogan_item_3: '手相预测',
      slogan_item_4: '问卦咨询',
      slogan_item_5: '预测报告',
      slogan_item_6: '答案之书预测',
      google_download: 'Google Play下载',
      ios_download: 'Apple下载',
      hiseer: 'HiSeer\n你的专属运势专家'
    },
    // 底部按钮
    footer_btn: '生成全能报告'
  },
  // 生成器组件
  generating: {
    title: '报告生成中',
    birth: {
      step: [
        '生日密码解析中',
        '本命解读生成中',
        '人际交往分析中',
        '职业规划分析中',
        '恋爱指南生成中'
      ],
      complete: [
        '生日密码解析完成',
        '本命解读生成完成',
        '人际交往分析完成',
        '职业规划分析完成',
        '恋爱指南生成完成'
      ]
    },
    person: {
      step: [
        '天赋类型匹配中',
        '关键词查询中',
        '最强天赋解读中',
        '性格特点分析中',
        '潜力提升建议生成中'
      ],
      complete: [
        '天赋类型匹配完成',
        '关键词查询完成',
        '最强天赋解读完成',
        '性格特点分析完成',
        '潜力提升建议生成完成'
      ]
    },
    destiny: {
      step: [
        '天赋类型匹配中',
        '相似名人检索中',
        '价值观解读中',
        '思维特点对比中',
        '潜力提升建议生成中'
      ],
      complete: [
        '天赋类型匹配完成',
        '相似名人检索完成',
        '价值观解读完成',
        '思维特点对比完成',
        '潜力提升建议生成完成'
      ]
    },
    love: {
      step: ['天赋类型匹配中', '恋爱性格分析中', '最佳对象匹配中'],
      complete: ['天赋类型匹配完成', '恋爱性格分析完成', '最佳对象匹配完成']
    },
    relation: {
      step: '贵人检索中',
      complete: '贵人检索完成'
    },
    profession: {
      step: '职业性格分析中',
      complete: '职业性格分析完成'
    },
    yin0: '逻辑超强的&理想主义者',
    yin1: '独立思考的&真理探索家',
    bi0: '热情仗义的&能力担当者',
    bi1: '果敢阳光的&人生冒险家',
    shang0: '天马行空的&浪漫智多星',
    shang1: '脑洞大开的&艺术生活家',
    cai0: '实事求是的&价值创造者',
    cai1: '勤勉务实的&社会贡献者',
    sha0: '工匠精神的&只需守护者',
    sha1: '自律严谨的&秩序守护者'
  },
  // 生日报告
  report: {
    share: '邀请朋友测',
    save_card: '生成专属名片',
    tablist: {
      life: '本命解读',
      interpersonal: '人际交往',
      career: '职业规划',
      love: '恋爱指南'
    },
    life: {
      unknow: '时辰不明',
      talent_type: '的天赋类型是：',
      // 解读关键词
      key_word_title: '解读关键词',
      advantage: '优点',
      shortcomings: '缺点',
      // 最强天赋
      most_best_talent: '最强天赋',
      // 典型名家名人
      famous_person: '典型名家名人',
      famous_person_from: '以上资料来源于《维基百科》',
      // 价值观
      values: '价值观',
      values_decs_title: '<strong>纵观一生</strong>，',
      values_decs_title_rest: '的价值观构成是什么?',
      values_all_life: '一生的价值观',
      values_now: '现阶段的价值观',
      // 性格分析
      personality: '性格分析',
      highlight: '闪光点',
      short: '弱点',
      // 思维特点
      thinking: '思维特点',
      thinking_all_life: '一生的思维特点',
      thinking_now: '现阶段思维特点',
      // 潜力提升建议
      potential: '潜力提升建议'
    },
    interpersonal: {
      // 六亲关联性排名
      interpersonal: '人际交往',
      relative: '六亲关联性排名',
      life_relative: '一生的六亲关联性',
      now_relative: '现阶段的六亲关联性',
      all_life: '纵观一生',
      now_period: '现阶段',
      relative_extent: '的六亲关联程度：',
      father: '父亲',
      husband: '丈夫/男友',
      child: '孩子',
      sis_bro_fir: '兄弟姐妹/朋友',
      mother: '母亲',
      wife: '妻子/女友',
      year_period: '2013年~2023年',
      relative_content_txt:
        '这里的关联性排名具体体现在日常生活中六亲对你的影响程度、你对他们的关注程度以及与其交往过程中花费时间/精力的多少，排名越靠前代表影响力越大、关注程度越高、花费时间精力越多。',
      // 生命中的贵人
      elegant: '生命中的贵人',
      relative_elegant: '六亲中的贵人',
      elegant_analysis: '从人物类型角度分析，你的贵人包括：'
    },
    career: {
      career: '职业规划',
      // 事业观
      career_outlook: '事业观',
      nature: '职业性格',
      //职业能力分布
      abilities: '职业能力分布',
      // 从业建议
      career_advice: '从业建议',
      low: '弱项',
      high: '强项',
      normal: '平均'
    },
    love: {
      // 爱情观
      love_outlook: '爱情观',
      // 最佳交往对象
      best_lover: '最佳交往对象'
    },
    rate: {
      txt: '预测体验如何？评价一下吧～',
      completed: '我的评价',
      rate_please: '喜欢的话就给报告点亮5颗星吧～',
      advice: '您给HiSeer的建议（选填）',
      score: '分',
      advice_placeholder: '希望看到更多哪方面的内容或服务...',
      toast: '请选择评分',
      success: '感谢您的评价！'
    },
    save_tip: '当前报告未保存，现在离开可能会丢失该报告，是否前往保存？',
    save: {
      title: '保存报告到HiSeer App',
      save_report: '保存报告',
      save2seer: '手机验证',
      save2email: '邮箱验证',
      seer_tips: '验证手机号成为HiSeer用户，直接保存报告至HiSeer app',
      email_tips: '接收邮箱地址',
      placeholder_code: '请输入验证码',
      placeholder_email: '请输入您的邮箱',
      placeholder_phone: '请输入手机号',
      send_code: '发送验证码',
      send_again: '重发',
      code_has_been_sent: '验证码已发送',
      report2seer: '保存报告到HiSeer App',
      report2email: '保存报告至邮箱',
      phone_err: '请输入正确的手机号',
      email_err: '请输入正确的邮箱',
      code_err: '验证码错误',
      send_success: '保存成功',
      save_success:
        '打开HiSeer app，\n使用验证时输入的邮箱或手机号登录，\n可查看已领取的报告',
      email_verify: '邮箱验证',
      email_code_success: '验证码已发送至邮箱，请输入验证码',
      email_modal_code_tip: '（如未收到请查看垃圾邮件）',
      email_tip: '为了保护您的信息安全，验证邮箱后结果将自动保存',
      prompt: '提示',
      prompt_content:
        '不保存将会增加结果丢失的风险，保存后您可以使用验证的账号登录HiSeer app查看结果，是否保存？',
      tip: '请仔细记录您填写的账号，\n后续您可登录HiSeer app查看报告',
      modal_success: [
        '1.打开HiSeer app，使用保存时输入的邮箱或手机号登录，可查看已保存结果',
        '2.点击本网页右上角<span>☰</span>按钮，在历史记录-订单查询页面输入订单号 / 保存时提供的手机号或邮箱进行订单查询，可查看已保存结果'
      ]
    },
    // 生日报告完整内容
    whole_report: {
      title: '全能报告完整内容',
      description: '针对每个用户进行四个层面，共12个角度的深度全面分析预测。'
    },
    filter: {
      unlock: '解锁完整报告'
    }
  },
  // 人格指南
  personality: {
    desc_list: [
      '生辰八字排盘',
      '人格类型匹配',
      '最强天赋挖掘',
      '性格观念分析',
      '潜力提升建议'
    ],
    description_title: '看见隐藏的天赋，挖掘潜在的能力',
    description:
      '“我只是来人间凑数的无名之辈”\n不！你只是没有发现自己的天赋！\n每个人都有与生俱来的特质，但与生俱来也意味着很难自己察觉，因此它就像巨大的宝藏，正在静静地等着探寻和发掘。',
    match: {
      title: '20种人格类型精准匹配',
      type0: '冒险家',
      desc0: '慷慨重义',
      desc_png0: '聪明灵活',
      type1: '理想家',
      desc1: '聪明仁慈',
      desc_png1: '淡泊名利',
      type2: '贡献者',
      desc2: '待人宽厚',
      desc_png2: '善解人意',
      type3: '脑洞家',
      desc3: '才华横溢',
      desc_png3: '桀骜不驯'
    },
    keyword: {
      title: '优缺点关键词全面解读',
      merit: '优点',
      merits: ['智慧', '责任心', '有条理', '诚实', '有计划', '重承诺'],
      defect: '缺点',
      defects: ['传统', '固执', '犹豫不决', '过度分析', '优柔寡断', '保守']
    },
    talent: {
      title: '天赋能力多角度分析',
      list: ['严谨', '创意', '规划', '落地', '协作']
    },
    nature: {
      title: '性格长短版深度分析',
      list: [
        '战略思考',
        '意志力强大',
        '富于常识和悟性',
        '不敏感',
        '傲慢',
        '迟钝和强势'
      ]
    },
    advice: {
      title: '专业建议帮助潜力提升',
      list: ['关注情绪', '重视他人价值', '提前思考']
    },
    person_report:
      '本测算基于易理算法，通过分析姓名、出生时间、性别等信息结合现实情况，分析测试者的天赋、性格、优缺点，针对个人特点提供潜力提升指南。',
    more: {
      title: '更多报告',
      click: '点击查看报告详情'
    },
    comment_list: [
      {
        time: '2023/03/25',
        text: '非常准，之前测过MBTI感觉讲得比较模糊，这个人格报告描述得比较细致，帮我提升了自我认知'
      },
      {
        time: '2023/03/31',
        text: '很好，分析了我的隐藏天赋，给了我不少信心'
      },
      {
        time: '2023/01/08',
        text: '哈哈随便测测没想到讲得这么仔细，保存了，以后迷茫的时候拿出来看看'
      }
    ],
    fixed_btn: '立即查看天赋'
  },
  // 命运指南
  destiny_report: {
    desc_list: ['名人匹配', '人格定位', '观念解读', '思维分析', '潜力提升'],
    description_title: '你可以像名人一样成功',
    description:
      '哪些名人与你属于同一种命运格局？\n谁和你拥有相似的天赋/性格特点？\n我们为你精准匹配与你命运格局相同的名人，帮助你更了解自己的发展方向、潜在天赋\n了解你们之间的共同特点，他们可能就是你的未来...',
    celebrity: {
      title: '匹配与你天赋命运相似的名人',
      desc: '寻八字命运相似之人\n发掘自己的终极潜力'
    },
    value: {
      title: '价值观全面解读',
      list: ['理想主义', '完美主义', '团队精神', '务实精神', '自由主义']
    },
    thinking: {
      title: '思维特点横纵向对比',
      list: ['逻辑思维', '主观能动', '严谨自控', '创意灵感', '务实落地']
    },
    comment_list: [
      {
        time: '2023/03/17',
        text: '与我最相似的名人第一个竟然是Lisa！！！开心！！！！'
      },
      {
        time: '2023/02/03',
        text: '潜力提升建议给得很实用，赞哦~'
      },
      {
        time: '2023/01/05',
        text: '我确实是比较严谨自控的人，分析得还是挺靠谱的，希望真的能像相似名人那样获得成功啊'
      }
    ],
    report_des:
      '本测算基于易理算法，通过分析姓名、出生时间、性别等信息结合现实情况，将测试者的人格、思维、观念与千位名人作对比，匹配与测试者最相似的名人，并针对个人特点提供潜力提升指南。'
  },
  // 恋爱指南
  love_report: {
    head_list: ['人格匹配', '恋爱分析', '男方助妻', '女方助夫'],
    description_title: '指引你走向幸福和谐的爱情',
    description:
      '恋爱是一场势均力敌的博弈，双方都有着自己的独立人格和精神世界，既不能完全依附对方，也不能让对方完全占据自己。\n从现在开始，强化自己的人格认知，正视自己的恋爱观念，与伴侣建立健康和谐的恋爱关系，共同进步、相互成就，携手徜徉天地间。',
    analysis: {
      title: '恋爱性格观念分析',
      list: [
        '<span>客观分析你的恋爱感受</span>，帮助你更清楚地了解你和伴侣间的深层情感',
        '<span>深入解读你的恋爱性格</span>，帮助你有效掌握恋爱中的积极力量，扩大你的恋爱世界'
      ]
    },
    match: {
      title: '匹配最适合你的另一半',
      list: [
        '果敢阳光的\n人生冒险家',
        '逻辑超强的\n理想主义者',
        '自律严谨的\n秩序守护者',
        '工匠精神的\n完美主义者'
      ],
      radio: '匹配度'
    },
    comment_list: [
      {
        time: '2023/03/29',
        text: '说我的最佳交往对象是逻辑思维能力很强的人诶，刚好我身边就有一个，希望能修成正果~'
      },
      {
        time: '2023/03/01',
        text: '准的诶，我在恋爱方面...确实很主动哈哈哈，希望早点脱单！'
      },
      {
        time: '2023/02/11',
        text: '交往过的三个对象都挺符合描述的，让我早点遇到对的他吧'
      }
    ],
    report_des:
      '本测算基于易理算法，通过分析姓名、出生时间、性别等信息结合现实情况，分析测试者情感观念和恋爱角色，给予实际可行的建议，帮助找到顺旺好姻缘。'
  },
  // 人际指南
  relation: {
    header_list: ['看六亲关系亲疏', '教你慧眼识贵人'],
    description_title: '教你练就识别贵人的眼力',
    description:
      '贵人不在于有多“贵”，而在于有多“近”\n从六亲中的贵人开始，让贵人主动走近你！\n让你的每一天你都遇见贵人，每一件事都有贵人帮助。',
    relatives: {
      title: '六亲关系分析',
      list: ['子', '姐妹', '兄弟', '父', '母', '妻', '夫', '女']
    },
    analysis: {
      title: '贵人分析',
      content:
        '六亲中，谁是你的贵人？\n社会上，谁是你的贵人?\n贵人秘籍在手 荣华富贵都有'
    },
    comment_list: [
      {
        time: '2023/03/10',
        text: '好准啊，我就是“妈宝女”，跟妈妈关系超好'
      },
      {
        time: '2023/02/22',
        text: '我很喜欢交朋友，原来是因为他们是我的贵人哈哈哈'
      },
      {
        time: '2023/02/05',
        text: '建议我多跟逻辑性强的人交流，确实是，我自己逻辑性比较差哈哈哈'
      }
    ],
    report_desc:
      '本测算基于易理算法，通过分析姓名、出生时间、性别等信息结合现实情况，分析测试者的家庭六亲关系，针对个人特点提供贵人交往指南，助力测试者多交贵人、步步高升。'
  },
  // 职场指南
  profession: {
    list: ['职业性格分析', '职业能力评估', '职业选择指南'],
    description_title: '一步到位搞定职业规划',
    description:
      '适合自己的才是最好的，选对职业比努力更重要！\n“职业指南”帮助您更全面了解自己的性格，搞清楚自己的优势和劣势，找到与自身特点最匹配的职业，拓展职业发展的视野，实现职业生涯的长远规划。',
    profession_analysis: {
      title: '职业性格分析',
      list: ['认识自己的职业性格', '改变自己的职场命运！']
    },
    ability: {
      title: '职业类型建议',
      left_list: ['战略布局', '组织领导', '市场需求', '成本减耗', '独立研发'],
      right_list: ['包装宣传', '公关社交', '原创设计', '盈利扩大', '技术整合']
    },
    advice: {
      title: '职业类型建议',
      list: [
        '信息传播',
        '作家/记者',
        '企业商业',
        '顾问/销售',
        '教育/咨询',
        '翻译/大学教授',
        '技术/互联网',
        '软件开发工程师\n网络管理员',
        '政府/服务',
        '消防员\n警察',
        '专业性职业',
        '律师\n法官'
      ]
    },
    comment_list: [
      {
        time: '2023/03/19',
        text: '文理分科选择困难症，纠结了半天，没想到一个报告比我自己还了解我自己，希望分班顺利'
      },
      {
        time: '2023/03/22',
        text: '准毕业生，来看看自己适合干哪行，感觉报告里面最有价值的是职业能力分布，很专业'
      },
      {
        time: '2023/02/15',
        text: '求职迷茫期看到这个报告，很及时很nice'
      }
    ],
    profession_description:
      '本测算基于易理算法，通过分析姓名、出生时间、性别等信息结合现实情况，分析测试者的职业性格、工作能力并为其提供恰当的职业建议。'
  },
  // 合盘报告
  match: {
    select_type: '请选择合盘类型',
    homo: '同性爱情合盘',
    hetero: '男女爱情合盘',
    form_desc: '揭秘关系本质 预测爱情走向',
    your_info: '你的信息',
    his_info: '对方的信息',
    match_now: '立即合盘',
    relative: '当前关系：',
    select_relative: '选择当前关系',
    relatives: [
      { label: '暧昧', value: 1 },
      { label: '热恋', value: 2 },
      { label: '婚后', value: 3 }
    ],
    puzzle: {
      title: '面对“爱情”你有这些困惑吗？',
      help: '爱情合盘报告可以帮助你',
      puzzle: [
        'TA到底\n爱不爱我?',
        '感情是否存在\n挑战和机遇？',
        '未来的关系\n走向如何？',
        '子女运\n如何？',
        '我们缘分\n有多深？',
        '我们会拥有\n幸福婚姻吗?'
      ],
      homo_puzzle: [
        '我们羁绊有多深？',
        '我们会一直相互陪伴吗？',
        '未来的关系走向如何？',
        'TA会出轨吗？',
        'TA到底爱不爱我？',
        '性生活默契如何？'
      ],
      list: [
        '了解自己和对方的性格、爱情观，同时清晰指出双方的合拍度、互补性，更好地帮助你认识自己，了解对方，增进彼此的理解和信任。',
        '无论你是<span>单身</span>还是<span>恋爱中</span>，无论你是想<span>找到真爱</span>还是想<span>稳固现有感情</span>，爱情合盘报告都可以帮助你，让你更清楚自己的心理需求和感情倾向，让你更好地把握情感发展趋势，更自信地追求自己的幸福。'
      ]
    },
    suitable: {
      title: '爱情合盘报告适用群体：',
      desc: '爱情合盘报告可以预测3种类型的关系走向',
      ambiguous:
        '与<span>暗恋对象</span>合盘\n看双方<span>情感发展潜力</span>\n决定要不要追求',
      homo: '与<span>热恋的TA</span>合盘\n看<span>未來情感運勢</span>\n评估双方感情挑战',
      hetero:
        '与<span>热恋的TA</span>合盘\n看<span>未来婚姻运势</span>\n考虑要不要结婚',
      marriage:
        '与<span>步入婚姻的TA</span>合盘\n看<span>婚后情感生育运势</span>\n评估双方财运趋势',
      marriage_homo:
        '与<span>相伴多年的TA</span>合盘\n看<span>未来生活状态</span>\n评估双方财运、桃花'
    },
    report: {
      tabs: ['热恋关系', '暧昧关系', '婚后关系'],
      title: '对不同的关系提供不同的分析预测：',
      desc: '三大维度 {num}个的问题全方位解答',
      options: ['双方情感', '事业财运', '未来发展'],
      options_marriage: ['双方情感', '家庭关系', '婚姻发展'],
      options_ambiguous: ['双方情感', '恋爱成长', '脱单指南']
    },
    emotion: {
      title: '双方情感',
      list: [
        '双方的性格匹配度',
        '双方的恋爱观契合度',
        '伴侣在各自生命中的顺位排序',
        '双方性生活宫合'
      ],
      list_ambiguous: [
        '双方的性格匹配度',
        '双方的恋爱观契合度',
        '对方的理想恋人类型'
      ],
      list_marriage: [
        '双方的性格匹配度',
        '双方婚姻观契合度',
        '对方的理想伴侣类型',
        '伴侣在各自生命中的重要性',
        '双方性生活匹配度'
      ]
    },
    money: {
      title: '事业财运',
      list: ['双方事业观', '双方未来财运宫合'],
      list_ambiguous: [
        '如何获得对方的好感',
        '如何加深我们的关系',
        '如果我们在一起',
        '我们之中谁更花心',
        '双方性生活匹配度',
        '在一起可能会出现的挑战'
      ],
      list_marriage: [
        '双方能给彼此带来什么',
        '双方未来财运宫合',
        '双方的子女运宫合'
      ]
    },
    future: {
      title: '未来发展',
      list: [
        '双方的子女运宫合',
        '未来一年双方的感情运势',
        '情感中潜在的挑战',
        '双方情感中出现第三者的几率',
        '双方步入婚姻的机会',
        '提升恋爱关系建议'
      ],
      list_marriage: [
        '婚姻中潜在的挑战',
        '婚姻中出现第三者的几率',
        '未来一年的婚姻运势',
        '提升婚姻质量建议'
      ],
      list_ambiguous: ['未来三个月脱单可能性', '旺桃花建议']
    },
    example: {
      title: '爱情合盘(type)报告示例',
      according: '爱情合盘报告依据',
      accordings:
        '爱情合盘报告依据合盘双方的出生日期和时间，先分析各自的命理特征、性格运势，再结合两人的喜忌关系对当前的情感状态、未来关系走向进行预测。'
    },
    feedback: {
      title: '爱情合盘报告使用感受',
      feed_list: [
        {
          homo_name: '**秀   2023-9-01',
          hetero_name: '**秀   2023-9-01',
          hetero:
            '在挽回情感的边缘挣扎，但终究不是对的人，合盘报告给了我重新认识他的机会，是真的不合适，不强求了。',
          homo: '在挽回情感的边缘挣扎，但终究不是对的人，合盘报告给了我重新认识他的机会，是真的不合适，不强求了。'
        },
        {
          hetero_name: '**金   2023-8-18',
          homo_name: '**金   2023-8-18',
          hetero:
            '单身想主动追求来的，一看合盘关系发展空间很大，果断去追，现在已经在看日子准备结婚了嘿嘿',
          homo: '单身想主动追求来的，一看合盘关系发展空间很大，果断去追，现在已经在看日子准备结婚了嘿嘿'
        },
        {
          hetero_name: '**名   2023-7-29',
          homo_name: '**名   2023-7-29',
          hetero: '婚后确实工作繁忙异地比较多，合盘报告里面讲的真的神准！',
          homo: '婚后确实工作繁忙异地比较多，合盘报告里面讲的真的神准！'
        }
      ]
    },
    illustrate: {
      title: '爱情合盘报告使用说明',
      homo: [
        '1.需要认真填写合盘双方的完整信息，包括姓名、性别、生日；',
        '2.同性爱情合盘报告的双方性别必须为同性；',
        '3.合盘报告支付后将在30秒内生成结果，生成后可以保存报告，保存后可以通过以下方式查看：下载HiSeer APP，通过保存时验证的邮箱/手机号进行登录后，在 我的-【报告与占卜】中查看；在本网页的【历史订单】中，搜索邮箱/手机号/订单号进行查看。'
      ],
      hetero: [
        '1.需要认真填写合盘双方的完整信息，包括姓名、性别、生日；',
        '2.男女爱情合盘的双方性别必须为异性；',
        '3.合盘报告支付后将在30秒内生成结果，生成后可以保存报告，保存后可以通过以下方式查看：下载HiSeer APP，通过保存时验证的邮箱/手机号进行登录后，在 我的-【报告与占卜】中查看；在本网页的【历史订单】中，搜索邮箱/手机号/订单号进行查看。'
      ]
    },
    loading: {
      title: '爱情合盘分析中',
      list: [
        '性格契合度分析',
        '人生大事顺位对比',
        '财运起伏趋势预测',
        '感情危机应对'
      ],
      list_marriage: [
        '婚姻契合度分析',
        '人生大事顺位对比',
        '财运起伏趋势预测',
        '未来婚姻关系分析'
      ],
      list_ambiguous: [
        '性格契合度分析',
        '未来双方关系预测',
        '双方花心程度分析',
        '桃花催旺脱单建议'
      ]
    },
    love: {
      emotion: '双方情感',
      match_emotion: '双方的性格匹配度',
      similarity: '性格相似度：',
      complementary: '性格互补性：',
      outlook_match: '双方的恋爱观契合度',
      love_match: '恋爱契合度',
      impression: '如何获得对方的好感',
      relative: '如何加深我们的关系',
      enhance_advise: '关系提升建议',
      together: '如果我们在一起',
      playful: '我们之中谁更花心',
      possible: '未来三个月脱单可能性',
      luck: '旺桃花建议',
      challenge: '在一起可能会出现的挑战',
      // 婚后
      marriage_match: '双方婚姻观契合度',
      ideal_partner: '对方的理想伴侣类型',
      marriage_promote: '双方能给彼此带来什么',
      fortunate_match: '双方未来财运宫合',
      child_match: '双方的子女运宫合',
      marriage_challenge: '婚姻中潜在的挑战',
      marriage_cheat: '双方情感中出现第三者的几率',
      marriage_enhance: '提升婚姻质量建议',
      mate_importance: '伴侣在各自生命中的重要性',
      attitude: '双方对金钱的态度',
      now_sort: '现阶段的排序',
      life_sort: '一生的排序',
      sort_list: ['理想', '社交/朋友', '孩子', '事业/财富', '另一半'],
      sex_match: '双方性生活宫合',
      sex_perform: '双方的性生活表现',
      sex_desire: '双方的性欲强弱',
      sex_rate: '性生活匹配度'
    },
    similaritys: ['你们的性格有几分相似？', '你们的性格有几分互补？'],
    importances: ['对你而言什么最重要？', ' 对TA来说另一半排在第几位？'],
    sexs: ['你们的性生活合拍吗？', '你们的性生活表现如何？'],
    wealthys: ['你们主要的财富来源于何处？', '你们的未来财运趋势如何？'],
    childs: ['你们一生可以有几个子女？', '你们与子女的关系如何？'],
    chances: ['你们的情感状态如何？', '未来三年结婚的概率如何？'],
    love_match: ['双方的恋爱理念是什么？', '会通过何种方式表达爱？'],
    impression: ['对方的喜好如何？', '怎么表现才能引起TA的注意？'],
    love_relative: ['未来一年情感发展情况如何？', '怎样为我们的恋爱加满火力？'],
    playful: ['双方花心程度如何？', '内心对恋爱持何种态度？'],
    possible: ['未来三个月脱单的具体几率？', '除了对方是否存在其他桃花？'],
    love_luck: ['你的桃花运如何？', '哪些方式可以帮你快速脱单？'],
    // 婚后
    marriage_match: ['双方对待婚姻态度如何？', '婚姻对TA而言重要吗？'],
    marriage_promote: ['在生活事业方面，', '彼此能相互促进、共同发展吗？'],
    marriage_challenge: [
      '哪些因素会影响婚姻稳定？',
      '面对什么问题需要格外注意？'
    ],
    marriage_advise: ['如何提升婚姻幸福指数？', '哪些事情可以促进双方情感？'],
    fortunate: '事业财运',
    both_fort: '双方的事业观',
    wealthy: '双方的未来财运宫合',
    both_weal: '双方的财富来源',
    future_develop: '未来发展',
    weal_compare: '未来财运趋势对比：',
    weal_fortunate: '财富运势',
    emo_fortunate: '感情运势',
    score: '分',
    score_unit: '分数',
    age: '年龄',
    child: {
      match: '双方的子女运宫合',
      fate: '与子女的缘分',
      importance: '子女对双方的重要性',
      effect: '双方对子女的影响'
    },
    challenge: '情感中潜在的挑战',
    chance: '双方步入婚姻的机会',
    third: '双方情感中出现第三者的几率',
    marriage: '近三年结婚几率：',
    emotion_fort: '未来一年双方的感情运势',
    emotion_chart: '情感趋势图',
    marriage_fort: '婚姻运势',
    marriage_chart: '婚姻运势图',
    advice: '提升恋爱关系建议',
    advice_hiseer: '合盘报告准确率如何...',
    recommend: '推荐情感占卜',
    more: '更多情感占卜'
  },
  // 年运报告
  annual: {
    title: '年度运势报告',
    year: '预测年份',
    place_year: '请选择您要预测的年份',
    predict: '立即测算',
    activity: '2024元宵节前限时送龙年开运符\n助您好运一整年！',
    example: '年度运势报告示例',
    paid: '<span>num</span>人下单了年度运势 定制报告\n<span>98.9%</span>用户觉得很准',
    alter: '更改',
    schedule: {
      steps: [
        '收货地址填写已完成',
        { text: '开运符开光仪式', success: '开运符开光仪式已完成' },
        { text: '开运符发货', success: '开运符发货已完成' }
      ],
      upload: '仪式完成后将为您上传视频',
      play: '点击播放开光视频',
      express: '物流单号：',
      contact_us: '如有疑问，请通过以下方式联系我们：',
      tips: '使用邮箱账号\nemail登录HiSeer App\n可查看年度运势报告结果、开运符进度',
      email_tip: '登录HiSeer App\n可查看年度运势报告结果、开运符进度'
    },
    master: {
      title: '年度运势 定制报告',
      text: '专业命理师预测+真人撰写为你提前预报年度运程',
      overview: '一、年度综合运势概况',
      overview_list:
        '这一年生活的重心会在哪些方面？\n会获得哪些方面的发展？\n会遭遇哪些方面的挫折？',
      analysis: '二、6大维度运势详细分析',
      analysis_list: [
        {
          title: '爱情运势',
          content: '什么时候桃花运最旺？\n是否有情感危机？'
        },
        { title: '事业运势', content: '什么时候事业运好？\n能否升职加薪？' },
        { title: '财富运势', content: '什么时候财运好？\n财富来源何处？' },
        { title: '社交运势', content: '社交运势如何？\n适合交往哪些朋友？' },
        {
          title: '学业运势',
          content: '学业成绩如何？\n能否通过学习获得提升？'
        },
        { title: '健康运势', content: '年度健康情况如何？\n需要注意哪些事项？' }
      ],
      guide: '三、助运指南',
      guide_bold: '涵盖利好方向、喜用颜色、幸运数字在内等多方面的好运指南',
      guide_list:
        '哪个方向有利发展？\n哪些颜色是我的年度喜用色？\n能给我带来幸运的生活方式有哪些？',
      service: '真诚的服务，不负您的新年期待',
      service_content: `我们书写的每一份年运报告，写下的每一句分析综述，都来自专业命理师的用心推演。

      我们每天承接上百个年度运势报告服务，逐一推演耗神费心，但出于我们对用户负责的态度，为了对得起您对于新年的期待，我们必须如此，也请您理解从命理分析到形成书面报告需要必要的时间。

      下单后我们将按顺序安排年运分析，一般会在24小时内完成报告，请耐心等待。`
    },
    charm: {
      title: '开运符助运',
      desc: '现在立即下单大师年运详批，除了获得大师真人年运分析报告，还可获得经由道观制作、开光的【龙年开运符】，我们帮您把好运送到家',
      list: [
        {
          name: '财运符',
          content:
            '财运符可以帮助你广招财源、消解灾难。如果你的财路正旺，那么它可以添一把火，如果你的事业不顺，那么它的作用就是雪中送炭。召集五路财神暗中为你广开财路，为你增加机遇，以获得偏财运与偏财，获得意外之财。'
        },
        {
          name: '事业符',
          content:
            '事业符可以为你带来好运和机遇，激发事业潜能，获得贵人相助，让你在事业和职场上更容易获得成功。它可以吸引积极的能量，让你在工作中更加顺利，让你的职业生涯更加顺风顺水。'
        },
        {
          name: '桃花符',
          content:
            '能够催旺桃花运，进而获得异性的爱慕跟追求。对于单身的男女来讲，能够帮助早日遇到心仪对象，招来好姻缘；对于已经恋爱的人而言，也能够改善感情不顺的情况，促进关系升温，早日走进婚姻殿堂，让感情幸福美满。'
        },
        {
          name: '学业符',
          content:
            '学业文昌符的主要作用是祈求学业顺利、考试顺利及升学升职等。具有提升学业运势和智慧的作用。使用文昌符可以辅助学习，增强学习动力和积极心态，提高专注力和记忆力。'
        },
        {
          name: '平安符',
          content:
            '平安符可以护佑人们身体和心灵健康、保佑出入平安、挡住小人。适合各类人士，例如经常出行的人们，平安符可以起到一种保平安的作用。同时为自己或家人求得平安符增加身体的能量或改善身体状况。'
        },
        {
          name: '人缘符',
          content:
            '广结人缘符能够帮助你扩展社交圈，使你更容易建立新的人际关系。帮助你打开人们的心扉，使他们对你产生好感和信任，让你在社交场合更加受欢迎，与他人建立更深入的联系，享受更多的支持和帮助。'
        }
      ],
      tip: '六种实体开运符您可以选择领取其中一种',
      writing: '所有开运符皆为道观大师亲笔绘制、开光认证的符',
      lighting: '我们将根据您提供的个人信息为每位用户做独家定制的开光仪式',
      video: '点击查看开运符绘制视频',
      light: '点击查看开光仪式视频',
      deliver: '开运符制作完毕我们将根据您提供的地址为您邮寄到家',
      color: '颜色随机',
      gift: '赠',
      gift_desc:
        '您可以将开运符放在随身携带的包里、车上、客厅，它将为您带来无限好运',
      gift_title: '【开运符】限时赠送：',
      gift_content:
        '即日起至甲辰年正月十五（2024年2月24日）期间，下单年运报告可领开运符（六选一），过期不再赠送，机不可失，不要错过喔！'
    },
    comment: {
      title: '年度运势报告使用感受',
      list: [
        {
          name: '**静  2023.10.3',
          content:
            '大师写得特别详细，各方面都覆盖到了，我最想知道的情感运势也给到了12个月的趋势图，56月桃花最好，2024加油脱单！！！'
        },
        {
          name: '**语  2023.9.16',
          content: '感谢大师的建议，明年适合换工作，太好了！'
        },
        // {
        //   name: '**倾  2023.11.1',
        //   content:
        //     '开运符收到了！我放在入户门附近，每天进出都能看到，很安心，谢谢~'
        // }
        {
          name: '**金  2024.2.16',
          content:
            '新年收到大师的年运报告，对新的一年有了新希望，有数了，谢谢。'
        }
      ]
    },
    illustrate: {
      title: '报告使用说明',
      list: [
        '1.年度运势报告需要填写姓名、性别、生日、预测年份；',
        '2.为保证预测准确性，年度运势报告由专业运势预测大师团队在线分析、预测撰写，一般会在提交信息后24小时内完成报告，请耐心等待；',
        // '3.为了顺利寄出开运符，我们需要收集您的收货信息，需要输入您的真实姓名、具体住址、手机号、邮箱等内容，请认真填写；',
        '3.已完成的年度运势报告您可以通过以下方式查看：'
      ],
      tip_list: [
        '年运报告结果将发送至您填写的邮箱，您可以在邮箱中查看；',
        '下载HiSeer APP，通过您填写的邮箱进行登录后，在 【年度运势报告】-<历史记录>中查看；',
        '在本网页的【历史订单】中，搜索邮箱或订单号进行查看。'
      ],
      contact: '如有疑问，请通过以下方式联系我们：'
    },
    payment: {
      title: '支付确认',
      prod: 'x年度运势 定制报告',
      name: '预测对象',
      pay: '待支付',
      type: '解锁方式：',
      tip: '大师会在24小时内将年运报告发至您的邮箱',
      email: '电子邮箱：',
      button: '验证邮箱',
      e_title: '邮箱验证',
      e_sent: '验证邮件已发送至',
      e_toemail: '请前往收件箱进行验证',
      e_tip: '（如未收到请查看垃圾邮件）',
      e_confirm: '验证完成',
      e_warning: '请先完成邮箱验证',
      success: '验证成功',
      verify_again: '重新验证',
      try_again: '未检测到邮箱验证操作，请重试'
    },
    info: {
      submit: '您的年运定制报告订单已提交给命理师',
      year: '预测年份',
      submit_time: '提交时间：',
      tips_1: '',
      tips: '命理师将在&之前将年运报告结果发送至邮箱email，请耐心等待......',
      tips_out: '命理师正在撰写报告，请耐心等待...',
      tips_gray: `已完成的年运报告您可以通过以下方式查看：
      年运定制报告结果将发送至您填写的邮箱，您可以在邮箱中查看；在本网页的【历史订单】中，搜索邮箱或订单号进行查看。`,
      e_tips_1: '使用邮箱账号',
      e_tips_2: '登录HiSeer App',
      e_tips_3: '可查看年度运势报告',
      confirm: '开运符领取确认',
      confirm_text:
        '开运符为年度运势报告的独家定制赠品，请点击领取并填写收货信息',
      cancel: '放弃领符',
      fillin: '领取开运符',
      cancel_warning:
        '开运符为年度运势报告的独家定制赠品，现在选择放弃后，将无法再次获取，您确认放弃领取开运符的权益吗？',
      giveup: '你已放弃领取开运符',
      deliver: '开运符邮寄费用由我们承担。',
      deliver_tip:
        '为了顺利寄出开运符，请认真填写以下信息，保证信息的真实、准确，如果由于您的填写失误造成无法正常签收开运符，您将自行承担该损失。',
      deliver_tip_1:
        '发货后我们将通过电子邮箱告知您运单号，您也可以在APP中查看开运符进度',
      select: '请选择您想要的开运符种类',
      options: ['财运符', '事业符', '桃花符', '学业符', '平安符', '人缘符'],
      deliver_form: [
        { type: 'name', label: '收货人姓名', placeholder: '请输入收货人姓名' },
        { type: 'phone', label: '手机号', placeholder: '请输入手机号' },
        { type: 'address', label: '收货地址', placeholder: '请输入收货地址' },
        { type: 'email', label: '电子邮箱', placeholder: '请输入电子邮箱' },
        { type: 'postCode', label: '邮政编码', placeholder: '请输入邮政编码' }
      ],
      exceed: '最多输入num字符',
      err: {
        type: '请选择开运符种类',
        name: '请输入收货人姓名',
        phone: '请输入正确的手机号',
        address: '请输入收货地址',
        email: '请输入正确的邮箱',
        postCode: '请输入正确的邮政编码'
      },
      schedule: '开运符进度>',
      outdated: '活动已过期',
      submitted: '收货信息已填写'
    },
    detail: {
      overview: '一、年度综合运势概况',
      over_subtitle: '年度综合运势概况',
      radar: '年度运势雷达图',
      radar_tips: '数值越高代表该方面的运势就越好，仅供参考',
      list: [
        '爱情运势',
        '事业运势',
        '财富运势',
        '学业运势',
        '社交运势',
        '健康运势'
      ],
      tu: '图',
      analy: '二、年度运势详细分析',
      month: '月份',
      score: '得分',
      start: '2月（立春）',
      end: '1月（小寒）',
      guide: '三、助运指南',
      guide_list: [
        '助运发展方向、风水坐向、环境：',
        '助运颜色、数字：',
        '助运饮食：',
        '助运生活方式：',
        '助运饰品： ',
        '助运生肖：'
      ],
      guide_tips:
        '（在确保佩戴饰物、摆件不与本人及家人生肖刑、冲、害的基础上。）',
      master: '大师签名：',
      share: '分享报告>',
      get: '点击获取我的年运 定制报告',
      share_txt: '快来看看HiSeer大师为我撰写的2024年运报告>> HiSeer app - ',
      download: '下载HiSeer App\n获取年度运势报告'
    }
  },
  // 问卦咨询
  hexagram: {
    name: '占卜者姓名',
    gender: '占卜者性别',
    question: '想要占卜的问题',
    update: '换一批',
    question_place: '请输入你想咨询的具体问题',
    q_err: '最多输入50字符',
    start: '起卦',
    manual: '装挂',
    time: '日期',
    time_err: '请输入起卦时间',
    question_1: '卦题',
    title_1: '起卦必读',
    tips: '如何起卦才能得到较为准确的结果',
    cover_list: ['问情感', '问事业', '问财运', '问学业', '问健康'],
    cover_text: '一事一占 准确预测\n大师解读 趋吉避凶',
    more_question: '猜你想问',
    select: '请选择',
    six_err: '六爻选项不能为空',
    list: [
      {
        q: '六爻占卜适用于询问具体事情，越具体越好。',
        r: '今天面试能否成功？',
        f: '我应该从事什么职业？'
      },
      {
        q: '一事一占，每次占卜只问一件具体事情',
        r: '他喜欢我吗？',
        f: '他喜欢我吗？我们会在一起吗？在一起多久？'
      },
      {
        q: '如果问题与时间或数字有关，一定要加上时间或数字前提。',
        r: '我最近一个月能找到工作吗？',
        f: '我能找到工作吗？'
      },
      {
        q: '占卜时集中心神，对问题描述清楚，以便于自己清晰问题or让咨询师更明确的了解您的情况。（问题越具体，解答越准确）'
      }
    ],
    example: [
      {
        title: '情感问卦示例',
        label_q: '问情感',
        question: '我应该跟他结婚生子吗？',
        label_r: '回复示例',
        reply:
          '您好，此卦为风火家人之水雷屯，世爻空说明您对于这个情感的未来心中无底。不过卦中子孙动来生世，说明你们结婚未来还是不错的，对生孩子还是比较合适的。但是值得注意的一点是，您的另一半在未来生活中相比孩子可能不是最重要的。情感生活过得比较平稳不是非常浓烈的那种。不过总体来说两个人结婚生子还是属于比较合适的。另外提示：卦中提示近期需要多注意父母健康和安全。以上供参考。'
      },
      {
        title: '事业问卦示例',
        label_q: '问事业',
        question: '今年能否晋升？',
        label_r: '回复示例',
        reply:
          '您好，根据您的卦象看，今年晋升的机会比较小，同时竞争比较激烈，你身边很多人都在寻求机会，您现在处于比较担忧，有压力的状态下。但是不用担心，您与官同爻，也就是说领导是有关注你并看好你的，晋升是早晚的事情，但不是这一次。升职建议，这段时间多增强自己的专业技能和知识，另外多和领导交流搭建良好的关系。以上是根据卦象给出的结论，请供参考！'
      },
      {
        title: '财运问卦示例',
        label_q: '问财运',
        question: '这次合作能否赚到钱？',
        label_r: '回复示例',
        reply:
          '您好，根据您提供的卦象看，兄弟持世财不上卦，这个合作可能赚钱的几率比较小。您目前心中也是比较没底，对方想推进和合作的意向更高。这个卦象显示破财的迹象更高，有可能您投入的资金难以回本。建议您可以从多维度去评估和调研清楚再进一步考虑投资意向，以上预测供参考。'
      },
      {
        title: '学业问卦示例',
        label_q: '问学业',
        question: '我能考上喜欢的学校吗？',
        label_r: '回复示例',
        reply:
          '您好，此卦为水地比之泽水困，官爻持世，说明考试有难度，您目前也是处于压力较大比较焦虑的状态。另外卦中父母动化兄弟，说明此次考试的竞争性比较大，报考的人比较多，学校会更倾向于录用其它学生。因此总体来说直接考进这个学校的难度比较大，除非有其它资源可以加持。建议：如果非常想进这个学校的话，还需要在成绩上下功夫。如果还有其它目标学校可以再占。以上供参考。'
      },
      {
        title: '健康问卦示例',
        label_q: '问健康',
        question: '孩子最近经常发烧，何时恢复健康？',
        label_r: '回复示例',
        reply:
          '您好，根据您提供的卦象判断，可以看出孩子目前的病情会持续一段时间，可能会有药不对症的情况出现。但是无需过于担心，孩子的情况不会引发其它较严重的症状，最晚不超过午月（阳历6月份），孩子的病情会得到很好的缓解以及自愈。建议：调整好孩子的饮食、生活作息以及孩子的心情状态，提高身体的自我恢复机能。'
      }
    ],
    feedback: {
      title: '问卦感受',
      list: [
        {
          time: '**颜 2023.9.14',
          question: '占卜问题：现在跳槽合适吗？',
          comment: '评价：謝謝！有很大的幫助指引'
        },
        {
          time: '**惟 2023.9.6',
          question: '占卜问题：我应该去挽回这段感情吗？',
          comment: '评价：謝謝指点，收获很多，希望自己可以更好配得上她'
        },
        {
          time: '**伶 2023.9.6',
          question: '占卜问题：我今年能否顺利考上大学？',
          comment:
            '评价：“难度比较大，除非有其它资源可以加持”，确实竞争激烈，但是也真心希望自己可以考上，继续努力~'
        }
      ]
    },
    illustrate: {
      title: '问卦咨询使用说明',
      content: `1.认真填写问卦人姓名、性别、想要测算的问题，一事一占，支付后将提交问卦信息给咨询师；
      2.为保证预测准确性，所有问卦咨询由HiSeer团队从业十余年的资深命理咨询师在线分析、预测撰写，一般会在提交信息后24小时内完成解卦，最终以文字(150字左右)或语音消息的形式回复您，请耐心等待；
      3.如果用户未按照起卦规则，同时提问多个问题 或 提问问题描述不清晰，咨询师会给您发送修改邮件，请您及时进行修改并重新提交；
      4.已完成的问卦咨询记录您可以通过以下方式查看：
      问卦结果将发送至您填写的邮箱，您可以在邮箱中查看；
      下载HiSeer APP，通过您填写的邮箱进行登录后，在 【问卦咨询】中查看；
      在本网页的【历史订单】中，搜索邮箱或订单号进行查看。`
    },
    yaoing: '正在爻卦',
    sixyao: ['一爻', '二爻', '三爻', '四爻', '五爻', '六爻'],
    positive: '正',
    opposite: '反',
    consult: '找大师咨询解卦',
    rule: '咨询规则',
    rules: `1.认真填写问卦人姓名、性别、想要测算的问题，一事一占，支付后将提交问卦信息给咨询师；

    2.为保证预测准确性，所有问卦咨询由HiSeer团队从业十余年的资深命理咨询师在线分析、预测撰写，一般会在提交信息后24小时内完成解卦，最终以文字(150字左右)或语音消息的形式回复您，请耐心等待；

    3.如果用户未按照起卦规则，同时提问多个问题 或 提问问题描述不清晰，咨询师会给您发送修改邮件，请您及时进行修改并重新提交；
    `,
    check_tips: {
      title: '4.已完成的问卦咨询记录您可以通过以下方式查看：',
      list: [
        '问卦结果将发送至您填写的邮箱，您可以在邮箱中查看；',
        '下载HiSeer APP，通过您填写的邮箱进行登录后，在 【问卦咨询】中查看；',
        '在本网页的【历史订单】中，搜索邮箱或订单号进行查看。'
      ]
    },
    info: {
      q: '占卜问题：',
      name: '卦名：',
      detail: '占卜卦象：',
      submitted:
        '问卦咨询已提交\n命理师将在time之前将您的问卦解读结果发送至email,请注意查收',
      waiting: '咨询师处理中，请耐心等待',
      add: [
        '为了更好地帮助您解卦，请描述关于所占卜问题的背景信息，描述越具体，解读越准确。',
        '占卜师需要通过卦象结合实际情况来为您关心的问题做更精准的吉凶趋势判断哦。'
      ],
      add_title: '补充说明：',
      placeholder:
        '针对提问背景进行补充说明，提问的背景描述、前因后果越详细，解读越准确',
      err: '请输入补充说明',
      cancel: '暂不补充',
      confirm: '提交补充信息',
      tips: '使用邮箱账号\nemail登录HiSeer App\n可同步查看问卦解读进度'
    },
    payment: '大师解卦完成将把结果发送至该邮箱',
    detail: '问卦咨询详情',
    reply: '咨询师回复',
    reply_t: '回复时间：',
    replenish: '请补充信息',
    modify: '修改信息',
    playing: '正在播放语音…'
  },
  // 职能报告
  enterprise: {
    assis: '企业助手',
    title: '企业智能人才测评',
    desc: '全方位智能评估 助您慧眼识良才',
    start: '开始测评',
    recruit:
      '招聘中，企业过多关注求职者的表面行为，招到了学历达标、专业对口的员工，但他们在实际工作中却总是表现平平。\n因为大多数企业都忽略了这样一个事实——真正决定员工工作表现的因素，是冰山下的隐性特质。',
    iceburg: {
      top: '可以观察的显性行为',
      t_num: '冰山上30%',
      b_num: '冰山下70%',
      bottom: '难以培养的隐性特质',
      t_keys: ['知识', '技能'],
      b_keys: ['能力', '性格', '动机', '天赋'],
      under:
        '冰山下如此重要，隐性特质很难被培养，企业或岗位又需要具备这些素质的员工，怎么办？为了帮助突破企业招聘困境，我们给出了自己的答案——',
      review: '企业智能人才测评',
      review_desc:
        '测评报告结合最专业的人格算法，高效抓取候选人的内在特质，准确检验候选人真实水平，通过多方面分析，以环环相扣的方式，探究冰山下的真相，助您慧眼识良才。'
    },
    content: {
      title: '企业智能人才测评报告主要内容',
      assess: {
        title: '候选人当前能力评估',
        position: '精准聚焦候选人最突出的特质',
        five: '考量候选人在5个维度各方面的表现',
        vantages: '评估候选人的优劣势',
        radar: ['人际互动', '思维模式', '目标执行', '抱负能量', '情绪成熟度'],
        subtitle_1: '人才定位',
        subtitle_2: '综合评估',
        subtitle_3: '职业特征',
        subtitle_4: '职业能力分布'
      },
      analy: {
        title: '候选人未来发展预测',
        subtitle1: '未来5年职业前景评估',
        list1: ['是巅峰还是低谷？', '是平稳还是波折？'],
        subtitle2: '未来5年职业发展趋势图',
        list2: ['近期能否担任核心岗位？', '稳定程度如何？'],
        ability: '分析候选人的工作能力'
      },
      guide: {
        title: '企业用人指南',
        subtitle1: '如何管理善用此职员',
        list1: [
          '安排何种岗位角色可以发挥其特长',
          '什么样的工作方式可以激发其工作动力',
          '提供什么资源可以帮助其成长'
        ],
        text1: '帮助企业实现真正的因“才”施用',
        subtitle2: '用人风险及注意事项',
        list2: [
          '雇用候选人需要注意哪些事项',
          '候选人在工作中可能会遇到哪些问题',
          '如何管理可以避免用人风险'
        ],
        text2: '协助企业避免用人风险'
      },
      example: '企业智能人才测评报告示例',
      feeling: {
        title: '企业智能人才测评使用感受',
        list: [
          {
            name: '**东 xxxxx公司 人力资源部门经理',
            text: '用这个智能人才测评一段时间，最大的感受是精准！对于我们招聘者而言，找到合适的人比找到厉害的人更重要，这个系统可以帮助我们快速掌握求职者的特点，判断是否合适，提高了我们招人的效率。'
          },
          {
            name: '**敏 xxxxx公司 CEO',
            text: '企业用人指南这部分给我们提供了很多实用的建议，帮我们我完善了企业人才管理方面的制度，而且员工反应也很好啊。'
          },
          {
            name: '**飞 xxxxx公司 VP',
            text: '这个人才测评报告，我主要用来参考核心员工的近期发展和离职意向，这些员工都是经过长时间的磨合和培养的，了解他们的发展状态，对于员工管理和规划很有帮助。'
          }
        ]
      },
      illustrate: {
        title: '企业智能人才测评使用说明',
        content:
          '1.需要认真填写候选人的姓名、性别、生日等信息；\n2.支付后智能人才测评报告将在30秒内生成结果，生成后可以永久查看，已完成的报告您可以通过以下方式查看：记录订单号，或者通过验证邮箱/手机号的方式进行保存，后续可以在本网页的【历史订单】中，搜索邮箱/手机号/订单号进行查看。'
      }
    },
    loading: {
      title: '正在分析......',
      list: ['能力评估', '未来发展', '用人指南']
    },
    object: '测评对象',
    score: '人才评分',
    overall: '综合评分',
    warning:
      '当前测评对象的生日信息不完整，因此分析结果存在不准确的因素，误差在20%左右',
    line_warning:
      '该段内容涉及未来5年职业发展的整体展望，需要注意这一描述并不代表每年发展的好坏。',
    assess: '候选人能力评估',
    analy: '候选人发展预测',
    guide: '企业善用管理指南',
    assess_list: ['思维模式', '人际互动', '情绪成熟度', '目标执行', '抱负能量'],
    year: '年份',
    shot: '保存为图片',
    lunar: '阴历',
    solar: '阳历',
    save_tips: '请仔细记录您填写的账号，\n后续您可通过此账号搜索订单',
    prompt:
      '不保存将会增加报告丢失的风险，保存后您可以在邮箱内查看报告，是否保存报告?',
    success: '发送成功！报告已发送至邮箱请及时查收~'
  },
  // 职能报告个人版
  career: {
    title: '职业发展测评报告',
    desc: '量身定制职业规划 工作发展快人一步',
    items: ['工作能力评估', '职业发展预测', '职业生涯建议'],
    questions: [
      '你还在工作中的困境苦苦挣扎吗？',
      '你是否缺乏动力或觉得自己没有发挥潜力？',
      '还是晋升困境让你夜不能寐？'
    ],
    bubbles: [
      '我适合做什么样的工作？',
      '我的核心竞争力是什么？',
      '该不该跳槽？',
      '明年有晋升机会吗？',
      '哪个工作环境更适合我？'
    ],
    sayno: '<span>职业发展测评</span> ，助你前程无忧，对职场困境说NO！',
    construct: [
      { title: '工作能力评估', content: '快速定位\n你的核心竞争力' },
      { title: '职业发展预测', content: '精准预测\n你的职业运势' },
      { title: '职业生涯建议', content: '量身定制\n你的职业建议' }
    ],
    result: '职业生涯 提前规划\n升职加薪 快人一步',
    content: {
      title: '职业发展测评报告主要内容',
      position: '职业定位',
      positions: [
        '自主独立型',
        '引领开创型',
        '责任承担型',
        '灵活决策型',
        '行动导向型',
        '目标协助型'
      ],
      special: '精准聚焦个人职业特质',
      assess: '评估5个维度各方面的表现',
      character: '快速抓取个人优劣势',
      character_list: [
        '批判性',
        '执行力',
        '适应性',
        '开放性',
        '无视规则',
        '粗心大意',
        '保守'
      ],
      ability: '一体两面分析工作能力',
      forecast: '未来5年职业前景评估',
      forecasts: ['是巅峰还是低谷？', '是平稳还是波折？'],
      trend: '未来5年职业发展趋势图',
      trends: ['升职机遇如何？', '是否存在职业危机？'],
      guide: {
        title: '职场指南',
        list: [
          '选择何种类型的岗位可以发挥自身特长',
          '什么样的工作方式可以激发工作动力',
          '什么样的公司平台可以帮助个人成长'
        ],
        text: '找准真正的职业天赋，轻松实现个人价值'
      },
      advice: {
        title: '发展建议',
        list: [
          '你该如何灵活适应职场环境',
          '如何行事可以避免犯错',
          '哪些关键细节需要格外注意'
        ],
        text: '帮助你减少失误，降低职业风险'
      },
      swiper: '职业发展测评报告示例',
      feeling: {
        title: '职业发展测评报告使用感受',
        list: [
          {
            name: '**志',
            text: '综合评估的部分是怎么做到这么准确的...准到失语，我就是典型的“思维”和“执行”跟得上，所以选择了当码农哈哈哈'
          },
          {
            name: '**栩',
            text: '这个报告不错的，对我们这些马上毕业的学生来讲很实用，测完更有信心了，冲！'
          },
          {
            name: '**鹃',
            text: '好准呐！正如报告测到的，我本人其他能力都一般，也就人际沟通比较擅长，所以现在干销售'
          }
        ]
      },
      illustrate: {
        title: '职业发展测评报告使用说明',
        content:
          '1.需要认真填写候选人的姓名、性别、生日等信息；\n2.支付后职业发展测评报告将在30秒内生成结果，生成后可以永久查看，已完成的报告您可以通过以下方式查看：记录订单号，或者通过验证邮箱/手机号的方式进行保存，后续可以在本网页的【历史订单】中，搜索邮箱/手机号/订单号进行查看。'
      }
    },
    loading: {
      analysing: '正在分析......',
      list: ['工作能力评估', '职业发展预测', '职业生涯建议']
    },
    tablist: ['能力评估', '未来发展', '职业建议']
  },
  // simple-report组件完整天赋部分数据
  simple: {
    // 本名解读
    talent: '最强天赋',
    famous: '典型\n名人名家',
    values: '价值观',
    personality: '性格分析',
    think: '思维特点',
    potential: '潜力提升',
    // 人际交往
    six: '六亲关系',
    elegant: '贵人分析',
    // 职业规划
    career: '事业观',
    advice: '从业建议',
    // 恋爱指南
    love: '爱情观',
    best_lover: '最佳\n交往对象',
    unlock: '解锁完整报告',
    // 拆分报告
    split_keyword: {
      title: '你的天赋关键词',
      content: '提炼你最显著的特征是什么？\n你的人格画像是什么样的？'
    },
    s_talent: {
      title: '你的最强天赋',
      content: '解读你的天赋优势是什么？\n你是哪个类型的潜力股？'
    },
    s_nature: {
      title: '你的性格分析',
      content: '解读你的性格优势是什么？\n分析你的性格弱点是什么？'
    },
    s_potential: {
      title: '潜力提升建议',
      content: '指导你如何克服缺点，发挥优势\n如何发掘潜力实现理想'
    },
    s_talent_image: {
      title: '你的天赋形象'
    },
    s_celebrity: {
      title: '与你相似的名人',
      content: '与你人格相似度最高的名人是谁\n他们从事着什么样的职业'
    },
    s_values: {
      title: '你的价值观',
      content: '解读什么对你来说最重要\n你最具发展潜力的精神特质是什么'
    },
    s_thinking: {
      title: '你的思维特点',
      content: '解读你最擅长的思维方式是什么\n分析哪个方面的思维意识需要提升'
    },
    s_love_nature: {
      nature: '恋爱性格',
      title: '你的恋爱性格',
      content: '解读你在爱情中扮演着怎样的角色\n你会怎样对待你的伴侣'
    },
    s_best_lover: {
      title: '最佳交往对象',
      content: '匹配最适合与你相伴一生的人是谁'
    },
    s_relatives: {
      title: '你的六亲关联性排名',
      content: '解读你与六亲的亲疏关系\n对你影响最大的人是谁'
    },
    s_affect_life: {
      title: '你的贵人',
      content: '分析六亲中谁对你有帮助\n什么类型的人是你的贵人'
    },
    s_profession_nature: {
      title: '你的职业性格',
      content: '解读你在职场中扮演什么角色\n你会怎样对待工作和同事'
    },
    s_profession_ability: {
      title: '你的职业能力分布',
      content: '分析工作能力长短板\n提示需要提升的职业方面'
    },
    s_profession_type: {
      title: '适合你的职业类型',
      content: '指导就业从业方向\n匹配适合你的职业类型'
    }
  },
  // 网络请求
  request: {
    network_err: '网络异常'
  },
  // 免费排盘首页
  plate: {
    eight: '八字排盘',
    name: '姓名',
    gender: '性别',
    country: '出生地',
    birth: '生日',
    submit: '起盘',
    restart: '重新起盘',
    name_placeholder: '请输入姓名',
    birth_placeholder: '请输入出生日期',
    history: '历史记录',
    no_show: '显示\n干支',
    g: '显示\n藏干',
    spirit: '显示\n十神',
    element: '显示\n五行',
    fortunate: '大运',
    flow_year: '流年',
    flow_month: '流月',
    flow_day: '流日',
    flow_hour: '流时',
    more_sun: '下载HiSeer 了解更多日干知识>>',
    more_destiny: '下载HiSeer 了解更多命局知识>>',
    more_predict: '更多预测'
  },
  // 答案之书
  answer_book: {
    title: '答案之书预测',
    search: '搜索',
    search_holder: '搜索答案之书标题',
    no_result: '未搜索到相关结果',
    content:
      '答案之书——为你烦恼之事找到出路，\n覆盖<span>情感、财运、事业、健康</span>等海量问题，\n你想问的都有解答。',
    history: '人测过',
    score: '分',
    start: '开始',
    start_tip: '准备好了点击【开始】',
    stop: '停',
    question_tip: '请在心中默念问题...',
    tips: '准备好了点击停止',
    check: '查看解读',
    download: '<span>下载HiSeer APP</span>\n查看答案之书完整解读',
    pay_prompt: '付费查看完整结果',
    tabs: ['推荐', '情感', '财运', '事业', '学业', '综合'],
    save: {
      tip: '请仔细记录您填写的账号，\n后续您可登录HiSeer app查看结果',
      title: '保存结果到HiSeer App',
      prompt:
        '不保存将会增加结果丢失的风险，保存后您可以使用验证的账号登录HiSeer app查看结果，是否保存？',
      success: [
        '1.打开HiSeer app，使用保存时使用的邮箱或手机号登录，可查看已保存结果',
        '2.点击本网页右上角<span>☰</span>按钮，在历史记录-订单查询页面输入订单号 / 保存时提供的手机号或邮箱进行订单查询，可查看已保存结果'
      ]
    },
    more: '更多占卜',
    save_txt: '保存结果',
    ad_text: '下载HiSeer App\n更多占卜等你来测',
    paid: '人购买了占卜',
    useful: '的人觉得很准',
    loading: '加载中...',
    seer: '以上内容为HiSeer独家研发的占卜服务'
  },
  // 资讯分享
  info: {
    share: '分享',
    public: '发布',
    more: '更多推荐',
    download: '下载HiSeer app使用完整功能',
    prompt: '下载HiSeer app\n阅读《》完整内容',
    read: '阅读',
    replay: '重播',
    tipping: '打赏',
    ivideo_download: '<span>下载HiSeer App</span>\n查看真人占卜完整解读',
    commont: '评论',
    write_comment: '写下你的评论...',
    copyright: '原创占卜 禁止转载抄袭',
    test: '人测过'
  },
  // 独立页面
  out: {
    master: {
      title: '专业咨询师',
      desc: '1对1咨询 在线解答',
      list: [
        {
          bold: '专业：',
          content:
            '由HiSeer专业咨询师提供1对1专业预测服务，所有咨询师都经历过专业的命理学学习，且有丰富的咨询从业经验，经由官方筛选、审核，咨询服务有保障。'
        },
        {
          bold: '多样：',
          content:
            '擅长不同领域的咨询师提供各类咨询项目供用户选择，咨询项目主要包括：命盘详批、一生运势、流年运势、情感运势、事业运势、财富运势、学业规划、亲子教育、感情合盘/合婚、六爻起卦、择日、取名、面相等，多样化咨询服务满足用户需求。'
        },
        {
          bold: '灵活：',
          content:
            '通过HiSeer实时聊天工具、预测工具进行推演并答疑解惑，方便快捷。'
        }
      ]
    }
  }
}
