<template>
  <div class="plate-list">
    <div class="title">
      <div class="name">{{ $t('menu.plate_name') }}</div>
      <!-- <div class="gender">{{ $t('menu.gender') }}</div> -->
      <div class="birth">{{ $t('menu.birth') }}</div>
      <div class="operate">{{ $t('menu.detail') }}</div>
    </div>
    <ul class="list">
      <li class="plate-item" v-for="plate in plateList" :key="plate.id">
        <div class="name">{{ plate.name }}</div>
        <!-- <div class="gender">{{ plate.gender ? $t('male') : $t('female') }}</div> -->
        <div class="birth">{{ plate.showBirth }}</div>
        <div class="operate" @click="checkDetail(plate.id)">
          {{ $t('menu.detail') }} >>
        </div>
      </li>
      <li class="no-item" v-if="!plateList.length">
        <span>{{ $t('menu.no_history') }}</span>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { getPlateList } from '@/utils/menu'
import storage from '@/utils/storage'
import { PLATE_ID, UPDATE_PLATE } from '@/utils/contants'
import { path } from '@/router/constant'
import mitt from '@/plugins/mitt'
const i18n = useI18n()
const route = useRoute()
const router = useRouter()

const plateList = ref(getPlateList())

const closeMenu = inject('closeMenu')

const initPlateList = () => {
  const list = getPlateList()

  // 处理性别

  // 处理生日
  // console.log(list)
}
initPlateList()
// console.log(plateList)

const checkDetail = (id) => {
  console.log(id)
  storage.set(PLATE_ID, id)
  if (route.path === path.plate.detail) {
    mitt.emit(UPDATE_PLATE)
  } else {
    gtag('event', 'bazi_detail_from_history')
    router.replace(path.plate.detail)
  }

  closeMenu()
}

onMounted(() => {
  const list = document.querySelector('.plate-list .list')
  list.addEventListener('touchmove', (e) => {
    e.stopImmediatePropagation()
  })
})
</script>

<style lang="less" scoped>
.plate-list {
  padding: 72px 56px 0;
  height: 100%;
  .title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;

    font-size: 52px;
    & > div {
      text-align: center;
    }
  }
  .list {
    max-height: 1420px;
    height: 100%;
    font-size: 52px;
    overflow-y: auto;
    .plate-item {
      display: flex;
      justify-content: space-between;
      padding: 46px 0;
      & > div {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .operate {
        color: #5193ff;
      }
    }
    .no-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  // 列宽
  .name {
    width: 212px;
    white-space: nowrap;
  }
  .birth {
    width: 50%;
    min-width: 13em;
  }
  .operate {
    width: 286px;
  }
}
</style>
