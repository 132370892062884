<template>
  <div
    class="preview-wrapper"
    :style="{ 'background-color': background }"
    v-if="show"
    @click.stop="clickMask"
  >
    <div class="preview-content" @click.stop="">
      <van-icon
        v-if="closeable"
        size="20"
        class="close"
        name="cross"
        :style="closeStyle"
        :color="closeColor"
        @click="preview(false)"
      />
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const emits = defineEmits(['mask-click'])
const props = defineProps({
  closeable: {
    type: Boolean,
    default: true
  },
  background: {
    type: String,
    default: '#3c3c3c'
  },
  maskClosable: {
    type: Boolean,
    default: true
  },
  closeColor: {
    type: String,
    default: '#fff'
  },
  closeStyle: {
    type: Object,
    default: () => ({})
  }
})

const show = ref(false)

const preview = (value = true) => {
  show.value = value
}

const clickMask = () => {
  emits('mask-click')
  preview(!props.maskClosable)
}
defineExpose({
  preview
})
</script>
<style lang="less" scoped>
@import '@/assets/style/variable.less';
.preview-wrapper {
  box-sizing: border-box;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #3c3c3c;
  z-index: 3;
  .preview-content {
    position: relative;
    display: inline-block;
    max-width: @maxWidth;
    .close {
      position: absolute;
      top: -80px;
      right: 20px;
    }
  }
}
</style>
