<template>
  <div class="answer-list">
    <div class="title">
      <div class="name">{{ $t('hexagram.question') }}</div>
      <div class="operate">{{ $t('menu.operate') }}</div>
    </div>
    <ul class="list">
      <li class="answer" v-for="item in hexagramList" :key="item.orderNo">
        <div class="name">
          <span>{{ item.question }}</span>
        </div>
        <div class="operate" @click="check(item)">{{ detailText }} >></div>
      </li>
      <li v-if="!hexagramList.length" class="no-item">
        <span>{{ $t('menu.no_history') }}</span>
      </li>
    </ul>
    <div class="order-button" @click="openOrder">{{ $t('menu.no_order') }}</div>
  </div>
</template>

<script setup>
import { ref, inject, computed, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import storage from '@/utils/storage'
import { getHexagram } from '@/utils/menu'
import {
  HEXAGRAM_LIST,
  ORDER_MENU,
  LANG,
  HEXAGRAM_DATA
} from '@/utils/contants'
import { path } from '@/router/constant'

const router = useRouter()
const route = useRoute()
const i18n = useI18n()
const closeMenu = inject('closeMenu')
const { mitt } = getCurrentInstance().proxy

const hexagramList = ref(getHexagram())

const detailText = computed(() => {
  if (i18n.locale.value === LANG.KR) return i18n.t('menu.detail')
  return i18n.t('menu.detail').slice(2)
})

const check = (hexagram) => {
  const { s } = hexagram
  storage.set(HEXAGRAM_DATA, { hexagram })
  storage.set('s', s)
  if (route.path.includes(path.hexagram.detail)) {
    router.push({
      path: path.hexagram.detail
    })
    setTimeout(() => {
      mitt.emit('updateS')
    }, 0)
  } else
    router.push({
      path: path.hexagram.detail
    })

  closeMenu()
}

const openOrder = () => {
  mitt.emit(ORDER_MENU)
}
</script>

<style lang="less" scoped>
.answer-list {
  height: 100%;
  padding-top: 66px;
  font-size: 52px;
  .title {
    padding-top: 17px;
    display: flex;
    justify-content: space-around;
    font-weight: bold;
  }
  .list {
    max-height: 1420px;
    height: 100%;
    font-size: 52px;
    overflow-y: auto;
    .answer {
      display: flex;
      justify-content: space-around;
      padding: 45px 0;
      .name {
        width: 11em;

        & > span {
          display: inline-block;
          width: 11em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .operate {
        color: #5193ff;
      }
    }
    .no-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .type,
  .name,
  .operate {
    width: 33%;
    text-align: center;
  }

  .order-button {
    margin: 0 auto;
    margin-top: 50px;
    width: 932px;
    height: 181px;
    line-height: 181px;
    font-size: 72px;
    color: #fff;
    background: #5193ff;
    border-radius: 119px;
    text-align: center;
  }
}
</style>
