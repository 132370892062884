import { createApp } from 'vue'

import router from './router'

import 'virtual:svg-icons-register'
import '@/assets/style/main.less'
import 'normalize.css/normalize.css'

import i18n from './lang'
import App from './App.vue'
// 埋点插件
import '@/plugins/gtag.js'
//阻止安卓字体放大
import '@/plugins/banZoom'
// flexible
import '@/plugins/flexible.js'
// 事件相关
import mitt from './plugins/mitt'

import '@vant/touch-emulator'

import {
  Button,
  ConfigProvider,
  Icon,
  List,
  Loading,
  Popup,
  Overlay,
  Search,
  Swipe,
  SwipeItem,
  Rate,
  Checkbox,
  CheckboxGroup,
  Picker,
  Tab,
  Tabs,
  Skeleton,
  Switch,
  Field,
  NumberKeyboard
} from 'vant'

const app = createApp(App)
// 配置全局事件总线
app.config.globalProperties.mitt = mitt

app.use(Button)
app.use(ConfigProvider)
app.use(Icon)
app.use(List)
app.use(Loading)
app.use(Popup)
app.use(Overlay)
app.use(Search)
app.use(Swipe)
app.use(SwipeItem)
app.use(Rate)
app.use(Picker)
app.use(Tab)
app.use(Tabs)
app.use(Skeleton)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Field)
app.use(Switch)
app.use(NumberKeyboard)
// app.use(Form)
// app.use(Field)

app.use(router)
app.use(i18n)
app.mount('#app')
app.config.errorHandler = (err) => {
  /* 处理错误 */
  console.log(err)
}
