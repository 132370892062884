import { reportBind, reportSimpleDetail } from '../apis/report'
import { addReport } from '../utils/menu'
import { NAME, REPORTLIST, REPORT_TYPE } from '../utils/contants'
import storage from '../utils/storage'

// 报告相关路由
const report = ['birth', 'person', 'destiny', 'love', 'relation', 'profession']

export default async function () {
  // 非报告路由不验证s
  const [_, first] = location.pathname.split('/')
  if (!report.includes(first)) return

  const s = storage.get('s')
  if (!s || s.length < 2) return
  try {
    const {
      data: { name }
    } = await reportSimpleDetail({ s })
    const { code } = await reportBind({ s })

    if (code === 0) {
      const reprotList = storage.get(REPORTLIST) || []
      const reportType = storage.get(REPORT_TYPE)
      const _has = reprotList.some((item) => item.s === s)
      if (!_has) addReport({ name, s, type: reportType })
    }
  } catch (err) {}
}
