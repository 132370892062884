<template>
  <Teleport v-if="loading" to="body">
    <div class="loading-wrapper" :style="{ backgroundColor: background }">
      <van-loading class="loading" :size="size" :color="color">{{
        text
      }}</van-loading>
    </div>
  </Teleport>
</template>
<script setup>
import { ref } from 'vue'
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: '30px'
  },
  color: {
    type: String,
    default: '#5193FF'
  },
  background: {
    type: String,
    default: 'rgba(0, 0, 0, 0.6)'
  }
})
const loading = ref(false)
const show = (value) => {
  loading.value = value
}
defineExpose({
  show
})
</script>
<style lang="less" scoped>
.loading-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: var(--vh);
  z-index: 3;
  // background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;
  // .loading {
  //   margin: 0 auto;
  // }
}
</style>
