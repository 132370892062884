const rootValue = 150
export default function rem(px) {
  return parseFloat(px) / rootValue + 'rem'
}

export function remToPx(rem) {
  const _rem = parseFloat(document.querySelector('html').style.fontSize)
  const ret = (parseFloat(rem) * _rem).toFixed(2)
  return ret
}

export function pxToPx(px) {
  return remToPx(parseFloat(rem(px)))
}
