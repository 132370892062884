<template>
  <div class="report-list">
    <div class="title">
      <div class="type">{{ $t('menu.type') }}</div>
      <div class="name">{{ $t('menu.birth_name') }}</div>
      <div class="operate">{{ $t('menu.operate') }}</div>
    </div>
    <ul class="list" @touchmove.stop="">
      <li class="report" v-for="report in reportList">
        <div class="type">
          <span>{{ report.typeName }}</span>
        </div>
        <div class="name">
          <span v-html="report.name"></span>
        </div>
        <div class="operate" @click="checkReport(report)">
          {{ $t('menu.detail') }} >>
        </div>
      </li>
      <li v-if="!reportList.length" class="no-item">
        <span>{{ $t('menu.no_history') }}</span>
      </li>
    </ul>
    <div class="order-button" @click="openOrder">{{ $t('menu.no_order') }}</div>
  </div>
</template>
<script setup>
import { getReport } from '@/utils/menu'
import storage from '@/utils/storage'
import { path } from '@/router/constant'
import { ref, computed, inject, getCurrentInstance, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  PRODUCT_TYPE,
  REPORT_TYPE,
  ORDER_MENU,
  ANNUAL_DATA
} from '@/utils/contants'

const { mitt } = getCurrentInstance().proxy
const closeMenu = inject('closeMenu')
const router = useRouter()
const route = useRoute()
const i18n = useI18n()

const pathList = [
  path.birth.detail,
  path.personality.detail,
  path.destiny.detail,
  path.love.detail,
  path.relation.detail,
  path.profession.detail,
  path.match.detail,
  '',
  path.annual.detail,
  [, path.enterprise.detail, path.career.detail]
]
const nameList = [
  'menu.entire',
  'menu.person',
  'menu.destiny',
  'menu.love',
  'menu.relation',
  'menu.profession',
  'menu.match',
  '',
  'menu.annual',
  ['', 'menu.enterprise', 'career.title']
]

const more = [9]

const reportList = computed(() => {
  const list = getReport() || []
  return list.map((item) => {
    const { type, sub = 1 } = item
    const key = more.includes(type) ? nameList[type][sub] : nameList[type]
    const typeName = i18n.t(key || '')
    return { typeName, type: item.type || 0, ...item }
  })
})

const updateData = (report) => {
  const { type } = report
  if (type === PRODUCT_TYPE.annual) {
    storage.set(ANNUAL_DATA, report)
  }
}

const checkReport = (report) => {
  const { s, type = 0, sub = 1 } = report
  const _path = more.includes(type) ? pathList[type][sub] : pathList[type]
  storage.set('s', s)
  storage.set(REPORT_TYPE, type)
  updateData(report)
  if (route.path.includes(_path)) {
    // 支付页跳转
    if (/payment|simple/i.test(route.path)) router.replace(_path)
    else router.push({ path: _path })
    setTimeout(() => {
      mitt.emit('updateS', 'history')
    }, 0)
  } else router.replace({ path: _path, query: { from: 'history' } })

  closeMenu()
}

const openOrder = () => {
  mitt.emit(ORDER_MENU)
}

onMounted(() => {
  const list = document.querySelector('.report-list .list')
  list.addEventListener('touchmove', (e) => {
    e.stopImmediatePropagation()
  })
})
</script>
<style lang="less" scoped>
.report-list {
  height: 100%;
  padding-top: 66px;
  font-size: 52px;
  .title {
    padding-top: 17px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
  .list {
    max-height: 1420px;
    height: 100%;
    font-size: 52px;
    overflow-y: auto;
    .report {
      display: flex;
      justify-content: space-between;
      padding: 45px 0;
      .name {
        & > span {
          display: inline-block;
          width: 5em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .operate {
        color: #5193ff;
      }
    }
    .no-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .type,
  .name,
  .operate {
    width: 33%;
    text-align: center;
  }

  .order-button {
    margin: 0 auto;
    margin-top: 50px;
    width: 932px;
    height: 181px;
    line-height: 181px;
    font-size: 72px;
    color: #fff;
    background: #5193ff;
    border-radius: 119px;
    text-align: center;
  }
}
</style>
