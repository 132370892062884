import { ref } from 'vue'
import { reportType } from '@/apis/report'
import { BIRTH_TYPE_STR, REPORT_TYPE } from '@/utils/contants'
import storage from '@/utils/storage'
import { getSearch } from '@/utils/utils'

export const getTypeList = async () => {
  // 初始化
  const { reportType: reportT } = getSearch()

  if (!isNaN(reportT)) storage.set(REPORT_TYPE, parseInt(reportT))

  const {
    data: {
      report: { l: whole, m: list }
    }
  } = await reportType()

  const typeList = whole.concat(list)

  storage.set(BIRTH_TYPE_STR, typeList)

  return typeList
}

export default getTypeList

export const useReportType = async (reportNo) => {
  let list = storage.get(BIRTH_TYPE_STR)
  if (!list) {
    list = await getTypeList()
  }

  const {
    type,
    ext: { h5_product }
  } = list[reportNo] || {}

  return { type, product: h5_product }
}
