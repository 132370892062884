export default {
  get(key) {
    if (!key) {
      return null
    }
    key = key.toString()
    const data = window.localStorage.getItem(key)
    return JSON.parse(data)
  },
  set(key, value) {
    if (!key || value === undefined || value === null) {
      return null
    }
    key = key.toString()
    window.localStorage.setItem(key, JSON.stringify(value))
  },
  update(key, value) {
    if (!key || value === undefined || value === null) {
      return null
    }
    key = key.toString()
    const data = this.get(key)
    window.localStorage.setItem(key, JSON.stringify({ ...data, ...value }))
  },
  delete(key) {
    if (!key) {
      return
    }
    key = key.toString()
    window.localStorage.removeItem(key)
  },
  clear() {
    window.localStorage.clear()
  }
}
