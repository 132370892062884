function mitt() {
  const _map = {}
  return {
    map: _map,
    on: function (type, handler) {
      const handlers = _map[type]
      if (handlers) {
        handlers.push(handler)
      } else _map[type] = [handler]
    },
    off: function (type, handler) {
      const _handlers = _map[type]
      if (_handlers) {
        if (handler) {
          const index = _handlers.indexOf(handler)
          _handlers.splice(index >>> 0, 1)
        } else delete _map[type]
      }
    },
    emit: function (type, data) {
      const handlers = _map[type]
      if (handlers) {
        // handler(data)
        handlers.map((handler) => {
          handler(data)
        })
      }
      const _all = _map['*']
      if (_all) {
        _all.map((handler) => {
          handler(data)
        })
      }
    }
  }
}
export default mitt()
