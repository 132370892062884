import { createI18n } from 'vue-i18n'
import storage from '@/utils/storage.js'
import koKR from './ko-KR'
import zhCN from './zh-CN'
import zhTW from './zh-TW'

export const langList = { ko: 'koKR', sc: 'zhCN', tc: 'zhTW' }
export const lowCaseLang = {
  kokr: 'koKR',
  zhcn: 'zhCN',
  zhtw: 'zhTW',
  zhhk: 'zhTW',
  zhmo: 'zhTW'
}

const search = location.search
  .substring(1)
  .split('&')
  .reduce((prev, _) => {
    const [key, value] = _.split('=')
    return { ...prev, [key]: value }
  }, {})

let locale = 'koKR'
try {
  locale = storage.get('lang')
} catch (err) {
  console.log(err)
  locale = localStorage.getItem('lang')
  // console.log(err)
}
locale =
  langList[search['dy-lang']] ||
  locale ||
  lowCaseLang[
    window.navigator.language.split('-').join('').toLocaleLowerCase()
  ] ||
  'zhCN'
storage.set('lang', locale)

const messages = {
  koKR,
  zhCN,
  zhTW
}

export default createI18n({
  locale,
  fallbackLocale: 'zhCN',
  warnHtmlMessage: false,
  legacy: false,
  messages
})
