// 历史记录
export const REPORTLIST = 'report-list' // 报告列表
export const PLATE_LIST = 'plate-list' // 排盘列表
export const ANSWER_LIST = 'answer-list' // 答案之书列表
export const CURRENT_MATCH = 'current-match' // 合盘报告
// 排盘id
export const PLATE_ID = 'plate-id'
// 答案之书
export const ANSWER_INFO = 'answer_info'
export const ANSWER_PROD = 'answer_product'
export const ANSWER_FREE = 'answer_free'
// 宫合报告价格
export const MATCH_PRICE = 'MATCH_PRICE'
// 职业个人报告
export const CAREER = 'CAREER'

/**
 * 事件名称
 */
export const UPDATE_PLATE = 'update-plate' // 排盘更新事件名
export const UPDATE_ANSWER = 'update-answer' // 答案之书更新事件
export const MENU_DOWNLOAD = 'menu-download' // 顶部菜单按钮下载
export const ORDER_MENU = 'order-menu' //打开订单查询菜单

// 二十四节气key
export const TWENTY_FOUR = [
  // 小寒开头
  'xiaohan',
  'dahan',
  'lichun',
  'yushui',
  'jingzhe',
  'chunfen',
  'qingming',
  'guyu',
  'lixia',
  'xiaoman',
  'mangzhong',
  'xiazhi',
  'xiaoshu',
  'dashu',
  'liqiu',
  'chushu',
  'bailu',
  'qiufen',
  'hanlu',
  'shuangjiang',
  'lidong',
  'xiaoxue',
  'daxue',
  'dongzhi'
]

export const Char2TWENTY_FOUR = {
  立春: 'lichun',
  惊蛰: 'jingzhe',
  清明: 'qingming',
  谷雨: 'guyu',
  立夏: 'lixia',
  芒种: 'mangzhong',
  小暑: 'xiaoshu',
  立秋: 'liqiu',
  白露: 'bailu',
  寒露: 'hanlu',
  立冬: 'lidong',
  大雪: 'daxue',
  小寒: 'xiaohan'
}

// 五行列表
export const fiveEl = ['water', 'wood', 'fire', 'earth', 'gold']

export const GENDER = 'GENDER'
export const BIRTH = 'BIRTH'
export const NAME = 'NAME'
//
export const LANG = {
  CN: 'zhCN',
  TW: 'zhTW',
  KR: 'koKR'
}

// 支付方式
export const PAYTYPE = {
  WECHAT: 'wechat',
  TOSS: 'toss',
  KAKAO: 'kakao',
  STRIPE: 'stripe',
  PAYPAL: 'paypal',
  FREE: 'free'
}

// 支付成功埋点
export const payBuryList = {
  wechat: [
    'report_pay_wechat_success',
    'renge_pay_wechat_success',
    'mingren_pay_wechat_success',
    'aiqing_pay_wechat_success',
    'guiren_pay_wechat_success',
    'zhiye_pay_wechat_success'
  ],
  paypal: [
    'report_pay_paypal_success',
    'renge_pay_paypal_success',
    'mingren_pay_paypal_success',
    'aiqing_pay_paypal_success',
    'guiren_pay_paypal_success',
    'zhiye_pay_paypal_success'
  ],
  stripe: [
    'report_pay_stripe_success',
    'renge_pay_stripe_success',
    'mingren_pay_stripe_success',
    'aiqing_pay_stripe_success',
    'guiren_pay_stripe_success',
    'zhiye_pay_stripe_success'
  ],
  toss: [
    'report_pay_toss_success',
    'renge_pay_toss_success',
    'mingren_pay_toss_success',
    'aiqing_pay_toss_success',
    'guiren_pay_toss_success',
    'zhiye_pay_toss_success'
  ]
}

// 区分地区
export const CURRENCY = 'currency'
export const DEFAULT_COUNTRY = 'CN'
export const CURRENCY_NAME = {
  KRW: 'KRW', //韩元
  CNY: 'CNY', //人民币
  HKD: 'HKD', //港元
  TWD: 'TWD', //台币
  MYR: 'MYR', //马来
  SGD: 'SGD' //新加坡
}
export const country2Currency = {
  CN: 'CNY',
  TW: 'TWD',
  HK: 'HKD',
  US: 'USD',
  SG: 'SGD',
  MY: 'MYR',
  KR: 'KRW',
  MO: 'MOP'
}
// export const WECHAT = 'wechat'
// export const TOASS = 'toass'
// export const FREE = 'free'

// 粉丝订单编号
export const FAN_NO = 'FAN_NO'
export const FAN_TYPE = 'FAN_TYPE'
export const ORDER_NO = 'ORDER_NO'
// 命局列表
export const destinyList = ['yin', 'bi', 'shang', 'cai', 'sha']

// 支付页面
export const lastName = {
  zhCN: [
    '周',
    '刘',
    '王',
    '李',
    '白',
    '姜',
    '黄',
    '陈',
    '张',
    '吴',
    '郑',
    '林',
    '赵',
    '杨',
    '许',
    '孙',
    '曹',
    '徐'
  ],
  zhTW: [
    '周',
    '劉',
    '王',
    '李',
    '白',
    '薑',
    '黃',
    '陳',
    '張',
    '吳',
    '鄭',
    '林',
    '趙',
    '楊',
    '許',
    '孫',
    '曹',
    '徐'
  ]
}

// 常用国家
export const frontCountry = ['cn', 'tw', 'hk', 'mo', 'kr', 'jp', 'my', 'sg']

export const Mrs = '女士'
export const Mr = '先生'

export const talent_CN = '天赋报告'
export const talent_TW = '天賦報告'
export const birth_CN = '生日报告'
export const birth_TW = '生日報告'

export const PEOPLE_NUM = 'people-number'
export const MATCH_NUM = 'match-number'

export const EMAIL = 'hiseer.lok@gmail.com'
export const WECHAT_ID = 'SeerLegend'
export const INSTAGRAM = 'SEERLEGEND'

// 年运报告
export const ANNUAL_DATA = 'ANNUAL_DATA'
export const ANNUAL_PRICE = 'ANNUAL_PRICE'

// 年运报告
export const ENTERPRISE_DATA = 'ENTERPRISE_DATA'
export const ENTERPRISE_PRICE = 'ENTERPRISE_PRICE'

// 问卦咨询
export const HEXAGRAM_DATA = 'HEXAGRAM_DATA'
export const HEXAGRAM_LIST = 'HEXAGRAM_LIST'

// 商品类型
export const REPORT_TYPE = 'REPORT_TYPE'
export const PRODUCT_TYPE = {
  match: 3,
  birth: 4,
  divine: 5,
  answer: 6,
  annual: 8,
  hexagram: 7,
  enterprise: 9
}
export const BIRTH_TYPE_STR = 'PRODUCT_TYPE'
export const BIRTH_TYPE = {
  WHOLE: 0,
  PERSON: 1,
  DESTINY: 2,
  LOVE: 3,
  INTERPERSON: 4,
  CAREER: 5,
  MATCH: 6
}

// 商品详情路径
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL'

// 推广商品
export const AD_DATA = 'AD_DATA'
export const CHECK_SAVE_STATUS = 'CHECK_SAVE_STATUS'
export const OPEN_TYPE = 'OPEN_TYPE'
