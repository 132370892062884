import request from './request.js'
import storage from '../utils/storage.js'

export const reportSimpleDetail = (params) =>
  request('/public/report/simpleDetail', params, 'GET')

export const reportDetail = (params) =>
  request('/public/report/detail', params, 'GET')

export const reportRate = (data) =>
  request('/public/report/comment', data, 'POST')

export const reportShare = (params) =>
  request('/public/report/shareContent', params, 'GET')

// 报告绑定
export const reportBind = async (params) => {
  try {
    const t = storage.get('t') || ''
    const res = await request('/public/report/bind', { t, ...params }, 'GET')
    // storage.delete('t')
    return res
  } catch (error) {
    throw error
  }
}

// 邮箱验证码
export const emailCode = (params) => request('/public/emailSend', params, 'GET')

// 保存到邮箱
export const save2Email = (params) =>
  request('/public/report/saveToEmail', params, 'GET')
// 手机验证码
export const phoneCode = (params) => request('/public/sms', params, 'GET')
// 保存到手机
export const save2Phone = (params) =>
  request('/public/report/saveToPhone', params, 'GET')

// toss支付
export const tossPre = (params) =>
  request(
    '/public/pay/tossPrepare',
    { product: 'seer_report', ...params },
    'GET'
  )

//
export const tossVerify = (params) =>
  request('/public/pay/tossVerify', params, 'GET')

export const virtualPay = (params) =>
  request('/public/pay/testPay', { product: 'seer_report', ...params }, 'GET')

export const kakaoPre = (params) =>
  request(
    '/public/pay/kaKaoPrepare',
    { product: 'seer_report', ...params },
    'GET'
  )

export const kakaoVerify = (params) =>
  request('/public/pay/kaKaoVerify', params, 'GET')

export const stripe = (params) =>
  request('/public/pay/stripe', { product: 'seer_report', ...params }, 'GET')

// paypal支付
export const paypal = (params) =>
  request('/public/pay/paypal', { product: 'seer_report', ...params }, 'GET')

export const checkBind = (params) =>
  request('/public/pay/hasBind', params, 'GET')

// 流量统计
export const statistics = (params) =>
  request('/public/spread', { key: 'birth_report_view', ...params }, 'GET')

export const getArea = () => request('/public/ipInfo', {}, 'GET')

// 商品价格
export const getPrice = (params) =>
  request('/public/product/price', { product: 'seer_report', ...params }, 'GET')

export const getDesc = (params) => request('/public/translate', params, 'GET')

export const plate = (data) => request('/public/service/plate', data, 'POST')

export const reportType = () => request('/public/tab', {}, 'GET')

// 订单查询
export const reportSearch = (params) =>
  request('/public/service/hasUsedZero', params, 'GET')

export const orderSearch = (params) =>
  request('/public/service/search', params, 'GET')

// 免费保存报告
export const freeReport = (params) =>
  request('/public/pay/zero', params, 'POST')

// 档位获取价格
export const grades = (params) =>
  request('/public/product/grades', params, 'GET')
