<template>
  <transition name="overlay-animate">
    <div class="overlay-popover" v-show="show">
      <div class="menu-wrapper">
        <!-- 路由列表 -->
        <ul class="seer-route">
          <li
            class="route-item"
            :class="{ active: selectedRoute === routeHome.key }"
            @click="updateActiveRoute(routeHome.key)"
          >
            {{ routeHome.name }}
            <div class="border-bottom"></div>
          </li>
          <!-- 预测项目 -->
          <li class="route-item" @click="showList = !showList">
            <span>{{ $t('menu.project') }}</span>
            <svg-icon
              class="arrow"
              :name="showList ? 'nav-arrow-up' : 'nav-arrow-down'"
              size="80"
            ></svg-icon>
            <div class="border-bottom"></div>
          </li>
          <!--  -->
          <transition>
            <li class="project" v-show="showList">
              <div
                class="route-item"
                :class="{ active: selectedRoute.includes(route.key) }"
                v-for="route in routeList"
                :key="route.key"
                @click="updateActiveRoute(route.key)"
              >
                {{ route.name }}
                <div class="border-bottom"></div>
              </div>
            </li>
          </transition>
          <!-- 历史记录 -->
          <li class="route-item" @click="openHistory">
            {{ $t('menu.history') }}
            <div class="border-bottom"></div>
          </li>
          <!-- 联系客服 -->
          <li class="route-item">
            <a @click="openContact($event, true)">
              {{ $t('menu.contact') }}
            </a>
            <div class="border-bottom"></div>
          </li>
          <li class="route-item">
            <a :href="agreement().secret" @click="emits('close')">
              {{ $t('menu.privacyArgeement') }}
            </a>
            <div class="border-bottom"></div>
          </li>
          <li class="route-item">
            <a :href="agreement().user" @click="emits('close')">
              {{ $t('menu.userArgeement') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </transition>
  <History ref="historyRef" />
  <!-- 联系客服面板 -->
  <animation position="left">
    <div class="contact--panel" v-if="contact">
      <div class="contact-header">
        <van-icon
          class="left"
          name="arrow-left"
          :size="pxToPx(80)"
          @click="openContact($event, false)"
        />
        <span class="title">{{ $t('menu.contact') }}</span>
      </div>
      <Contact />
    </div>
  </animation>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { agreement } from '@/utils/utils.js'
import { path } from '@/router/constant'
import pxToRem, { pxToPx } from '@/utils/pxToRem.js'
import SvgIcon from '@/components/SvgIcon.vue'
import Animation from '@/components/Animation.vue'
import Contact from './Contact.vue'
import History from './History.vue'
import Preview from '@/components/Preview.vue'

const contact = ref(false)
const historyRef = ref()
const i18n = useI18n()
const router = useRouter()
const route = useRoute()
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['close'])
const showList = ref(true)

// 路由相关
const routeHome = ref({ name: 'Home', route: path.home, key: path.home })
const routeList = computed(() => [
  {
    key: path.personality.home,
    name: i18n.t('menu.person')
  },
  {
    key: path.destiny.home,
    name: i18n.t('menu.destiny')
  },
  {
    key: path.love.home,
    name: i18n.t('menu.love')
  },
  {
    key: path.relation.home,
    name: i18n.t('menu.relation')
  },
  {
    key: path.profession.home,
    name: i18n.t('menu.profession')
  },
  { key: path.birth.home, name: i18n.t('menu.entire') },
  {
    key: path.plate.home,
    name: i18n.t('menu.plate')
  },
  {
    key: path.answer.home,
    name: i18n.t('menu.answer')
  },
  {
    key: path.match.home,
    name: i18n.t('menu.match')
  },
  {
    key: path.annual.home,
    name: i18n.t('menu.annual_custom')
  },
  {
    key: path.hexagram.home,
    name: i18n.t('front.hexagram')
  },
  {
    key: path.enterprise.home,
    name: i18n.t('menu.enterprise')
  },
  {
    key: path.career.home,
    name: i18n.t('career.title')
  }
])
const selectedRoute = ref('')

const updateActiveRoute = (_route) => {
  console.log(_route)
  if (!selectedRoute.value.includes(_route) || _route === path.home) {
    selectedRoute.value = _route
    router.push({ path: _route })
  }
  emits('close')
}

const overlayStyle = {
  marginTop: pxToRem(178),
  height: `calc(100% - ${pxToRem(178)})`,
  width: '100%',
  backgroundColor: 'transparent'
}

const openHistory = (opt) => {
  // console.log(opt)
  historyRef.value.show(opt)
}
// useWatchLang(updateReportList)

const openContact = (e, value) => {
  e.preventDefault()
  contact.value = value
}

watch(
  () => props.show,
  (show) => {
    // console.log(show)
    if (show) {
      selectedRoute.value = route.path
      // updateReportList()
    } else {
      contact.value = show
      historyRef.value.show({ value: false })
    }
  }
)

defineExpose({
  openHistory
})
</script>
<style lang="less" scoped>
@import '@/assets/style/variable.less';
.overlay-popover {
  position: absolute;
  background: #fff;
  // top: 236px;
  height: calc(var(--vh) - @menuHeight);
  max-width: @maxWidth;
  width: 100%;
}
.menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  // padding-top: 76px;
  padding-left: 114px;
  padding-bottom: 25px;
  background-color: #fff;
  .lang-item {
    height: 90px;
    line-height: 90px;
    font-size: 64px;
    color: #333;
    padding: 18px 0;
    padding-left: 100px;

    .selected {
      margin-left: 48px;
    }
  }
  .select-item {
    display: flex;
    align-items: center;
    padding-left: 0;
    span {
      padding-left: 30px;
    }
    .arrow {
      padding-left: 36px;
    }
  }

  .seer-route {
    .project {
      .route-item {
        text-indent: 2em;
      }
    }
    .route-item {
      display: flex;
      position: relative;
      height: 90px;
      line-height: 90px;
      font-size: 64px;
      color: #333;

      padding-top: 70px;
      padding-bottom: 68px;
      a {
        color: #333;
      }
      .border-bottom {
        width: calc(100% + 114px - 128px);
        // prettier-ignore
        height: 1Px;
        background: #e7e7e7;
        position: absolute;
        border: 0;
        bottom: 0;
        margin-left: -50px;
      }

      // &::after {
      //   display: block;
      //   content: '';
      //   background: #e7e7e7;
      //   width: 1376px;
      //   height: 1px;
      //   margin-left: -52px;
      //   margin-top: 68px;
      // }
      :deep(.router-link-exact-active) {
        color: #5193ff;
        font-size: 72px;
      }
    }
    .active {
      height: 100px;
      line-height: 100px;
      font-size: 72px;
      color: #5193ff;
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }
}

.contact--panel {
  position: fixed;
  bottom: 0;
  left: 0;
  // transform: translateX(-50%);
  z-index: 3;
  // max-width: @maxWidth;
  width: 100%;
  height: calc(var(--vh) - @menuHeight);
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  .contact-header {
    width: 100%;
    max-width: @maxWidth;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // align-items: center;
    height: 284px;
    width: 100%;
    text-align: center;
    .left {
      position: absolute;
      left: 60px;
      top: 50%;
      transform: translateY(-50%);
    }
    .title {
      font-size: 72px;
      font-weight: 500;
      letter-spacing: 2px;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.overlay-animate-enter-from,
.overlay-animate-leave-to {
  transform: translateX(-100%);
}
.overlay-animate-enter-active,
.overlay-animate-leave-active {
  transition: transform 0.5s ease-in-out;
}
</style>
