export default {
  you: '당신',
  him: '그',
  her: '她',
  male: '남',
  female: '여',
  ming: '자',
  age: '살',
  years: '나이',
  sunStem: '일간',
  fate: '사주격국',
  name: '이름',
  birth: '생일',
  // 命格
  destiny: [
    '인중국(印重局)', //
    '비견국(比肩局)', //
    '상관국(傷官局)', //
    '재왕국(財旺局)', //
    '살중국(煞重局)' //
  ],
  // 两仪
  opposite: {
    black: '음',
    white: '양'
  },
  // 五行
  fiveEl: {
    gold: '金',
    wood: '木',
    water: '水',
    fire: '火',
    earth: '土'
    // gold: '금 ',
    // wood: '목 ',
    // water: '목 ',
    // fire: '목 ',
    // earth: '토 '
  },
  // 天干
  // prettier-ignore
  stem: {
    '甲': '갑',
    '乙': '을',
    '丙': '병',
    '丁': '정',
    '戊': '무',
    '己': '기',
    '庚': '경',
    '辛': '신',
    '壬': '임',
    '癸': '계'
  },
  // 地支
  // prettier-ignore
  branch: {
    '子': '자',
    '丑': '축',
    '寅': '인',
    '卯': '묘',
    '辰': '진',
    '巳': '사',
    '午': '오',
    '未': '미',
    '申': '신',
    '酉': '유',
    '戌': '술',
    '亥': '해'
  },
  // 二十四节气
  twenty_four: {
    lichun: '입춘',
    yushui: '우수',
    jingzhe: '경칩',
    chunfen: '춘분',
    qingming: '청명',
    guyu: '곡우',

    lixia: '입하',
    xiaoman: '소만',
    mangzhong: '망',
    xiazhi: '하지',
    xiaoshu: '소서',
    dashu: '대서',

    liqiu: '입추',
    chushu: '처서',
    bailu: '백로',
    qiufen: '추분',
    hanlu: '한로',
    shuangjiang: '상강',

    lidong: '입동',
    xiaoxue: '소설',
    daxue: '대설',
    dongzhi: '동지',
    xiaohan: '소한',
    dahan: '대한'
  },
  // 日期
  time: '시간',
  unknow: '모름 ',
  extra: '윤',
  solar: '양력',
  lunar: '음력',
  year: '년',
  month: '월',
  day: '일',
  hour: '시',
  minute: '분',
  second: '초',
  // 公共按钮
  iknow: '다음',
  save: '저장',
  no_save: '아니요',
  confirm: '확인',
  prompt: '알림',
  cancel: '취소',
  submit: '제출하기',
  download: '로 이동',
  to_download: '확인',
  search: '조회',
  estimate: '바로 알아보기',
  unlock: '바로 알아보기',
  check_report: '바로 알아보기',
  birth_report: '리포트',
  cal_name: '분석자 이름',
  orderno: '주문번호',
  order_tips: '주문번호는 리포트 조회에 사용할 수 있습니다.',
  title: 'HiSeer-당신만의 운세 전문가',
  title_birth: 'HiSeer-올인원 리포트',
  title_plate: 'HiSeer-사주팔자',
  birth_desc: '모두에게 통용되는 운세 어플! 궁금하지 않습니까?',
  plate_desc: '사주 간명에 필요한 모든 정보를 알 수 있는 기능!',
  click_right: 'Right click to save the image',
  // 评价
  rate: {
    rate: '평점 및 리뷰',
    place: '평점 및 리뷰',
    rate_q: '어떤 점이 개선되어야 한다고 생각하십니까?',
    advices: [
      '내용이 풍부하지 않습니다.',
      '풀이 속도가 너무 느립니다.',
      '가격이 저렴하지 않습니다.',
      '예측이 정확하지 않습니다.',
      '기타(작성해 주세요)'
    ]
  },
  nav: {
    title: 'HiSeer 독창적운세',
    button: '앱 열기'
  },
  menu: {
    project: '독창적 운세',
    talentReport: '리포트',
    plate: '사주팔자',
    answer: '해결의 책',
    type: '리포트 유형',
    entire: '올인원 리포트',
    person: '인격 리포트',
    destiny: '운명 리포트',
    love: '연애 리포트',
    profession: '직업 리포트',
    relation: '인간관계 리포트',
    match: '궁합',
    annual: '프리미엄 신년운세',
    enterprise: '인재 평가',
    annual_custom: '맞춤형 신년운세',
    hexagram: '주역점',
    career: '직업 발전평가',
    operate: '조회',
    order_search: '주문 조회',
    history: '풀이내역',
    privacyArgeement: '개인정보정책',
    userArgeement: '이용약관',
    contact: '문의하기',
    home: '천부적재능 알아보기',
    result: '천부적재능 리포트',
    help: '상담, 문의 연락처',
    wechat: 'Instagram:',
    email: 'Email:',
    no_history: '기록이 없습니다',
    report: '올인원 리포트',
    plate_name: '닉네임',
    birth_name: '분석자 이름',
    order_type: '구매리스트',
    order_name: '구매콘텐츠',
    theme: '질문',
    gender: '성별',
    birth: '생일',
    detail: '자세히 보기',
    no_history: '기록이 없습니다',
    no_order: '주문을 못 찾으셨습니까?',
    no_result: '관련 기록을 찾을 수 없습니다',
    order_tips: '주문번호/연동된 전화번호나 이메일 주소를 입력하세요.'
  },
  time_picker: {
    toast: '1900년부터 오늘까지만 입력 가능합니다.',
    nohour: '시간모름 ',
    title: '태어난 시간을 선택하십시오',
    place: '시간 선택',
    unknow: '시간모름',
    error: '정확한 생일을 입력해주세요',
    change: '절기 변화',
    y_range: '연 입력 범위',
    m_range: '월 입력 범위1-12',
    d_range: '일 입력 범위',
    hour_err: '출생 시간을 선택하세요',
    clear: '삭제',
    delete: '삭제'
  },
  payment: {
    only: '할인',
    price: '원가',
    icon: '₩',
    before_price: '',
    after_price: '',
    origin_price: '23000',
    sale: '5900',
    pay: '구매하기',
    fail: '결제 실패',
    title: '의 천부적재능 리포트',
    order_confirm: '결제 처리중...',
    type_item_0: {
      title: '사주해설',
      improve: '개인적 발전',
      text_0: '올인원 리포트',
      text_1: '사고특성'
    },
    type_item_1: {
      title: '인간관계',
      improve: '인맥 관리',
      text_0: '육친관계',
      text_1: '귀인운'
    },
    type_item_2: {
      title: '진로지도',
      improve: '미래 발전',
      text_0: '직업관',
      text_1: '직업 능력'
    },
    type_item_3: {
      title: '연애지침서',
      improve: '연애운',
      text_0: '연애관',
      text_1: '배우자 유형'
    },
    try_later: '잠시후 다시 시도해 주십시오',
    slogan_0: '천부적재능 파악',
    slogan_1: '정교한 분석',
    slogan_2: '색다른 미래',
    sale_text: '할인',
    until_end: '타임세일',
    free_btn: '무료 보기',
    people_paid: '명이 구매하였습니다',
    useful: '의 사람들은 만족합니다.',
    save_modal: {
      button: '무료 보기',
      title:
        '인증만 하면 무료로 리포트를 받을 수 있습니다\n전화번호 혹은 이메일으로 인증',
      error: '무료는 딱 한번!',
      prompt: '알림',
      tip: '무료 리포트 포기하시겠습니까?',
      success: '무료로 리포트를 받으셨습니다.',
      success_h5: '알림창 닫아 리포트 보기',
      success_txt:
        'HiSeer앱에서 방금 인증된 전화번호 혹은 이메일으로 로그인하면 리포트를 볼 수 있습니다.',
      open_app: '앱으로 이동하기',
      continue: '네',
      cancel: '아니요',
      outdated: '이벤트가 종료되었습니다'
    }
  },
  share: {
    card: '의 천부적재능 카드',
    qr: '스캔하기',
    copy_url: 'HiSeer에서 천부적재능 알아보는 리포트를 준비했습니다.',
    copy_success: '링크가 복사되었습니다',
    share_card: '공유하기',
    check_mine: '나의 천부적재능을 알아보기',
    earth: '지구상',
    talent_first: '의 사람은 비슷한 재능을 가지고 있습니다. ',
    talent_rest: '도 그 중 하나입니다.',
    save_tip: '길게 눌러 저장하기'
  },
  // 打开app页面
  open_app: {
    gift: '당신에게 리포트 무료쿠폰을 1장 증정',
    receive: 'HiSeer 앱에서 나의 초대코드를 입력만 하면 됩니다.',
    coupon: '리포트 무료쿠폰',
    copyed: '초대코드 복사되었습니다',
    code: '나의 초대코드',
    tips: '오른쪽 상단을 클릭하여\n기본 브라우저로 열기',
    loading: '로딩중'
  },
  front: {
    title: 'HiSeer 리포트',
    title_dload: 'HiSeer APP로 이동',
    title_plate: 'HiSeer 독창적 운세',
    hexagram: '주역점',
    hexagram_desc: '일사일점 전문가 해괘',
    plate: '사주 팔자',
    answer: '해결의 책',
    answer_desc: '모든 고민은 여기서 풀어줍니다',
    hiseer: '더 많은 콘텐츠를 알아보기',
    contact: '문의',
    contact_tip: '상담 비즈니즈 문의',
    email: '이메일 주소',
    wechat: 'wechat',
    annual_activity: '행운 부적은 덤',
    annual_swiper: '실시간 상담로 100%해답을 드립니다',
    annual_bold: '1:1 상담',
    planning: '개인용',
    route: {
      reports: ['인격', '운명', '연애', '직업', '인간관계'],
      five_in_one: '다섯가지 하나로',
      entire: '올인원 리포트',
      big_txt: ' 혜자',
      person: '인격 리포트',
      person_desc: '잠재적 재능 발굴',
      destiny: '운명 리포트',
      destiny_desc: '본인과 비슷한 명인 매칭',
      love: '연애 리포트',
      love_desc: '당신의 소울메이트 찾아주기',
      relation: '인간관계 리포트',
      relation_desc: '운명의 귀인',
      profession: '직업 리포트',
      profession_desc: '사업관, 직업 유형'
    }
  },
  home: {
    history_order: '풀이&내역',
    report_title: '사주팔자의 분석을 통해 천부적 재능을 파악하기',
    option_0: '사주팔자',
    option_1: '천부적재능',
    option_2: '같은 유형 명인',
    option_3: '사고특성',
    option_4: '잠재력 향상',
    option_5: '육친관계',
    option_6: '진로지도',
    option_7: '연애지침서',
    title: '정보를 먼저 입력해 주세요',
    form: {
      title: {
        self: '본인이 맞나요',
        birth: '생일',
        gender: '성별',
        name: '닉네임'
      },
      birth_placeholder: '출생년월일을 입력해주세요',
      name_placeholder: '닉네임을 입력해주세요',
      name_err: '20개 글자 이하',
      option: {
        self_one: '예',
        self_tow: '아니요',
        male: '남',
        female: '여'
      }
    },
    history: '사주 팔자 기록에서 선택하기',
    argeement: '본 서비스를 이용하면 <a>[이용약관]</a>에 자동으로 동의됩니다.',
    button: '바로 알아보기',
    share: '공유하기',
    copy_share: '모두에게 통용되는 천부적재능 리포트! 궁금하지 않습니까?',
    talent: {
      question_1: '올인원 리포트는 무엇인가요?',
      answer_1:
        '사람마다 태어나는 순간 우주는 생명에 에너지를 불어넣고 에너지의 차이는 다른 개체를 만들어내기 때문에 우리는 사람마다 다른 인격, 타고난 재능, 외모, 사회적 관계를 가지고 다른 운명을 갖게 됩니다.<br/>올인원 리포트는 생일 시간을 기반으로 하며, 성격과 재능에서 출발하여 생일에 있는 생명 정보를 전방위적이고 다각도로 해석하여 좋은 인생 길을 갈 수 있도록 도와줍니다.',
      question_2:
        '<div>천부적 재능을 결정하는</div><div>요소는 무엇입니까?</div>',
      answer_2:
        '모든 사람의 생년월일은 모두 하늘님에 달려 있으며 예측할 수 없고 변경할 수도 없습니다. 각각의 <strong>올인원 리포트</strong>은 바로 <strong>생년월일</strong> 안에 숨겨져 있습니다.',
      question_3:
        '당신은 자신이나 그 사람에 대해 많은 궁금을 가지고 있습니까?<br/>당신은 생년월일로 자신을 더 알고 싶습니까?',
      answer_3:
        '같은 의혹이 있다면 HiSeer과 함께 "생일코드"의 신비한 베일을 벗고 자신이나 그 사람의 운명을 명리학적으로 살펴보세요.',
      unlock_count: '2.5만명이 구매하였습니다',
      report_rate: '리포트 평가',
      accuracy: '적중률',
      usable: '실용성',
      satisfaction: '만족도'
    },
    // swiper
    swiper: {
      first: {
        title: '《천부적 재능 리포트》를 통해 당신이 알 수 있는 것은:',
        desc: {
          title_1:
            '사주팔자의 분석을 통해 자신의 천부적 재능을 전면적이고 다각도로 해설합니다:',
          list_1: `키워드로 성격 장단점을 성명하게 그려냄
          개인 최고의 천부적 재능을 구체적으로 표현
          가치관 분석, 성격 분석, 사유 특성 분석
          같은 유형의 명인 참`,
          title_2: '세 가지 측면에서 개인의 발전을 분석합니다:',
          list_2: `타인과의 사교 관계 분석
          연애표현 분석
          직업 능력, 장단판 분석`,
          title_3:
            '사주팔자를 통해 천부적 재능의 장단점을 분석하고 이에 대한 잠재력을 끌어올리는 방법, 사교적 주의사항, 최고의 연애 상대 및 진로지도를 제공합니다.'
        },
        option_1: '천부적 장점을 살리다',
        option_2: '자기 인식을 바꾼다',
        option_3: '발전 방향을 찾다',
        option_4: '직업상 단점을 보완하다',
        option_5: '잠재능력을 끄집어 내다'
      },
      second: {
        title: '리포트 소개',
        first_para: `<strong>《천부적 재능 리포트》</strong>는 <span>HiSeer</span>역학(易学)연구개발팀과 상청·벽해명리연수원이 공동으로 개발하는 사주 팔자, 개인의 재능, 사고 방식과 행동 특성에 기반한 심층 분석되는 리포트입니다.`,
        second_para:
          'HiSeer 명리학연수팀은 전통역학을 더욱 정제하고 최적화하여 심리학, 철학, 빅데이터 및 현대 사회문화와 결합하고, 모든 사람이 가지고 있는 사주팔자에서 출발하여, 개인의 일생 및 단계적인 천부적 의식과 가치 성향을 깊이 분석하여 사용자에게 방향을 제시해 줍니다.'
      },
      example: '리포트 샘플',
      fifth: {
        title: '사용자 리뷰',
        comment_1:
          '호기심으로 봤는데 귀신같이 잘 맞췄더라고요 완전 신기핻ㄷㄷ 읽고 나서 자신감을 생겼어요 씨어 감사합니다!',
        date_1: '2022/12/16  16:05',
        comment_2:
          '내 천부적 이미지가 너무 귀여워용ㅎㅎ 어쩐지 친구들이 자꾸 나한테 너무 감성적이라고... 힌트에 따라 더 잘 될 수 있도록 노력할게요 아자아자~!',
        date_2: '2023/01/01 13:01',
        comment_3: '잘 봤어요. 나의 연애 상태 저 부분이 너무 정확해요ㅜㅜ',
        date_3: '2023/01/05  10:18',
        comment_4:
          '신기한 게 성격 부분에서 말한 것 처럼 우리 아이는 확실히 꼼꼼한 편이고요. 진로 지도에 따라 컴퓨터 전공을 선택했는데 합격했으면 좋겠습니다.',
        date_4: '2022/06/25  14:30'
      }
    },
    our_team: {
      title: 'HiSeer팀',
      tip_1: '역학 연구 40여 년',
      tip_2: '역학 교육 12년',
      tip_3: '역학 매니아 3000명 이상 배양',
      tip_4: '오프라인 1v1상담 35만 건',
      tip_5: '백만 넘은 사주팔자 데이터베이스 만듦',
      hiseer: 'HiSeer독창 콘텐츠'
    },
    // 引流模块
    advertise: {
      slogan_item_1: '오늘의 운세',
      slogan_item_2: '사주 팔자 ',
      slogan_item_3: '손기운 운세',
      slogan_item_4: '주역점',
      slogan_item_5: '리포트',
      slogan_item_6: '[점]해결의 책',
      google_download: 'Google Play로 이동',
      ios_download: 'Apple로 이동',
      hiseer: 'HiSeer\n당신만의 운세 전문가'
    },
    // 底部按钮
    footer_btn: '리포트를 풀어가기'
  },
  generating: {
    title: '분석 중',

    birth: {
      step: [
        '사주팔자 분석중',
        '올인원 리포트 생성중',
        '인간관계 분석중',
        '진로지도 분석중',
        '연애지침서 생성중'
      ],
      complete: [
        '사주팔자 분석 완료',
        '올인원 리포트 생성 완료',
        '인간관계 분석 완료',
        '진로지도 분석 완료',
        '연애지침서 생성 완료'
      ]
    },
    person: {
      step: [
        '천부적재능 유형 매칭 중',
        '키워드 추출 중',
        '천부적재능 분석 중',
        '성격 특징 분석 중',
        '잠재력 끌어올리는 법 생성 중'
      ],
      complete: [
        '천부적재능 유형 매칭 완료',
        '키워드 추출 완료',
        '천부적재능 분석 완료',
        '성격 특징 분석 완료',
        '잠재력 끌어올리는 법 생성 완료'
      ]
    },
    destiny: {
      step: [
        '천부적재능 유형 매칭 중',
        '같은 유형의 유명인 검색 중',
        '가치관 해석 중',
        '사고방식 비교 중',
        '잠재력 끌어올리는 법 생성 중'
      ],
      complete: [
        '천부적재능 유형 매칭 완료',
        '같은 유형의 유명인 검색 완료',
        '가치관 해석 완료',
        '사고방식 비교 완료',
        '잠재력 끌어올리는 법 생성 완료'
      ]
    },
    love: {
      step: [
        '천부적재능 유형 매칭 중',
        '연애 성격 분석 중',
        '배우자 유형 매칭 중'
      ],
      complete: [
        '천부적재능 유형 매칭 완료',
        '연애 성격 분석 완료',
        '배우자 유형 매칭 완료'
      ]
    },
    relation: {
      step: '귀인운 분석 중',
      complete: '귀인운 분석 완료'
    },
    profession: {
      step: '직업 성격 분석 중',
      complete: '직업 성격 분석 완료'
    },
    yin0: '논리가 강한& 이상주의자',
    yin1: '자주적 사고를& 가진 진리 탐구가',
    bi0: '열정적인& 능력 담장자',
    bi1: '과감한 인생& 모험가',
    shang0: '상상력이 &풍부한 지다성',
    shang1: '아이디어가& 기발한 예술가',
    cai0: '실사구시적인& 가치 창조자',
    cai1: '부지런한& 사회공헌자',
    sha0: '장인정신을 가&진 완벽주의자',
    sha1: '자율적이고 엄밀&한 질서 수호자'
  },
  report: {
    share: '공유하기',
    save_card: '나만의 카드 만들기',
    tablist: {
      life: '사주해설',
      interpersonal: '인간관계',
      career: '진로지도',
      love: '연애지침서'
    },
    life: {
      unknow: '시간모름',
      talent_type: '의 천부적 재능은:',
      key_word_title: '성격 키워드',
      advantage: '장점',
      shortcomings: '단점',
      // 最强天赋
      most_best_talent: '천부적 재능',
      // 典型名家名人
      famous_person: '典型名家名人',
      famous_person_from: '출저: 나무위키,네이버 지식백과',
      // 价值观
      values: '가치관',
      values_decs_title: '',
      values_decs_title_rest:
        '의 <strong>일생의 가치관 구성</strong>은 어떻게 됩니까?',
      values_all_life: '일생의 가치관',
      values_now: '현단계의 가치관',
      // 性格分析
      personality: '성격 분석',
      highlight: '장점',
      short: '약점',
      // 思维特点
      thinking: '사고특성',
      thinking_all_life: '일생의 사고특성',
      thinking_now: '현단계의 사고특성',
      // 潜力提升建议
      potential: '잠재력을 끌어올리는 방법'
    },
    interpersonal: {
      // 六亲关联性排名
      interpersonal: '인간관계',
      relative: '육친의 관련성 순위',
      life_relative: '일생중에 육친의 관련성',
      now_relative: '현단계에 육친의 관련성',
      all_life: '일생중에 ',
      now_period: '현단계에 ',
      relative_extent: '육친의 관련성:',
      father: '父亲',
      husband: '丈夫/男友',
      child: '孩子',
      sis_bro_fir: '兄弟姐妹/朋友',
      mother: '母亲',
      wife: '妻子/女友',
      // father: '부(父)',
      // husband: '부(夫)/남친',
      // child: '자(子)',
      // sis_bro_fir: '형제자매/친구',
      // mother: '모(母)',
      // wife: '처(妻)/여친',
      year_period: '2013년~2023년',
      relative_content_txt:
        '여기서 관련성 순위는 일상생활에서 육친이 얼마나 영향을 미치는지, 그들에 대한 당신의 관심도, 그리고 지내는 과정에 얼마나 많은 시간과 정력을 쏟았는지가 구체적으로 나타나며, 상위권일수록 영향력이 크고, 관심도가 높으며, 시간이 많이 소비됩니다.',
      // 生命中的贵人
      elegant: '생명 중의 귀인',
      relative_elegant: '육친 중의 귀인',
      elegant_analysis: '인물 유형으로 말하자면 당신의 귀인은 다음과 같습니다:'
    },
    career: {
      career: '진로지도',
      // 事业观
      career_outlook: '사업관',
      nature: '직업 성격',
      //职业能力分布
      abilities: '职业能力分布',
      // 从业建议
      career_advice: '진로지도',
      low: '약합니다',
      high: '강합니다',
      normal: '평균합니다'
    },
    love: {
      // 爱情观
      love_outlook: '연애관',
      // 最佳交往对象
      best_lover: '배우자유형'
    },
    rate: {
      txt: '올인원 리포트 리포트에 만족하시나요?',
      completed: '리뷰',
      rate_please: '리포트에 만족하시나요?',
      advice: '피드백(선택)',
      score: '점',
      advice_placeholder:
        '피드백를 남겨주시면 더욱 나은 서비스로 보답하겠습니다.',
      toast: '리뷰 남겨주세요',
      success: '리뷰 감사합니다!'
    },
    save_tip:
      '현재 풀이결과를 저장되지 않아 잃어버릴 수 있으니 저장하시겠습니까?',
    save: {
      save_report: '저장하기 ',
      title: 'HiSeer앱으로 저장하기',
      save2seer: '전화번호 인증',
      save2email: '이메일 인증',
      seer_tips: '验证手机号成为HiSeer用户，直接保存报告至HiSeer app',
      email_tips: '이메일 주소',
      placeholder_code: '인증번호를 입력해주세요.',
      placeholder_email: '이메일 주소를 입력해주세요',
      placeholder_phone: '전화번호',
      send_code: '인증번호발송',
      send_again: '재요청',
      code_has_been_sent: '인증번호를 전송되였습니다',
      report2seer: 'HiSeer앱으로 저장하기',
      report2email: '保存報告至郵箱',
      phone_err: '정확한 전화번호를 입력하세요',
      email_err: '이메일 주소를 다시 확인해주세요.',
      code_err: '인증번호가 틀렸습니다.',
      send_success: '저장 성공',
      save_success:
        'HiSeer앱에서 방금 인증된 전화번호\n혹은 이메일로 로그인하면 리포트를\n볼 수 있습니다.',
      email_verify: '이메일 인증 ',
      email_code_success:
        '인증번호는 메일로 보내드렸으니 인증번호를 입력해주세요. ',
      email_modal_code_tip: '(못 받으시면 스팸메일을 확인하시기 바랍니다.)',
      email_tip:
        '회원님의 정보보호를 위해 이메일 인증 후 풀이결과를 자동으로 저장됩니다.',
      prompt: '알림',
      prompt_content:
        '이대로 나가면 구매하신 리포트를 다시 찾을 수 없으니, 리포트 저장하는 것을 권합니다. ',
      tip: 'HiSeer 앱에 접속할 수\n있게끔 입력한 계정를 잘 기억하십시오.',
      modal_success: [
        '1. HiSeer 앱을 열어 리포트를 저장할 때 사용하는 이메일 또는 전화번호로 로그인하면 저장된 리포트를 볼 수 있습니다.',
        '2. 오른쪽 상단 ☰ 버튼을 누러 풀이내역-주문 조회 페이지에서 주문번호, 또는 리포트 저장시 제공된 전화번호나 이메일을 입력하여 검색하면 저장된 리포트를 볼 수 있습니다.'
      ]
    },
    // 查看更多天赋报告
    whole_report: {
      title: '완전한 리포트',
      description:
        '각 회원님에 대해 4개의 방면, 총 12개 각도에서 심층적이고 전면적인 분석 및 예측을 진행합니다.'
    },
    filter: {
      unlock: '완전한 리포트 보기'
    }
  },
  personality: {
    desc_list: [
      '사주팔자',
      '인격 유형 매칭',
      '최고의 잠재능력 발굴',
      '성격과 의식 분석',
      '잠재력 끌어올리기'
    ],
    description_title: '숨겨진 재능을 발견하고 잠재력을 발굴하기',
    description:
      '"나는 그냥 별다른 특색 없는 평범한 사람이야"\n아니요! 당신은 단지 자신의 재능을 발견하지 못했을 뿐입니다!\n사람은 누구나 타고난 특질을 가지고 있지만, 타고난다는 것은 스스로 알아차리기 어렵다는 것을 의미하기 때문에 거대한 보물처럼 탐구하고 발굴해야 합니다.',
    match: {
      title: '20가지 인격 유형, 정확하게 매칭',
      type0: '모험가',
      desc0: '관대하고',
      desc_png0: '민첩하다',
      type1: '이상가',
      desc1: '인자하고',
      desc_png1: '담백하다',
      type2: '공헌자',
      desc2: '너그럽고',
      desc_png2: '선량하다',
      type3: '상상가',
      desc3: '재기발랄하고',
      desc_png3: '구애받지않다'
    },
    keyword: {
      title: '키워드로 장단점을 완벽하게 해석',
      merit: '장점',
      merits: [
        '지혜로움',
        '책임감',
        '조리있음',
        '성실함',
        '계획성',
        '약속잘지킴'
      ],
      defect: '단점',
      defects: [
        '정통주의',
        '고집',
        '우유부단',
        '과하게 분석함',
        '결정 장애',
        '보수파'
      ]
    },
    talent: {
      title: '잠재력 다각도 분석',
      list: ['자율성', '창의성', '기획력', '실천력', '협업능력']
    },
    nature: {
      title: '성격 장단점 심도 분석',
      list: [
        '전략적 사고',
        '의지력이 강하다',
        '풍부한 학식과 이해력',
        '예리하지 않다',
        '오만하다',
        '둔하고 자기중심적이다'
      ]
    },
    advice: {
      title: '잠재력 끌어올리는 법',
      list: [
        '자신의 정서를 지켜봐 주기',
        '타인의 가치를 중시하기',
        '미리 고민하기'
      ]
    },
    person_report:
      '이 리포트는 명리학 알고리즘을 기반으로 하고, 이름, 출생 시간, 성별 등의 정보를 분석하고, 현실적인 상황과 결합하여, 사용자의 재능, 성격, 장단점을 분석하고 개인 특성에 맞는 잠재력 개발 가이드를 제공합니다.',
    more: {
      title: '다른 리포트',
      click: '클릭하여 리포트 자세히 보기'
    },
    comment_list: [
      {
        time: '2023/03/25',
        text: '대체로 정확한것 같아서 좋네요. MBTI보다 더 상세하게 말해줘서 큰 도움됩니다.'
      },
      {
        time: '2023/03/31',
        text: '나도 모르는 내 자신의 어떤 숨겨진 재능을 알게 되어서 보고 자신감이 더 생긴 것 같애..'
      },
      {
        time: '2023/01/08',
        text: '다양하게 설명되어 있고 정말 큰 도움됩니다.'
      }
    ],
    fixed_btn: '바로 알아보기'
  },
  // 命运指南
  destiny_report: {
    desc_list: [
      '유명인 매칭',
      '인격 포지션',
      '의식 해석',
      '사고 분석',
      '잠재력 향상'
    ],
    description_title: '당신도 유명인처럼 성공할 수 있습니다',
    description:
      '어떤 유명인이 당신과 같은 사주격국에 속합니까?\n누가 당신과 비슷한 재능/성격 특성을 가지고 있습니까?\nHiSeer는 당신을 위해 당신과 같은 사주격국의 유명인을 정확하게 매칭하고, 당신의 발전 방향과 잠재된 천부적재능을 더 잘 알아볼 수 있도록 돕습니다.\n당신들의 공통점을 알게된다면, 그들은 당신의 미래일지도...',
    celebrity: {
      title: '비슷한 천부적 재능과 운명을 가진 유명인과 매칭합니다.',
      desc: '사주가 비슷한 사람을 찾고,\n자신의 잠재력을 발굴합니다.'
    },
    value: {
      title: '전면적으로 가치관 해석',
      list: ['이상주의', '완벽주의', '단체정신', '실용정신', '자유주의']
    },
    thinking: {
      title: '사고방식특징을 가로세로로 비교',
      radio: '적당',
      list: ['논리적 사고', '주관능동성', '신독자율', '아이디어', '실용정신']
    },
    comment_list: [
      {
        time: '2023/03/17',
        text: '나랑 가장 비슷한 유명인이 리사라니! 웬일이야!'
      },
      {
        time: '2023/02/03',
        text: '잠재력 끌어올리는 법, 저 부분이 진짜 유용한것 같아요.'
      },
      {
        time: '2023/01/05',
        text: '성격 분석은 꽤뚫어보는것처럼 정확합니다. 아..나도 저 유명인들처럼 성공하면 좋을텐데..'
      }
    ],
    report_des:
      '이 리포트는 명리학 알고리즘을 기반으로 하고, 이름, 출생 시간, 성별 등의 정보를 분석하고, 현실적인 상황과 결합하여, 사용자의 성격, 사고방식 및 의식을 수천 명의 유명인과 비교하고 가장 유사한 유명인과 매칭하고 개인 특성에 대한 잠재력 향상 지침을 제공합니다.'
  },
  // 恋爱指南
  love_report: {
    head_list: ['인격 유형 매칭', '당신의 연애관', '아내운', '남편운 '],
    description_title: '행복하고 조화로운 사랑으로 인도합니다',
    description:
      '연애는 박빙의 게임입니다. 양쪽 모두 독립적인 성격과 정신 세계를 가지고 있으며, 서로에게 완전히 의존할 수도 없고, 상대방이 자신을 완전히 차지하게 할 수도 없습니다.\n이제부터 자신의 인격 인식을 강화하고 자신의 연애관을 알아보며 배우자와 건강하고 조화로운 연애 관계를 만들고 함께 발전하고 상부상조합시다.',
    analysis: {
      title: '연애 성격 분석',
      list: [
        '<span>당신의 연애 감각을 객관적으로 분석하여</span>, 당신과 배우자 사이의 깊은 감정을 더욱 명확하게 이해할 수 있도록 돕습니다.',
        '<span>당신의 연애 성격을 깊이 해석하면</span>, 당신이 연애 중의 긍정적인 힘을 효과적으로 파악하여 연애운을 끌어올리는 데 도움이 됩니다.'
      ]
    },
    match: {
      title: '당신과 궁합이 맞는 배우자 유형',
      radio: '궁합',
      list: [
        '과감한\n인생 모험가',
        '논리가 강한\n이상주의자',
        '자율적이고 엄밀한\n질서 수호자',
        '장인정신을\n가진 완벽주의자'
      ]
    },
    comment_list: [
      {
        time: '2023/03/29',
        text: '나랑 궁합이 맞는 배우자 유형은 논리성이 강한 사람이라고... 신기하게 주변에 딱 이런 사람이 있는데...'
      },
      {
        time: '2023/03/01',
        text: '연애관 분석이 기가 막히게 딱 맞췄네요. 빨리 연애했으면 좋겠어요.'
      },
      {
        time: '2023/02/11',
        text: '사귀었던 전 남친들은 리포트 설명에 잘 맞네요. 언제 좋은 사람을 만날까...?'
      }
    ],
    report_des:
      '이 리포트는 명리학 알고리즘을 기반으로 하고, 이름, 출생 시간, 성별 등의 정보를 분석하고, 현실적인 상황과 결합하여, 사용자의 연애관 및 연애 성격을 분석하고 실행 가능한 제안을 제공하고 좋은 인연을 찾는 데 도움이 됩니다.'
  },
  // 人际指南
  relation: {
    header_list: [
      '육친관계 친밀도 판단하기',
      '예리한 안목으로 귀인을 알아보기'
    ],
    description_title: '귀인을 식별하는 안목을 훈련시켜 드리겠습니다.',
    description:
      "귀인은 얼마나 '귀한가'가 아니라, 얼마나 '가까운가'에 있습니다.\n육친 중 귀인부터 귀인이 먼저 다가오도록 하겠습니다!\n매일 귀인을 만나고 모든 일에 귀인의 도움이 되도록 하세요.",
    relatives: {
      title: '육친관계 분석',
      list: [
        '아들',
        '자매',
        '형제',
        '부(父)',
        '모(母)',
        '처(妻)',
        '부(夫)',
        '딸'
      ]
    },
    analysis: {
      title: '귀인운',
      content:
        '육친 중에 누가 당신의 귀인입니까?\n사회에서 당신의 귀인은 누구입니까?\n귀인을 만나는 방법을 알아야 성공에 한 발짝 더 가까워질 수 있습니다.'
    },
    comment_list: [
      {
        time: '2023/03/10',
        text: '귀인운 설명 재밌군요ㅎㅎ 말하는 대로 엄마와 사이가 제일 좋습니다.'
      },
      {
        time: '2023/02/22',
        text: '난 친구 사귀는 것을 좋아하는 이유가 그들은 나의 귀인이기 때문이구나ㅋㅋ'
      },
      {
        time: '2023/02/05',
        text: '리포트에 논리성이 강한 사람과 많이 교류하는 것을 권장하는데, 확실히, 저 자신은 논리성이 떨어지는 편입니다.'
      }
    ],
    report_desc:
      '이 리포트는 명리학 알고리즘을 기반으로 하고, 이름, 출생 시간, 성별 등의 정보를 분석하고, 현실적인 상황과 결합하여, 사용자의 가족 육친 관계를 분석하고 개인 특성에 대한 귀인 교제 지침을 제공하여 귀인을 더 많이 사귀고 승진할 수 있도록 돕습니다.'
  },
  // 职场指南
  profession: {
    list: ['직업 성격 분석', '직업 능력 평가', '진로선택 가이드'],
    description_title: '단번에 미래를 밝혀드립니다',
    description: `자신에게 맞는 것이 가장 좋고, 노력보다 직업을 선택하는 것이 더 중요합니다.\n'직업 가이드'는 자신의 성격을 보다 포괄적으로 이해하고, 자신의 장점과 단점을 명확히 하고, 자신의 특성에 가장 적합한 직업을 찾고, 커리어에 대한 장기적인 계획을 실현하는 데 도움이 됩니다.`,
    profession_analysis: {
      title: '직업 성격 분석',
      list: ['자신의 직업 성격을 인식하고,', '당신의 직장 운명을 바꾸세요!']
    },
    ability: {
      title: '직업 능력치 분포도',
      left_list: ['전략적배치', '리더십', '시장수요', '원가관리', '연구개발'],
      right_list: ['기술통합', '사교성', '창의력', '수익확대', '기술통합']
    },
    advice: {
      title: '직업 유형 제공',
      list: [
        '정보유통',
        '작가/기자',
        '기업상업',
        '고문/판매업자',
        '교육/상담',
        '대학교수/통역',
        '테크놀로지/인터넷',
        '소프트웨어개발자\n웹마스터',
        '정부/서비스',
        '소방관\n경찰',
        '전문성 직업',
        '변호사\n판사'
      ]
    },
    comment_list: [
      {
        time: '2023/03/19',
        text: '저보다 저 자신을 더 잘 아는 이 리포트에 놀랐습니다.  '
      },
      {
        time: '2023/03/22',
        text: '예비졸업생로써 자신이 어떤 직업에 적합할지 궁금했는데, 리포트에 가장 가치 있는 부분은 직업 능력 분포이고 아주 전문적이라고 생각합니다.'
      },
      {
        time: '2023/02/15',
        text: '취업 준비생은 이 리포트를 매우 시의적절하게 보았습니다.'
      }
    ],
    profession_description:
      '이 리포트는 명리학 알고리즘을 기반으로 하고, 이름, 출생 시간, 성별 등의 정보를 분석하고, 현실적인 상황과 결합하여, 사용자의 직업 성격, 업무 능력을 분석하고 적절한 직업 조언을 제공해 드립니다.'
  },
  // 合盘报告
  match: {
    select_type: '궁합 유형 선택',
    homo: '동성애정궁합',
    hetero: '남녀애정궁합',
    form_desc: '관계의 본질을 밝히고, 애정의 흐름을 예측하기',
    your_info: '당신의 정보',
    his_info: '상대방의 정보',
    match_now: '바로 알아보기',
    relative: '두 사람의 관계: ',
    select_relative: '그 사람과의 관계는?',
    relatives: [
      { label: '썸남썸녀', value: 1 },
      { label: '연인', value: 2 },
      { label: '부부', value: 3 }
    ],
    puzzle: {
      title: "'사랑'에 대해 이런 고민이 있나요?",
      help: '애정궁합풀이는',
      puzzle: [
        '그 사람은 나를 진심으로 사랑할까?',
        '우리 관계에 어떤 위기가 찾아왔을까?',
        '우리 앞으로 어떻게 될까?',
        '우리의 자식운은 어떨까?',
        '우리 인연은 어디까지일까?',
        '그 사람과 결혼하면 행복할까요?'
      ],
      homo_puzzle: [
        '우리 인연은 어디까지일까?',
        ' 내 미래 배우자, 그 사람 맞을까?',
        '앞으로 우리는 어떻게 될까?',
        '그 사람 바람기 있을까?',
        '그 사람은 나를 진심으로 사랑할까?',
        '속궁합은 어떨까?'
      ],
      list: [
        '당신이 자신과 상대방의 성격과 애정 관념을 이해하는 데 도움을 줄 수 있으며, 동시에 두 사람의 궁합과 상호보완성을 명확하게 알고, 당신 자신과 상대방을 더 잘 인식하면서 서로에 대한 이해와 신뢰를 증진할 수 있습니다.',
        '<span>싱글</span>이든,<span>진정한 사랑</span>을 찾고 싶든 <span>기존 애정 관계</span>를 굳건히 하고 싶든, 애정궁합풀이는 당신의 심리적 욕구와 애정 성향을 더 잘 이해하도록 도울 수 있습니다. 애정운 흐름을 더 잘 파악하고 자기 행복을 더 자신 있게 추구할 수 있습니다.'
      ]
    },
    suitable: {
      title: '누가 보면 좋을까요',
      desc: '애정궁합풀이는 3가지 관계의 흐름을 예측할 수 있습니다',
      ambiguous:
        '<span>썸타는 사람</span>과의 궁합\n두 사람의 <span>관계 흐름</span>\n둘이 사귈 수 있을지',
      hetero:
        '<span>사랑하는 연인</span>과의 궁합\n두 사람의 결혼운\n둘이 결혼하게 될까?',
      homo: '<span>사랑하는 연인</span>과의 궁합\n두 사람의 <span>관계 흐름</span>\n연애에 위기가 찾아왔을지',
      marriage:
        '<span>결혼한 배우자</span>와의 궁합\n두 사람의 <span>결혼생활과 자식운</span>\n어떤 위기가 찾아왔을까?',
      marriage_homo:
        '<span>오래 함께 하는 사람</span>과의 궁합\n두 사람의 미래 운세\n서로의 재물, 도화가 있을지'
    },
    report: {
      tabs: ['연인 관계', '썸 관계', '부부 관계'],
      title: '관계에 따라 적용된 궁합풀이',
      desc: '3가지 차원에서 {num}개 고민했던 것들을 풀어드립니다.',
      options: ['감정적', '재물적', '미래적'],
      options_marriage: ['서로의 마음', '가족관계', '혼인 발전'],
      options_ambiguous: ['서로의 마음', '관계 발전', '솔로탈출 방법']
    },
    emotion: {
      title: '감정적',
      list: [
        '두 사람의 성격궁합',
        '두 사람의 연애관 궁합',
        '배우자의 중요성',
        '두 사람의 속궁합'
      ],
      list_ambiguous: [
        '두 사람의 성격궁합',
        '두 사람의 연애관 궁합',
        '상대방의 이상형'
      ],
      list_marriage: [
        '두 사람의 성격궁합',
        '두 사람의 결혼관 궁합',
        '상대방의 이상형',
        '배우자의 중요성',
        '두 사람의 속궁합'
      ]
    },
    money: {
      title: '재물적',
      list: ['두 사람의 사업관', '재물운 궁합'],
      list_marriage: [
        '우리는 서로에게 무엇을 가져다 줄 수 있을까?',
        '재물운 궁합',
        '자식운 궁합'
      ],
      list_ambiguous: [
        '상대방의 마음을 사로잡은 방법',
        '관계를 발전시키는 시기 및 방법',
        '우리가 연인이 된다면?',
        '누가 바람기가 더 많을까',
        '두 사람의 속궁합',
        '연애하게 된다면 생길 도전'
      ]
    },
    future: {
      title: '미래적',
      list: [
        '자식운 궁합',
        '앞으로 1년 간 두 사람의 연애운',
        '연애에 잠재된 도전',
        '누가 더 바람기가 많을까?',
        '결혼 확률',
        '연애운을 높이는 방법'
      ],
      list_marriage: [
        '혼인에서 잠재된 도전',
        '누가 더 바람기가 많을까?',
        '앞으로 1년 간 두 사람의 감정운',
        '결혼의 질을 높이는 방법'
      ],
      list_ambiguous: ['앞으로 3개월 동안 연애할 가능성', '연애운 올리는 법']
    },
    example: {
      title: '애정궁합풀이(type) 샘플',
      according: '애정궁합풀이 원리:',
      accordings:
        '애정궁합풀이는 두 사람의 생년월일시를 기준으로 먼저 각자의 사주, 성격, 운세를 분석한 다음 두 사람의 용신과 대운을 결합하여 현재의 감정 상태와 미래 관계의 흐름을 예측합니다.'
    },
    feedback: {
      title: '사용자 후기:',
      feed_list: [
        {
          homo_name: '**수 2023-9-01',
          hetero_name: '**수 2023-9-01',
          homo: '그 사람을 되돌릴까 말까 고민했었는데…. 궁합 풀이를 보니 답이 나왔어ㅜㅜ 역시 나랑 안 맞은 사람이라 되돌릴 필요 없겠군…',
          hetero:
            '그 사람을 되돌릴까 말까 고민했었는데…. 궁합 풀이를 보니 답이 나왔어ㅜㅜ 역시 나랑 안 맞은 사람이라 되돌릴 필요 없겠군…'
        },
        {
          hetero_name: '**현   2023-8-18',
          homo_name: '**미 2023-7-17',
          hetero:
            '원래 썸 타는 사이였는데 궁합풀이를 보니 인연이 아주 깊다고 해서 바로 고백했지! 현재 결혼준비중ㅎㅎ',
          homo: '너무 정확하게 맞아서 소름...특히 성격 궁합 저 부분'
        },
        {
          hetero_name: '**명   2023-7-29',
          homo_name: '**철 2023-8-28',
          hetero:
            '너무 정확하게 맞아서 소름...남편이 외국으로 파견되었서 지금 떨어져 살고 있는데, 이건 완전 맞췄어!!',
          homo: '그 사람은 확실히 바람기가 많은 편이야...잘 맞췄어'
        }
      ]
    },
    illustrate: {
      title: '주의 사항:',
      homo: [
        '1.이름, 성별 및 생일을 포함한 두 사람의 완전한 정보를 입력해야 합니다.',
        '2.동성애정궁합풀이는 쌍방의 성별은 반드시 동성이어야 합니다.',
        '3.궁합풀이는 구매 후 30초 이내에 풀이 결과가 생성되며 궁합풀이 - 구매내역에서 또는 내정보 - 리포트 및 점복에서도 확인할 수 있습니다.'
      ],
      hetero: [
        '1.이름, 성별 및 생일을 포함한 두 사람의 완전한 정보를 입력해야 합니다.',
        '2.남녀애정궁합풀이는 쌍방의 성별은 반드시 이성이어야 합니다.',
        '3.궁합풀이는 구매 후 30초 이내에 풀이 결과가 생성되며 궁합풀이 - 구매내역에서 또는 내정보 - 리포트 및 점복에서도 확인할 수 있습니다.'
      ]
    },
    loading: {
      title: '애정 궁합 분석 중',
      list: [
        '성격 궁합 분석 중',
        '배우자 중요성의 대비',
        '재물운 흐름',
        '연애 위기 및 대처법'
      ],
      list_marriage: [
        '두 사람의 결혼관 궁합',
        '배우자 중요성의 대비',
        '재물운 흐름',
        '미래 두 사람의 혼인 흐름'
      ],
      list_ambiguous: [
        '성격 궁합 분석 중',
        '미래 두 사람의 감정운',
        '누가 더 바람기가 많을까?',
        '연애운 올리는 법'
      ]
    },
    love: {
      emotion: '감정적',
      match_emotion: '두 사람의 성격궁합',
      similarity: '두 사람의 닮은 정도:',
      complementary: '두 사람의 상보성:',
      outlook_match: '두 사람의 연애관 궁합',
      love_match: '두 사람의 연애관 궁합: ',
      impression: '상대방의 마음을 사로잡은 방법',
      relative: '관계를 발전시키는 시기 및 방법',
      enhance_advise: '연애운을 높이는 방법',
      together: '우리가 연인이 된다면?',
      playful: '누가 바람기가 더 많을까',
      possible: '앞으로 3개월 동안 연애할 가능성',
      luck: '연애운 올리는 법',
      challenge: '연애하게 된다면 생길 도전',
      marriage_match: '두 사람의 결혼관 궁합',
      marriage_promote: '우리는 서로에게 무엇을 가져다 줄 수 있을까?',
      ideal_partner: '상대방의 이상형',
      fortunate_match: '재물운 궁합',
      child_match: '자식운 궁합',
      marriage_challenge: '혼인에서 잠재된 도전',
      marriage_cheat: '누가 더 바람기가 많을까?',
      marriage_enhance: '결혼의 질을 높이는 방법',
      mate_importance: '배우자의 중요성',
      attitude: '두 사람의 금전관',
      now_sort: '현단계에서',
      life_sort: '일생에서',
      sort_list: ['이상', '사교/친구', '아이', '사업/재물', '배우자'],
      sex_match: '두 사람의 속궁합',
      sex_perform: '두 사람의 섹스 취향',
      sex_desire: '두 사람의 성욕 지수',
      sex_rate: '섹스 매치'
    },
    similaritys: ['당신들의 성격은 비슷한 건가? ', '아니면 상호 보완한 건가?'],
    importances: [
      '당신에게 무엇이 가장 중요한가?',
      ' 그 사람에게 배우자의 중요성은?'
    ],
    sexs: ['당신들의 속궁합은 잘 맞을까?', '각자의 섹스 취향은?'],
    wealthys: [
      '당신들의 재물 원천은 \n어디에서 오나요?',
      '둘의 미래 재물운 흐름은?'
    ],
    childs: [
      '당신들은 이번 생에 몇 \n명의 자식이 있을까?',
      '자녀들과의 관계는 어떨까?'
    ],
    chances: ['당신들의 감정과 관계는 어떨까?', '앞으로 3년 내 결혼할 확률은?'],
    love_match: [
      '두 사람의 애정 관념은 무엇인가?',
      '사랑을 어떤 방식으로 표현하는가?'
    ],
    impression: [
      '상대방의 취향은 어떨까?',
      '어떻게 그 사람의 마음을 잡을 수 있을까?'
    ],
    love_relative: [
      '앞으로 1년 우리의 관계 어떻게 될까?',
      '어떻게 하면 우리의 감정을 뜨겁게 할 수 있을까?'
    ],
    playful: ['상대방의 바람기가 많은 편인가?', '그 사람의 속마음은?'],
    possible: [
      '앞으로 3개월 동안 연애할 확률은?',
      '그 사람 말고 다른 인연이 있을까?'
    ],
    love_luck: ['나의 연애운은 어떨까?', '연애운 올리는 법은?'],
    marriage_match: [
      '쌍방의 결혼에 대한 태도는 어떨까?',
      '결혼은 그 사람에게 중요할까?'
    ],
    marriage_promote: [
      '생활이나 사업에 있어서,',
      '서로를 돕고 함께 발전할 수 있을까?'
    ],
    fortunate_match: [
      '재물 원천은 어디에서 오나요?',
      '둘의 미래 재물운 흐름은?'
    ],
    marriage_challenge: [
      '어떤 요인이 결혼\n 안정에 영향을 미칠까?',
      '어떤 문제에 직면하여\n 각별한 주의가 필요할까?'
    ],
    marriage_advise: [
      '어떻게 결혼 행복 지수를 높일까?',
      '어떤 방법이 감정을 촉진할 수 있을까?'
    ],
    fortunate: '재물적',
    both_fort: '두 사람의 사업관',
    wealthy: '재물운 궁합',
    both_weal: '당신들의 부는 어디서 오는가',
    future_develop: '미래적',
    weal_compare: '미래의 재물운 흐름:',
    weal_fortunate: '재물운',
    emo_fortunate: '연애운',
    score: '점',
    score_unit: '점수',
    child: {
      match: '자식운 궁합',
      fate: '아이와의 인연',
      importance: '아이의 중요성',
      effect: '아이에게 주는 영향'
    },
    challenge: '연애에 잠재된 도전',
    chance: '결혼 확률',
    third: '누가 더 바람기가 많을까?',
    marriage: '3년 내 결혼할 가능성:',
    emotion_fort: '앞으로 1년 간 두 사람의 연애운',
    emotion_chart: '연애운 흐름',
    marriage_fort: '혼인운',
    marriage_chart: '혼인운 흐름',
    advice: '연애운을 높이는 방법',
    advice_hiseer: '애정궁합풀이에 만족하시나요?',
    recommend: '추천',
    more: '더 보기'
  },
  // 年运报告
  annual: {
    title: '프리미엄 신년운세',
    year: '연도 선택',
    place_year: '예측할 연도를 선택하십시오.',
    predict: '바로 알아보기',
    activity: '2024년 2월 24일 전 주문하면\n행운 부적과 같이 보내드립니다.',
    example: '신년운세 샘플',
    paid: '<span>num</span>명이 이 상품을 구매하였습니다\n<span>98.9%</span>의 이용자들은 만족합니다.',
    alter: '변경',
    schedule: {
      steps: [
        '주소입력 완료',
        { text: '부적 개광 의식', success: '부적 개광 의식 완료' },
        { text: '부적 배송', success: '부적 배송됨' }
      ],
      upload: '개광 의식 치른 후 영상 업로드 예정',
      play: '개광 동영상 보기',
      express: '운송장 번호:',
      contact_us: '문제나 피드백이 있으시면\n아래 연락처로 연락주세요.',
      tips: '이메일 주소(email)로\n HiSeer에 로그인하면 신년운세, \n부적 배송 진도를 확인할 수 있습니다.',
      email_tip:
        'HiSeer에 로그인하면 신년운세, \n부적 배송 진도를 확인할 수 있습니다.'
    },
    master: {
      title: '프리미엄 신년운세',
      text: '역학 전문가가 직접 사주를 보고 당신만의 신년운세를 제공해 줍니다.',
      overview: '올해 총운',
      overview_list:
        '올해에는 어떤 운세가 찾아올까?\n어떤 면에서 기회가 있을까요?\n어떤 면에서 좌절을 겪을 수 있을까?',
      analysis: '주제별 운세',
      analysis_list: [
        {
          title: '연애운',
          content: '언제 연애운이 가장 좋을까?\n연애의 위기가 찾아왔을까?'
        },
        {
          title: '직장운',
          content: '언제 직장운이 가장 좋을까?\n승진할 기회가 있을까?'
        },
        {
          title: '재물운',
          content: '언제 재물운이 가장 좋을까?\n나의 부는 어디서 오는가?'
        },
        {
          title: '친구운',
          content: '올해 친구운이 어떨까?\n어떤 사람을 멀리야 하나?'
        },
        {
          title: '공부운',
          content: '올해 공부운이 어떨까?\n성적을 올릴 수 있을까?'
        },
        {
          title: '건강운',
          content: '올해 건강운이 어떨까?\n어떤 것을 주의해야 할까?'
        }
      ],
      guide: '운세 올리는 법',
      guide_bold: '행운의 방향, 색깔, 숫자 등을 포함한 전면적인 행운 Tip',
      guide_list:
        '어느 방향이 나의 발전에 유리할까?\n어떤 색이 올해 희용의 색상일까?\n행운을 가져다줄 수 있는 일이 뭘까?',
      service: '정성을 담아 기대에 부응하겠습니다',
      service_content: `우리의 모든 신년운세 풀이는 사주 전문가가 정성을 다하여 분석한 것입니다.
      매일 수백 개의 신년운세 주문을 받아 하나하나 신경이 많이 써야 하고, 분석하고 작성까지 충분한 시간이 필요하다는 것을 이해해 주시기를 바랍니다.
      주문 후 차례대로 진행하도록 하겠습니다. 일반적으로 24시간 이내에 신년운세 풀이가 완성되니 기다려 주세요.`
    },
    charm: {
      title: '행운 부적',
      desc: '손으로 쓴 6종 부적은 개광(開光) 의식을 치른 부적들이오니 행운을 가져다주기를 바랍니다.',
      list: [
        {
          name: '재물부적',
          content:
            '재원을 넓히고 재앙을 없애는 데 도움이 될 수 있습니다. 재물운이 왕성하면 불길을 붙여 줄 것이고, 재물운이 순조롭지 않으면 눈 속에 탄을 보내는 역할을 합니다. 재신을 불러 재물운이 열어주고, 기회를 잡아주며, 뜻밖의 편재를 얻게 도와주는 부적입니다.'
        },
        {
          name: '사업직장',
          content:
            '사업적인 행운과 기회를 가져다줄 수 있고, 잠재력을 자극하며, 귀인의 도움을 받을 수 있게 하고, 사업 및 직장에서 더욱 쉽게 성공할 수 있게 도와주는 부적입니다. 이 부적은 긍정적인 에너지를 불러오고, 일을 더 순조롭게 만들 수 있습니다.'
        },
        {
          name: '사랑부적',
          content:
            '연애운을 올리고 이성의 사랑과 관심을 얻을 수 있게 도와주는 부적입니다. 솔로인 경우 마음에 드는 상대를 빨리 만나 좋은 인연을 맺을 수 있도록 돕고, 이미 연애하는 경우 관계를 더욱 안정시키고, 행복하게 만들 수 있습니다.'
        },
        {
          name: '공부부적',
          content:
            '공부, 시험, 진학 등을 순조롭게 하기를 기원하고, 공부운과 지혜를 높이는 부적입니다. 이 부적을 사용하면 학습 의욕과 긍정적인 마음가짐을 높이고 집중력과 기억력을 향상할 수 있습니다.'
        },
        {
          name: '평안부적',
          content:
            '몸과 마음을 건강하게 기원하고, 출입을 평안하게 하며, 소인을 막아주는 부적입니다. 자주 외출하는 사람 등 모든 사람이 사용할 수 있으며, 수호신 같은 존재로 자신이나 가족에게 건강이나 평안을 기원할 수 있습니다.'
        },
        {
          name: '인간관계',
          content:
            '사교계를 넓히고 새로운 인맥을 쉽게 맺을 수 있도록 도와주는 부적입니다. 당신이 남의 호감과 신뢰를 갖게 하고, 인기를 높이며, 더 많은 기회와 도움을 누릴 수 있도록 돕습니다.'
        }
      ],
      tip: '여섯 가지 부적 중 하나를 선택할 수 있습니다.',
      writing:
        '모든 부적은 도관(道观) 스님이 친필로 작성하고 개광(開光) 의식을 치른 부적입니다.',
      lighting: '회원님에게 보내는 부적마다 개광 의식을 치를 것입니다.',
      video: '부적 작성 동영상',
      light: '부적 개광 의식 동영상',
      deliver: '부적 제작 완료 후 제공해 주신 주소로 발송하겠습니다.',
      color: '색상랜덤배송',
      gift: '기프트',
      gift_desc:
        '몸에 항상 지니고 다니는 지갑 속이나 차, 거실에 넣어두면 좋은 기운이 팍팍!',
      gift_title: '[행운 부적] 반짝세일: ',
      gift_content:
        '오늘부터 정월대보름(2024년 2월 24일) 전까지 신년운세를 구매하면 행운 부적은 덤!'
    },
    comment: {
      title: '사용자 후기',
      list: [
        {
          name: '**정  2023.10.3',
          content:
            '아주 상세하고 여러 가지 운세도 잘 설명해 줬습니다. 가장 궁금했던 연애운 부분에 흐름도도 있군요. 내년 5월 6월 연애운이 가장 좋대 잘 잡아야 하지~!'
        },
        {
          name: '**지  2023.9.16',
          content: '정확하고 자세하게 설명해주셔서 감사드립니다.'
        },
        {
          name: '**골드 2024.2.16',
          content: '스승님의 새해 운세를 보고 새해에 새로운 희망을 갖게 되었습니다. 감사합니다.'
        }
        // {
        //   name: '**현  2023.11.1',
        //   content: '행운 부적을 잘 받았습니다! 나에게 행운을 가져다주길….'
        // }
      ]
    },
    illustrate: {
      title: '주의 사항',
      list: [
        '1.프리미엄 신년운세는 이름, 성별, 생일 및 예측 연도를 정확하게 입력해야 합니다.',
        '2.신년운세는 사주 전문가팀이 실시간으로 분석 및 작성하여 일반적으로 정보 제출 후 24시간 이내에 신년운세를 완성하므로 인내심을 가지고 기다려 주십시오.',
        // '3.행운 부적을 순조롭게 발송하기 위해서 당신의 실명, 주소, 핸드폰 번호, 이메일 등의 정보를 입력해야 합니다.',
        '3. 구매하신 신년운세는 다음과 같은 방법으로 볼 수 있습니다.'
      ],
      tip_list: [
        '제공해 주신 메일로 보내드릴 예정이니 메일함에서 확인할 수 있습니다.',
        'HiSeer APP를 다운받아 로그인 후  [신년운세] - [구매내역]에서 확인할 수 있습니다.',
        '본 페이지의 [풀이내역]에서 이메일 또는 주문 번호를 검색하여 확인할 수 있습니다.'
      ],
      contact: '문제나 피드백이 있으시면 아래 연락처로 연락주세요.'
    },
    payment: {
      title: '결제내역',
      prod: 'x년 맞춤형 신년운세',
      name: '분석 대상',
      pay: '결제금액',
      type: '해제 방식:',
      tip: '전문가가 24시간 내 풀이 완료 후 이메일로 보내드리겠습니다.',
      email: '이메일',
      button: '이메일 주소 인증',
      e_title: '이메일 주소 인증',
      e_sent: '인증 메일은',
      e_toemail: '으로 발송되었으니 확인하시기를 바랍니다.',
      e_tip: '(못 받으시면 스팸메일을 확인하시기를 바랍니다.)',
      e_confirm: '인증완료',
      e_warning: '먼저 이메일 인증하세요',
      success: '인증완료',
      verify_again: '재시도',
      try_again: '인증 요청이 없습니다. 다시 확인해 주세요.'
    },
    info: {
      submit: '제출하였습니다',
      year: '연도',
      submit_time: '제출 시간 ',
      tips_1: '',
      tips: '전문가가 &까지 당신의 신년운세 풀이를 완성하여 email으로 보내 드릴 테니 인내심을 가지고 기다려 주십시오.',
      tips_out: '전문가가 신년운세를 작성하고 있으니 기다려 주십시오...',
      tips_gray: `구매하신 신년운세는 다음과 같은 방법으로 볼 수 있습니다.
      제공해 주신 메일로 보내드릴 예정이니 메일함에서 확인할 수 있습니다.
      본 페이지의 [풀이내역]에서 이메일 또는 주문 번호를 검색하여 확인할 수 있습니다.`,
      e_tips_1: '이메일 주소',
      e_tips_2: '(email)로 HiSeer에 로그인하면 신년운세,',
      e_tips_3: ' 확인할 수 있습니다.',
      confirm: '행운 부적을 받으시겠습니까?',
      confirm_text:
        '부적은 프리미엄 신년운세 서비스의 독점 맞춤 사은품입니다. 순조롭게 받을 수 있도록 해당 배송정보를 입력하십시오.',
      cancel: '포기하기',
      fillin: '부적 수령하기',
      cancel_warning:
        '지금 포기하면 다시 받을 수 없으니 행운 부적을 포기하시겠습니까?',
      giveup: '부적 수령을 포기했습니다.',
      deliver: '배송비 무료입니다.',
      deliver_tip:
        '행운 부적을 잘 받기 위하여 아래의 정보를 진실하게 입력해 주시기를 바랍니다. 만약 정보 오류로 인해 부적을 받지 못한 경우, 스스로 이 손실을 부담합니다.',
      deliver_tip_1:
        '발송 후 이메일로 운송장 번호를 알려드리니, 앱에서 배송 진도를 확인할 수 있습니다.',
      select: '원하는 부적을 고르세요',
      options: [
        '재물부적',
        '사업직장',
        '사랑부적',
        '공부부적',
        '평안부적',
        '인간관계'
      ],
      deliver_form: [
        { type: 'name', label: '이름', placeholder: '이름을 입력해주세요' },
        { type: 'phone', label: '전화번호', placeholder: '전화번호' },
        { type: 'address', label: '주소', placeholder: '주소를 입력해주세요' },
        {
          type: 'email',
          label: '이메일',
          placeholder: '이메일 주소를 입력해주세요'
        },
        { type: 'postCode', label: '우편', placeholder: '우편을 입력해주세요' }
      ],
      exceed: 'num개 글자 이하',
      err: {
        type: '원하는 부적을 고르세요',
        name: '이름을 입력해주세요',
        phone: '정확한 전화번호를 입력하세요',
        address: '주소를 입력해주세요',
        email: '이메일 주소를 다시 확인해주세요.',
        postCode: '우편을 입력해주세요'
      },
      schedule: '배송 진도>',
      outdated: '이벤트가 끝났습니다',
      submitted: '배송정보 입력완료'
    },
    detail: {
      overview: '올해 총운',
      over_subtitle: '올해 총운',
      radar: '주제별 운세 점수',
      radar_tips: '점수가 높을수록 이 운세가 좋습니다.',
      list: ['연애운', '직장운', '재물운', '공부운', '친구운', '건강운'],
      analy: '주제별 운세',
      month: '월',
      score: '득점',
      start: '2월(입춘)',
      end: '1월(소한)',
      guide: '운세 올리는 법',
      guide_list: [
        '행운 방향, 풍수자리, 환경:',
        '행운 색깔, 숫자:',
        '행운 음식물:',
        '행운의 일:',
        '행운 액세서리:',
        '행운의 띠:'
      ],
      tu: ' 흐름',
      guide_tips:
        '본인과 가족의 띠와 상충(相冲), 상형(相刑), 상해(相害)를 입지 않는 장식품을 선택하세요.',
      master: '전문가 사인:',
      share: '공유하기>',
      get: '나의 신년운세 알아보기',
      share_txt:
        'HiSeer 사주 전문가가 직접 풀어주는 2024년 신년운세>> HiSeer app - ',
      download: 'HiSeer APP를 다운받아\n프리미엄신년운세 알아보기'
    }
  },
  // 问卦咨询
  hexagram: {
    name: '이름',
    gender: '성별',
    question: '질문',
    update: '다시 추천',
    question_place: '상담하고 싶은 질문에 대해 자세히 설명하세요',
    q_err: '50개 글자 이하',
    start: '점치기',
    manual: '점치기',
    time: '시간',
    time_err: '효괘 시간을 선택해주세요',
    question_1: '질문',
    title_1: '주의점',
    tips: '어떻게 점을 쳐야 상대적으로 정확한 결과를 얻을 수 있습니까?',
    cover_list: ['연애 고민', '직장 고민', '돈 고민', '학업 고민', '건강 고민'],
    cover_text: '일사일점 정확한 예측\n전문가 해괘',
    more_question: '추천',
    select: '효를 선택하십시요',
    six_err: '효를 선택하십시요',
    list: [
      {
        q: '육효는 특정 사항을 문의하는 데 적합하며 질문이 구체적일수록 좋습니다.',
        r: '오늘 면접 잘 볼 수 있을까?',
        f: '나는 어떤 직업에 어울릴까?'
      },
      {
        q: '한 번에 한 가지 질문만 내십시오.',
        r: '그 사람은 나를 좋아할까?',
        f: '그 사람은 나를 좋아할까? 우리 사귈 수 있을까? 얼마나 오래 사귈수 있을까?'
      },
      {
        q: '문제가 시간 또는 숫자와 관련이 있는 경우, 질문에 꼭  시간 또는 숫자를 적어서 제출하세요.',
        r: '최근 한 달 동안 일자리를 구할 수 있을까?',
        f: '직장을 구할 수 있을까?'
      },
      {
        q: '집중하고, 질문에 대한 설명을 명확하게 하여 전문가가 당신의 상황을 더 명확하게 이해할 수 있도록 합니다.(질문이 구체적일수록 풀이가 정확해집니다.)'
      }
    ],
    example: [
      {
        title: '주역점 상담 샘플',
        label_q: '연애 고민',
        question: '그 사람과 결혼하고 아이를 낳아도 될까?',
        label_r: '풀이 예시',
        reply:
          '안녕하세요. 이 괘는 풍화가인 수뢰둔(风火家人-水雷屯)입니다. 세효(世爻)가 공(空)이라는 것은 당신이 그 사람과의 미래에 대해 확신이 없다는 것을 보여줍니다. 하지만 괘에서 세효(世爻)를 생(生)하는 자손효(子孫爻)가 있어서 당신들은 결혼하고 아이를 낳는 것도 나쁘지 않다는 것을 의미합니다. 그런데 한 가지 주목할 점은 당신의 짝이 미래 생활에서 아이보다 덜 중요할 수 있다는 것입니다. 두 사람의 감정도 비교적 평온하고 뜨겁지 않으며, 화목한 부부라고 할 수 있습니다. 또한, 괘에서는 가까운 장래에 부모의 건강과 안전에 많은 주의를 기울일 필요가 있음을 암시합니다. 이상 참고하시기 바랍니다.'
      },
      {
        title: '주역점 상담 샘플',
        label_q: '직장 고민',
        question: '올해 승진할 기회가 있을까?',
        label_r: '풀이 예시',
        reply:
          '안녕하세요, 괘상으로 볼 때, 올해 승진 기회는 비교적 적고 경쟁도 치열합니다. 주변에 경쟁자가 많은 데다가 지금 초조하고 스트레스를 받는 상태입니다. 하지만 걱정하지 마세요. 괘에서 당신은 관(官)과 동효(同爻)이기 때문에, 즉 상사는 당신을 인정하고 좋게 보고 있습니다. 그래서 올해 승진은 어렵지만 시간문제뿐입니다. 그 전에 더 많은 전문 기술과 지식을 배우고 리더십을 키우며 좋은 관계를 구축할 것을 제안합니다. 이상은 괘에 근거하여 내린 결론이니 참고하시기를 바랍니다.'
      },
      {
        title: '주역점 상담 샘플',
        label_q: '돈 고민',
        question: '이번 합작으로 돈을 벌 수 있을까?',
        label_r: '풀이 예시',
        reply:
          '안녕하세요. 괘에 따르면 형제지세(兄弟持世) 재부상괘(財不上卦,재물을 가지고 있지 않다)를 나타나 이 합작으로 돈을 벌 확률이 낮습니다. 당신은 현재 마음속으로 합작에 대해 확신이 없고, 상대방이 협력하려는 마음이 더 큽니다. 이 괘상은 손해를 보는 징후로 당신이 투자한 자금을 회수하기 어려울 수도 있다는 것을 의미합니다. 전면적으로 꼼꼼하게 평가와 조사를 한 후 합작을 고려할 것을 권장하며, 위의 내용은 참고용으로 제공됩니다.'
      },
      {
        title: '주역점 상담 샘플',
        label_q: '학업 고민',
        question: '원하는 학교에 갈 수 있을까?',
        label_r: '풀이 예시',
        reply:
          '안녕하세요. 이 괘는 수지비 택수곤(水地比-泽水困)이고, 괘에서 관효지세(官爻持世)가 나타나 시험이 어렵고, 당신도 현재 스트레스를 많이 받고 초조해하는 상태라는 것을 의미합니다. 또한 괘에서 부모효(父母爻)가 형제효(兄弟爻)로 변하는 것은 이번 시험이 치열하고 지원자가 많아 학교가 당신보다 다른 학생을 채용하는 확률이 더 높다는 것을 나타냅니다. 따라서 다른 방법이 없는 한 이 학교에 입학하는 것은 상대적으로 어렵습니다. 만약 이 학교에 꼭 들어가고 싶다면 성적에 큰 노력과 신경을 써야 합니다. 이상 참고하시기를 바랍니다.'
      },
      {
        title: '주역점 상담 샘플',
        label_q: '건강 고민',
        question: '아이가 요즘 자주 열이 났는데 언제 좋아질까요?',
        label_r: '풀이 예시',
        reply:
          '안녕하세요. 괘상으로 판단하면 아이의 현재 상태가 당분간 지속될 것으로 보이며, 약이 병에 맞지 않을 수 있습니다. 그러나 너무 걱정할 필요는 없으며 현재의 증상은 다른 심각한 증상을 일으키지 않으며 늦어도 오월(양력 6월) 전에 잘 완화되고 저절로 치유될 것입니다.아이의 음식, 휴식, 그리고 기분 상태를 신경으로 써서 신체의 자기 회복 기능을 향상합니다.'
      }
    ],
    feedback: {
      title: '상담 후기',
      list: [
        {
          time: '**연 2023.9.14',
          question: '상담 내용: 지금 이직해도 될까?',
          comment: '상담 후기: 상담 잘 받았습니다 감사합니다'
        },
        {
          time: '**명 2023.9.6',
          question: '상담 내용: 그녀를 되돌릴 수 있을까?',
          comment:
            '상담 후기: 앞으로 또 궁금한 점이 있을 때에는 꼭 여기서 확인할겁니다 ^^ 속이 후련합니다 ㅎ'
        },
        {
          time: '***란 2023.9.6',
          question: '상담 내용: 올해 대학에 잘 합격할 수 있을까요?',
          comment:
            '상담 후기: 정말 좋으세요. 알아듣기 편하게 이해할 수 있게 말씀도 잘 해주시고 좋았습니다! 감사합니다'
        }
      ]
    },
    illustrate: {
      title: '주역점 상담 설명',
      content: `1. 질문자의 이름, 성별, 상담하고 싶은 질문을 성실히 입력하여 한 번에 한 가지 일을 상담할 수 있습니다.
      2. 전문가팀이 실시간으로 분석 및 작성하여 일반적으로 정보 제출 후 24시간 이내에 해괘를 완료하고 문자(150자 내외)나 음성메시지로 회신하오니 기다려 주시기를 바랍니다.
      3. 만약 사용자가 점괘 규칙을 따르지 않고 동시에 여러 질문을 하거나 질문에 대한 설명이 명확하지 않을 경우, 상담 신청이 반박되어, 제때 수정하여 다시 제출해 주시기를 바랍니다.
      4. 구매하신 주역점 상담 풀이는 다음과 같은 방법으로  볼 수 있습니다.
      제공해 주신 메일로 보내드릴 예정이니 메일함에서 확인할 수 있습니다.
      HiSeer APP를 다운받아 로그인 후  [주역점 상담] - [구매내역]에서 확인할 수 있습니다.
      본 페이지의 [풀이내역]에서 이메일 또는 주문 번호를 검색하여 확인할 수 있습니다.`
    },
    yaoing: '점 치는중',
    sixyao: ['일효', '이효', '삼효', '사효', '오효', '육효'],
    positive: '정',
    opposite: '반',
    consult: '전문가 해괘',
    rule: '비고',
    rules: `1. 질문자의 이름, 성별, 상담하고 싶은 질문을 성실히 입력하여 한 번에 한 가지 일을 상담할 수 있습니다.

    2. 전문가팀이 실시간으로 분석 및 작성하여 일반적으로 정보 제출 후 24시간 이내에 해괘를 완료하고 문자(150자 내외)나 음성메시지로 회신하오니 기다려 주시기를 바랍니다.

    3. 만약 사용자가 점괘 규칙을 따르지 않고 동시에 여러 질문을 하거나 질문에 대한 설명이 명확하지 않을 경우, 상담 신청이 반박되어, 제때 수정하여 다시 제출해 주시기를 바랍니다.

    `,
    check_tips: {
      title:
        '4. 구매하신 주역점 상담 풀이는 다음과 같은 방법으로  볼 수 있습니다.',
      list: [
        '제공해 주신 메일로 보내드릴 예정이니 메일함에서 확인할 수 있습니다.',
        'HiSeer APP를 다운받아 로그인 후  [주역점 상담] - [구매내역]에서 확인할 수 있습니다.',
        '본 페이지의 [풀이내역]에서 이메일 또는 주문 번호를 검색하여 확인할 수 있습니다.'
      ]
    },
    info: {
      q: '질문:',
      name: '괘명:',
      detail: '점 괘:',
      submitted:
        '해괘 요청이 제출하였습니다.\n전문가는 time까지 구매하신 주역점상담 풀이를 이메일로 보낼 것이니 확인하시기를 바랍니다.',
      waiting: '전문가 처리중',
      add: [
        '주역점을 더 잘 풀 수 있도록 질문에 대한 설명(질문 배경, 전말과 원인 등)을 하십시오. 설명이 상세할수록 풀이가 정확해집니다.',
        '전문가는 점과 실제 상황을 고려하여 질문에 대한 보다 정확한 길흉의 추이를 판단할 것입니다.'
      ],
      add_title: '추가 내용',
      placeholder:
        '질문에 대한 설명(질문 배경, 전인과 결과 등)을 상세할수록 풀이가 정확해집니다.',
      err: '추가 내용을 입력하세요',
      cancel: '아니요',
      confirm: '설명추가',
      tips: '이메일 주소(email)로 HiSeer에 로그인하면 주역점 상담 진도를 확인할 수 있습니다.'
    },
    payment: '전문가가 해괘한 후 해당 메일로 풀이를 보내드리겠습니다.',
    detail: '상제히 보기',
    reply: '상담 결과',
    reply_t: '답장 시간',
    replenish: '추가 정보',
    modify: '수정하기',
    playing: '재생중'
  },
  // 职能报告
  enterprise: {
    assis: '기업을 위해',
    title: '인재 평가',
    desc: '전면적으로 재능 평가로 뛰어난 인재를 놓치지 않기',
    start: '시작하기',
    recruit:
      '채용 과정에서 기업은 구직자의 표면적인 행동에 너무 많은 관심을 기울이고 학력과 전문성이 직위에 맞는 직원을 채용하지만 실제 업무에서는 항상 평범합니다.\n대부분의 기업은 직원의 업무 성과를 진정으로 결정하는 요소가 빙산의 숨겨진 특성이라는 사실을 간과하기 때문입니다.',
    iceburg: {
      top: '관찰 가능한 현성 행위',
      t_num: '빙산 위 30%',
      b_num: '빙산 아래 70%',
      bottom: '배양하기 어려운 열성 특질',
      t_keys: ['지식', '기능'],
      b_keys: ['능력', '성격', '동기', '타고난 재질'],
      under:
        '빙산 아래가 너무 중요하기 때문에 숨겨진 특성을 배양하기 어려운데 기업이나 직위에 이러한 자질을 갖춘 직원이 필요한 경우 어떻게 해야 합니까?기업의 채용 고민을 돌파하는 데 도움을 주기 위해 HiSeer는 나름의 답을 내놓았습니다.',
      review: '인재 평가',
      review_desc:
        '인재 평가 리포트는 가장 전문적인 인격 알고리즘과 결합하여 후보자의 고유한 특성을 효율적으로 분석하고 후보자의 실제 수준을 정확하게 검증하며 여러 분석을 통해 빙산의 진실을 탐색하고 현명한 인재를 식별하는 데 도움이 됩니다.'
    },
    content: {
      title: '기업용 인재 평가는',
      assess: {
        title: '후보자의 현재 능력 평가',
        position: '후보자의 가장 두드러진 특질에 정확하게 초점을 맞추기',
        five: '다섯 차원에서 후보자의 활약상을 살펴보기',
        vantages: '후보자의 우열',
        radar: ['인간관계', '사고방식', '집행력', '에너지', '멘탈관리'],
        subtitle_1: '인재 포지셔닝',
        subtitle_2: '종합평가',
        subtitle_3: '직업적 성격',
        subtitle_4: '직업능력분포'
      },
      analy: {
        title: '후보자의 직업 장래성',
        subtitle1: '앞으로 5년의 직업 장래성 평가',
        list1: [
          '사업의 절정일까, 아니면 슬럼프일까?',
          '평온할까, 아니면 우여곡절이 많을까?'
        ],
        subtitle2: '앞으로 5년의 직업 발전 흐름',
        list2: [
          '조만간 핵심 직책을 맡을 수 있을까?',
          '안정성은 어느 정도일까?'
        ],
        ability: '후보자의 업무 능력 분석하기'
      },
      guide: {
        title: '기업 용인술',
        subtitle1: '이 직원을 어떻게 관리하고 활용하는지',
        list1: [
          '어떤 직위가 그 재능을 발휘할 수 있을지',
          '어떤 업무수행 방식이 그 작업 동력을 자극할 수 있을지',
          '어떤 자원을 제공하면 성장을 도울 수 있을지'
        ],
        text1: '기업이 진정한 인재를 놓치지 않기',
        subtitle2: '고용 리스크 및 주의 사항',
        list2: [
          '후보자를 고용할 때 어떤 사항을 주의해야 하는지',
          '후보자는 업무 중에 어떤 문제에 부딪힐 수 있을지',
          '어떻게 관리하면 고용 리스크를 피할 수 있을지'
        ],
        text2: '기업이 고용 리스크를 피할 수 있도록 도와주기'
      },
      example: '인재 평가 리포트 샘플',
      feeling: {
        title: '사용자 후기',
        list: [
          {
            name: '**동 xxxxx회사 HR매니저',
            text: '이 리포트를 한동안 사용했는데, 가장 큰 소감은 정확하다는 것입니다. 우리 구인자에게는 유능한 사람을 찾는 것보다 적임자를 찾는 것이 더 중요하고, 이 시스템은 구직자의 특성을 빠르게 파악하고 적임 여부를 판단하여 채용의 효율성을 높일 수 있도록 도와줬어요.'
          },
          {
            name: '**민 xxxx회사 CEO',
            text: '이 리포트는 우리에게 많은 실용적인 조언을 제공하고 기업 인재 관리 시스템을 개선하는 데 도움이 되었으며 직원 반응도 매우 좋습니다.'
          },
          {
            name: '**현 xxxxx회사 VP',
            text: '이 인재 평가 리포트는 주로 핵심 직원이 최근의 발전 흐름과 이직 의향을 참조하는 데 사용되며, 이 직원들은 오랜 기간 동안 회사와 같이 걸어 왔는데 직장 동향을 파악하면 직원 관리 및 계획에 매우 도움이 됩니다.'
          }
        ]
      },
      illustrate: {
        title: '주의 사항',
        content:
          '1. 후보자의 이름, 성별 및 생일을 정확하게 입력해야 합니다.\n2.결제 후 인재 평가 리포트는 30초 이내에 풀이가 생성되며 생성 후 다음과 같은 방식으로 영구적으로 확인할 수 있습니다.주문 번호를 기록하거나, 이메일/휴대전화 번호 인증으로 저장하거나, 본 홈페이지의 [구매내역]에서 이메일/휴대전화 번호/주문 번호를 검색하여 확인할 수 있습니다.'
      },
      loading: {
        title: '분석중....',
        list: ['능력 평가', '미래 발전', '인재 활용']
      }
    },
    loading: {
      title: '분석중....',
      list: ['능력 평가', '미래 발전', '인재 활용']
    },
    object: '평가 대상',
    score: '점수',
    overall: '종합점수',
    warning:
      '현재 평가 대상의 생일 정보가 불완전하여 분석 결과에 정확하지 않을 수 있습니다.',
    line_warning:
      '이 부분은 향후 5년 동안 직업 발전의 전반적인 전망을 포함하며 매년 발전의 좋고 나쁨을 나타내지 않는다는 점에 유의할 필요가 있습니다.',
    assess: '후보자 능력 평가',
    analy: '후보자의 직업 장래성',
    guide: '기업 용인술',
    assess_list: ['사고방식', '인간관계', '멘탈관리', '집행력', '에너지'],
    year: '연도',
    shot: '저장하기',
    lunar: '음력',
    solar: '양력',
    save_tips:
      '작성한 계좌번호를 잘 기억하면 \n추후 이 계좌로 주문서를 검색할 수 있습니다.',
    prompt:
      '不保存將會新增報告遺失的風險，保存後您可以在郵箱內查看報告，是否保存報告？',
    success:
      '발송 성공 천부적 재능 리포트를 회원님의 이메일으로 전송되었습니다. 확인해주십시오.'
  },
  career: {
    title: '직업 발전평가',
    desc: '맞춤형 직업 기획으로 남보다 한 걸음 더 빨라지기',
    items: ['업무 능력 평가', '직업 발전 흐름', '커리어 조언'],
    questions: [
      '아직도 일의 곤경 속에서 허덕이고 있습니까?',
      '동기가 부족하거나 자신이 잠재력을 발휘하지 못한다고 느끼십니까?',
      '아니면 승진 곤경이 당신이 밤잠을 못 이루게 합니까?'
    ],
    bubbles: [
      '나는 어떤 일을 하기에 적합할까?',
      '나의 핵심 경쟁력은 무엇일까?',
      '회사를 옮길까??',
      '내년에 승진 기회가 있을까?',
      '어떤 작업 환경이 나에게 더 적합할까?'
    ],
    sayno: '직업 발전 평가는 당신의 앞날에 걱정을 끼치지 않도록 도와줍니다.',
    construct: [
      { title: '업무 능력 평가', content: '퀵 포지셔닝\n당신의 핵심 경쟁력' },
      { title: '직업 발전 흐름', content: '정확한 예측\n당신의 직업 운세' },
      { title: '커리어 조언', content: '맞춤형 분석\n당신의 직업 조언' }
    ],
    result: '직업 계획을 미리 하기\n승진은 한 걸음 빠르기',
    content: {
      title: '직업발전평가 주로 내용',
      position: '인재 포지셔닝',
      positions: [
        '자주독립형',
        '개척창조형',
        '책임감당형',
        '유연결단형',
        '행동지향형',
        '목표협업형'
      ],
      special: '개인의 직업적 특질에 정확하게 초점을 맞추기',
      assess: '다섯 차원에서 평가 대상의 활약상을 살펴보기',
      character: '개인의 우열을 빠르게 파악하기',
      character_list: [
        '비판성',
        '실행력',
        '적응성',
        '개방성',
        '제멋대로',
        '소홀함',
        '보수성'
      ],
      ability: '변증법적으로 업무 능력을 분석하기',
      forecast: '앞으로 5년의 직업 장래성 평가',
      forecasts: [
        '사업의 절정일까, 아니면 슬럼프일까?',
        '평온할까, 아니면 우여곡절이 많을까?'
      ],
      trend: '앞으로 5년의 직업 발전 흐름',
      trends: ['승진 기회가 있을까? ', '직장 위기가 있다면?'],
      guide: {
        title: '직장 가이드',
        list: [
          '어떤 직책을 택하면 특기를 발휘할 수 있을지',
          '어떤 작업 방식이 작업 동력을 불러일으킬 수 있을지',
          '어떤 회사가 개인의 성장을 도울 수 있을지'
        ],
        text: '진정한 직업적 재능을 찾아 개인의 가치를 쉽게 실현하기'
      },
      advice: {
        title: '발전시키는 방법',
        list: [
          '어떻게 하면 직장 환경에 유연하게 적응할 수 있을까?',
          '어떻게 일을 하면 실수를 피할 수 있을까?',
          '어떤 중요한 세부 사항이 각별히 주의해야 할까?'
        ],
        text: '실수를 줄이고 직업 리스크를 감소에 도와주기'
      },
      swiper: '직업발전평가 리포트 샘플',
      feeling: {
        title: '사용자 후기',
        list: [
          {
            name: '**지',
            text: '종합 평가 부분은 소름돋을 정도로 너무 정확해... 나는 사고력이 집행력을 잘 따라가는 편이라 IT업계를 택했었던거야ㅎㅎ'
          },
          {
            name: '**정',
            text: '이 리포트는 특히 곤 졸업할 나 같은 학생들에게는 아주 도움이 되고, 분석 결과를 보니 더욱 자신감이 생겼어여 ㄱㅅㄱㅅ!'
          },
          {
            name: '**권',
            text: '결과 보고 놀랐어요... 나는 특별한 재능이 없고 다른 사람과 소통이 잘해서 지금은 영업직을 하고 있어요.'
          }
        ]
      },
      illustrate: {
        title: '주의 사항',
        content:
          '1. 평가 대상의 이름, 성별 및 생일을 정확하게 입력해야 합니다.\n2.결제 후 직업 발전평가 리포트는 30초 이내에 풀이가 생성되며 생성 후 다음과 같은 방식으로 영구적으로 확인할 수 있습니다.주문 번호를 기록하거나, 이메일/휴대전화 번호 인증으로 저장하거나, 본 홈페이지의 [구매내역]에서 이메일/휴대전화 번호/주문 번호를 검색하여 확인할 수 있습니다'
      }
    },
    loading: {
      analysing: '분석중....',
      list: ['업무 능력 평가', '직업 발전 흐름', '커리어 조언']
    },
    tablist: ['능력 평가', '미래 발전', '직장 조언']
  },
  // simple-report组件完整天赋部分数据
  simple: {
    // 本名解读
    talent: '올인원 리포트',
    famous: '같은 유형의 \n유명인',
    values: '가치관',
    personality: '성격 분석',
    think: '사고특성',
    potential: '잠재력\n끌어올리기',
    // 人际交往
    six: '육친 관계',
    elegant: '귀인운',
    // 职业规划
    career: '사업관',
    advice: '진로지도',
    // 恋爱指南
    love: '연애관',
    best_lover: '배우자\n유형',
    unlock: '완전한 리포트 보기',
    split_keyword: {
      title: '당신의 천부적재능 유형',
      content:
        '당신의 가장 두드러진 특징을 추출하고\n당신의 천부적재능 캐릭터를 그려줍니다'
    },
    s_talent: {
      title: '당신의 올인원 리포트',
      content: '당신의 재능과 장점,\n그리고 유망주인지를 알려줍니다.'
    },
    s_nature: {
      title: '당신의 성격 분석',
      content: '당신의 장점과 약점을\n완벽하게 분석해 줍니다.'
    },
    s_potential: {
      title: '잠재력을 끌어올리는 방법',
      content:
        '단점을 극복하고 장점을 살리는 방법,\n그리고 잠재력을 끌어올리는 방법을 제공합니다.'
    },
    s_talent_image: {
      title: '당신의 천부적 캐릭터'
    },
    s_celebrity: {
      title: '같은 유형의 유명인',
      content:
        '당신의 인격과 유사도가 가장 높은 유명인,\n그들이 종사하는 직업을 알려드립니다.'
    },
    s_values: {
      title: '당신의 가치관',
      content:
        '당신에게 가장 중요한 것과 가장 잠재력이\n있는 정신적 특질을 풀어줍니다.'
    },
    s_thinking: {
      title: '당신의 사고특성',
      content:
        '당신이 가장 잘하는 사고방식을 해독하고,\n어느 방면의 사고 의식이 향상되어야 하는지를 분석합니다.'
    },
    s_love_nature: {
      nature: '연애 성격',
      title: '당신의 연애 성격',
      content: '당신이 사랑에 있는 역할,\n배우자에 대하는 방식을 분석합니다.'
    },
    s_best_lover: {
      title: '배우자 유형',
      content: '당신과 궁합이 맞는 배우자 유형'
    },
    s_relatives: {
      title: '당신의 육친 관련성 순위',
      content:
        '당신과 육친의 친밀도,\n당신에게 가장 큰 영향을 미치는 사람을 찾아줍니다.'
    },
    s_affect_life: {
      title: '당신의 귀인운',
      content:
        '육친 중에 당신에게 도움이 되는 사람,\n그리고 당신의 귀인 유형을 분석합니다.'
    },
    s_profession_nature: {
      title: '당신의 직업 성격',
      content: '직장에서 당신의 역할,\n일과 동료에 대하는 방식을 분석합니다.'
    },
    s_profession_ability: {
      title: '직업 능력치 분포',
      content: '업무 능력의 장단점을 분석하고,\n올려야 할 부분 제시해 줍니다.'
    },
    s_profession_type: {
      title: '당신에게 적합하는 직업 종류',
      content: '취업 방향을 잡아주고,\n당신에게 맞는 직업 유형을 매칭해 줍니다.'
    }
  },
  request: {
    network_err: '네트워크 연결 이상'
  },
  // 免费排盘
  plate: {
    eight: '사주 팔자 ',
    gender: '성별',
    name: '닉네임',
    country: '출생지',
    birth: '생일',
    submit: '확인',
    restart: '새로 만들기',
    name_placeholder: '닉네임을 입력해주세요',
    birth_placeholder: '출생년월일을 입력해주세요',
    history: '풀이내역',
    no_show: '간지\n표시',
    g: '장간\n표시',
    spirit: '십성\n표시',
    element: '오행\n표시',
    fortunate: '대운',
    flow_year: '유년',
    flow_month: '유월',
    flow_day: '유일',
    flow_hour: '유시',
    more_sun: 'HiSeer다운받아 십간에 대해서 더 알아보기',
    more_destiny: 'HiSeer다운받아 다섯 명국에 대해서 더 알아보기',
    more_predict: '인기 제품'
  },
  // 答案之书
  answer_book: {
    title: '[점]해결의 책',
    search: '검색',
    search_holder: '검색어를 입력해주세요',
    no_result: '관련 결과가 검색되지 않습니다',
    content:
      "해결의 책——<span>연애, 재물, 직장, 학업</span> 등 당신이 고민하는 일에 대한 해결책을 찾아주고, 당신이 묻고 싶은 질문에는 '해결의 책'이 대답해줍니다.",
    history: '조회수',
    score: '점',
    start: '시작',
    start_tip: '준비되면 [시작] 를 누르세요',
    stop: 'Stop',
    question_tip: '마음속으로 질문을 생각하세요',
    tips: '준비되면 Stop를 누르세요',
    check: '풀이보기',
    download: '<span>HiSeer App 다운받아</span>\n완전한 풀이결과 보기',
    pay_prompt: '구매하기',
    tabs: ['추천', '애정운', '재물운', '직장', '학업운', '종합'],
    save: {
      tip: 'HiSeer 앱에 접속할 수 있게끔\n입력한 계정를 잘 기억하십시오.',
      title: 'HiSeer로 저장하기',
      prompt:
        '이대로 나가면 구매하신 리포트를 다시 찾을 수 없으니, 리포트 저장하는 것을 권합니다. ',
      success: [
        '1. HiSeer 앱을 열어 리포트를 저장할 때 사용하는 이메일 또는 전화번호로 로그인하면 저장된 리포트를 볼 수 있습니다.',
        '2. 오른쪽 상단 ☰ 버튼을 누러 풀이내역-주문 조회 페이지에서 주문번호, 또는 리포트 저장시 제공된 전화번호나 이메일을 입력하여 검색하면 저장된 리포트를 볼 수 있습니다.'
      ]
    },
    more: '모아보기',
    save_txt: '저장',
    ad_text: 'HiSeer 앱 다운 받아\n더 많은 콘텐츠 보기',
    paid: '이용자 수',
    useful: '만족도',
    loading: '로드...',
    seer: 'HiSeer오리지널 콘텐츠'
  },
  // 资讯分享
  info: {
    share: '공유',
    public: '',
    more: '更多推荐',
    download: 'HiSeer 다운 받아 사용하세요.',
    prompt: 'HiSeer 앱 다운 받아\n《》완전한 콘텐츠 보기',
    read: '조회수',
    replay: '다시 보기',
    tipping: '복채내기',
    commont: '리뷰',
    write_comment: '리뷰 남겨주세요',
    copyright: '오리지널 콘텐츠-영리적 사용 불가',
    test: '조회수'
  },
  // 独立页面
  out: {
    master: {
      title: '전문가 상담',
      desc: '1:1로 실시간 상담',
      list: [
        {
          bold: '전문성: ',
          content:
            'HiSeer상담사가 1:1상담 서비스를 제공하며, 모든 상담사는 전문적인 명리학을 배웠고 상담 경험이 풍부하며 HiSeer 공식 심사를 거쳐 상담 서비스가 보장됩니다.'
        },
        {
          bold: '풍부함: ',
          content:
            '사주팔자, 평생 운세, 유년(流年) 운세, 연애운, 직장/사업운, 재물운, 학업운, 자식 교육, 궁합, 주역점 점술, 택일, 이름짓기 및 관상 등 다양한 상담 서비스를 포함해 사용자의 요구를 충족시킵니다.'
        },
        {
          bold: '편리함:',
          content: '실시간 채팅으로 상담 가능하며, 보다 편리하고 빠릅니다.'
        }
      ]
    }
  }
}
