import { Toast } from 'vant'
import i18n from '@/lang/index.js'
// import getOs from '@/utils/getOs.js'

export const langList = {
  koKR: 'ko',
  zhCN: 'sc',
  zhTW: 'tc'
}
console.log(i18n.global.locale.value)
export default async (url = '', data = {}, type = 'GET', headers = {}) => {
  let baseUrl = import.meta.env.VITE_BASE_URL // 基础路径
  //去除report.withseer跨域问题
  if (location.origin === 'https://report.withseer.com') {
    baseUrl = 'https://report.withseer.com/pro'
  }
  type = type.toUpperCase() // 请求方式小写转换成大写
  url = baseUrl + url // 请求地址的拼接

  if (type === 'GET') {
    let dataStr = '' //数据拼接字符串
    Object.keys(data).forEach((key) => {
      dataStr += key + '=' + data[key] + '&'
    })
    if (dataStr !== '') {
      dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'))
      url = url + '?' + dataStr
    }
  }
  let requestConfig = {
    credentials: 'same-origin',
    method: type,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'dy-lang': (() => langList[i18n.global.locale.value])(),
      // 'dy-uid': 'p-8618650175527',
      'dy-source': 3,
      // 'dy-version': '1.0.0',
      ...headers
    }
    // mode: 'cors', // 用来决定是否允许跨域请求  值有 三个 same-origin，no-cors（默认）以及 cores;
    // cache: 'force-cache' // 是否缓存请求资源 可选值有 default 、 no-store 、 reload 、 no-cache 、 force-cache 或者 only-if-cached 。
  }
  if (type == 'POST') {
    Object.defineProperty(requestConfig, 'body', {
      value: JSON.stringify(data)
    })
  }
  try {
    const response = await fetch(url, requestConfig)
    const responseJson = await response.json()
    // console.log(responseJson);
    if (responseJson.code !== 0) {
      const error = new Error(responseJson.msg)
      error.cause = responseJson.code
      throw error
    }
    return responseJson
  } catch (error) {
    // cause 不存在则表示网络问题
    console.log('error.cause', error, 'name', error.name)
    if (!error.cause) Toast(i18n.global.t('request.network_err'))
    throw error
  }
}
