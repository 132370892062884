import { getSearch } from '../utils/utils'
import storage from '../utils/storage'
import { virtualPay } from '../apis/report.js'
import { Toast } from 'vant'

// 获取活动参数
export default function () {
  const { skip } = getSearch()
  if (skip) {
    storage.set('skip', skip)
  }
  //   else if (storage.get('skip')) {
  //     storage.delete('skip')
  //   }
}

export const useGetSkip = () => {
  return storage.get('skip')
}

export const skipPay = async (product) => {
  try {
    const {
      data: { orderNo }
    } = await virtualPay({ product })
    return orderNo

    // router.replace({
    //   path: '/report',
    //   query: { orderNo: orderNo, virtual: true }
    // })
  } catch (err) {
    Toast(err.message)
  }
}
