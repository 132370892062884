<template>
  <div class="contact--wrapper">
    <div class="help" v-if="!isLang(LANG.KR)">
      {{ $t('menu.pay_tips') }}
    </div>
    <div class="help">
      {{ $t('menu.help') }}
    </div>
    <div class="qr-code" :style="{ backgroundImage: `url(${qrCode})` }"></div>
    <div class="wx-id">
      {{ $t('menu.wechat') }}{{ wechat }}
      <svg-icon
        class="copy-icon"
        name="nav-copy"
        size="68"
        @click="copy(wechat)"
      ></svg-icon>
    </div>
    <div class="wx-id">
      {{ $t('menu.email') }}{{ EMAIL }}
      <svg-icon
        class="copy-icon"
        name="nav-copy"
        size="68"
        @click="copy(EMAIL)"
      ></svg-icon>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Toast } from 'vant'
import { copyByCommand, isLang } from '@/utils/utils'
import { WECHAT_ID, INSTAGRAM, EMAIL, LANG } from '@/utils/contants'

import SvgIcon from '@/components/SvgIcon.vue'

const qrCode = computed(
  () =>
    new URL(
      `../../../icons/nav/contact${isLang(LANG.KR) ? '-kokr' : ''}.png`,
      import.meta.url
    ).href
)

const wechat = computed(() => (isLang(LANG.KR) ? INSTAGRAM : WECHAT_ID))

const copy = (text) => {
  console.log(text)
  copyByCommand(text, () => {
    Toast.success('')
  })
}
</script>

<style lang="less" scoped>
.contact--wrapper {
  // margin-left: -114px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  font-size: 50px;
  text-align: center;
  .help {
    font-size: 58px;
    line-height: 85px;
    white-space: pre;
  }
  .qr-code {
    margin: 25px auto;
    width: 400px;
    height: 400px;
    background-image: url('@/icons/nav/contact.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .wx-id {
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 65px;
    .copy-icon {
      margin-left: 20px;
      vertical-align: middle;
    }
  }
}
</style>
