<script setup>
import Header from './components/Header/index.vue'
import Loading from './components/Loading.vue'
import { watch, provide, ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import pxToRem, { remToPx } from './utils/pxToRem'
import { getSearch, isWechat } from './utils/utils'
import useCheckReport from './hooks/useCheckReport.js'
import useInit from '@/hooks/useInit'
import useSkipPay from './hooks/useSkipPay.js'
import useReportType from '@/hooks/useReportType'
const height = ref(window.innerHeight)
const headerRef = ref()
const router = useRouter()
const loadingRef = ref(false)

const showHeader = computed(() => {
  const search = getSearch() || {}
  return search['from'] !== 'email'
})

const _height = computed(() => {
  if (!showHeader.value) return height.value + 'px'
  return height.value - remToPx(pxToRem(178)) + 'px'
})

const [, first] = location.pathname.split('/')
if (
  ['birth', 'person', 'destiny', 'love', 'relation', 'profession'].includes(
    first
  )
)
  useReportType()

provide('closeMenu', () => {
  headerRef.value.close()
})
provide('openMenu', (...rest) => {
  headerRef.value.openMenu(...rest)
})

watch(
  () => height.value,
  (newV) => {
    document.documentElement.style.setProperty('--vh', `${newV}px`)
  },
  {
    immediate: true
  }
)

router.beforeEach(() => {
  loadingRef.value.show(true)
})
router.afterEach(() => {
  loadingRef.value.show(false)
})

onMounted(() => {
  useCheckReport()
  // 触发resize
})
window.addEventListener('pageshow', () => {
  setTimeout(() => {
    height.value = window.innerHeight
  }, 500)
})
useSkipPay()
useInit(height)
</script>

<template>
  <header class="header-fixed max-width" v-if="showHeader">
    <Header ref="headerRef" />
  </header>
  <section class="content max-width">
    <RouterView />
  </section>
  <Loading ref="loadingRef" background="rgba(0, 0, 0, 0.1)"/>
</template>

<style scoped lang="less">
.header-fixed {
  position: relative;
  z-index: 3;
}
.content {
  overflow: auto;
  height: v-bind(_height);
}
</style>
