<template>
  <div class="nav-wrapper">
    <div class="txt">
      <img src="@/assets/images/home/logo.png" alt="SEER" />
      <span>{{ $t('nav.title') }}</span>
    </div>
    <div class="btn-box">
      <div class="open-app-btn" @click="toDownload">
        <span>{{ $t('nav.button') }}</span>
      </div>
      <svg-icon
        :name="show ? 'nav-close' : 'nav-menu'"
        size="80"
        @click.stop="openMenu"
      ></svg-icon>
    </div>
  </div>
  <Menu ref="menuRef" :show="show" @close="close" />
</template>
<script setup>
import svgIcon from '@/components/SvgIcon.vue'
import Menu from './components/Menu.vue'
import { openApp } from '@/utils/utils.js'
import { MENU_DOWNLOAD } from '@/utils/contants'
import { ref, provide, getCurrentInstance } from 'vue'

const { mitt } = getCurrentInstance().proxy
const show = ref(false)
const menuRef = ref(null)

const openMenu = (option) => {
  show.value = !show.value
  const { historyType } = option || {}
  historyType && menuRef.value.openHistory({ historyType })
}

const close = () => {
  show.value = false
}

const toDownload = () => {
  mitt.emit(MENU_DOWNLOAD)
  openApp()
}
provide('closeMenu', close)
defineExpose({
  close,
  openMenu
})
</script>
<style lang="less" scoped>
.nav-wrapper {
  height: 178px;
  line-height: 178px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 114px;

  box-shadow: 0px 8px 8px 0px rgba(144, 144, 144, 0.4);
  .txt {
    display: flex;
    align-items: center;
    img {
      width: 104px;
      height: 104px;
    }
    span {
      padding-left: 20px;
      font-size: 50px;
    }
  }
  .btn-box {
    display: flex;
    align-items: center;
    .open-app-btn {
      width: 274px;
      height: 108px;
      line-height: 108px;
      border-radius: 18px;
      font-size: 56px;
      color: #fff;
      background-color: rgba(81, 147, 255, 1);
      margin-right: 72px;

      display: flex;
      justify-content: center;
    }
  }
}
</style>
