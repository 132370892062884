<template>
  <svg
    aria-hidden="true"
    class="svg-icon"
    :width="alterWidth"
    :height="alterHeight"
  >
    <use :xlink:href="symbolId" :fill="color" />
  </svg>
</template>

<script setup>
import { computed } from 'vue'
import pxToRem from '@/utils/pxToRem.js'

const props = defineProps({
  prefix: {
    type: String,
    default: 'icon'
  },
  size: {
    type: String
  },
  width: {
    type: String
  },
  height: {
    type: String
  },
  name: {
    type: String,
    required: true
  },
  color: {
    type: String,
    default: '#fff'
  }
})
const alterWidth = computed(() => {
  return props.size ? pxToRem(props.size) : pxToRem(props.width)
})
const alterHeight = computed(() => {
  return props.size ? pxToRem(props.size) : pxToRem(props.height)
})
const symbolId = computed(() => `#${props.prefix}-${props.name}`)
</script>
