export const path = {
  home: '/home',
  birth: {
    home: '/birth',
    detail: '/birth/detail',
    simple: '/birth/simple'
  },
  personality: {
    home: '/person',
    payment: '/person/payment',
    detail: '/person/detail'
  },
  destiny: {
    home: '/destiny',
    payment: '/destiny/payment',
    detail: '/destiny/detail'
  },
  love: {
    home: '/love',
    payment: '/love/payment',
    detail: '/love/detail'
  },
  relation: {
    home: '/relation',
    payment: '/relation/payment',
    detail: '/relation/detail'
  },
  profession: {
    home: '/profession',
    payment: '/profession/payment',
    detail: '/profession/detail'
  },
  plate: {
    home: '/plate',
    detail: '/plate/detail'
  },
  answer: {
    home: '/answer-book',
    share: '/answer-book/share',
    detail: '/answer-book/detail',
    payment: '/answer-book/payment'
  },
  // 宫合报告
  match: {
    home: '/match',
    payment: '/match/payment',
    detail: '/match/detail'
  },
  annual: {
    home: '/annual',
    payment: '/annual/payment',
    detail: '/annual/detail',
    schedule: '/annual/schedule',
    info: '/annual/info',
    express: '/annual/express'
  },
  hexagram: {
    home: '/hexagram',
    mHexagram: '/manual-hexagram',
    consult: '/hexagram/consult',
    info: '/hexagram/info',
    detail: '/hexagram/detail'
  },
  // 职能报告
  enterprise: {
    home: '/enterprise',
    detail: '/enterprise/detail'
  },
  career: {
    home: '/career',
    detail: '/career/detail'
  },
  // 资讯
  information: {
    home: '/information',
    long: '/information/lvideo',
    short: '/information/svideo',
    book: '/information/book',
    article: '/information/article',
    iVideo: '/information/ivideo'
  },
  swiper: '/consult',
  video: '/video',
  share: '/hiseer-card',
  shareApp: '/seerlegend',
  linkApp: '/link-seerlegend'
}
