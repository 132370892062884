if (import.meta.env.PROD) {
  // if (true) {
  var script = document.createElement('script')
  script.async = 1
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-EFQNSHFSEJ'
  //   document(script)
  document.head.insertBefore(script, document.querySelector('script'))
}
window.dataLayer = window.dataLayer || []
// 生产环境
function gtagProd() {
  dataLayer.push(arguments)
}
// 开发环境
function gtagDev() {
  console.log(arguments)
}
window.gtag = import.meta.env.PROD ? gtagProd : gtagDev
gtag('js', new Date())

gtag('config', 'G-EFQNSHFSEJ')
